// import React, { useState, useEffect } from "react";
// import Header from "./Header";
// import Sidebar from "./Sidebar";
// import "../App.css";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import {
//   faArrowRight,
//   faArrowLeft,
//   faCopy,
//   faSliders,
// } from "@fortawesome/free-solid-svg-icons";
// import axios from "axios";
// import { BASE_URL } from "../config";
// const Wallet = () => {
//   // State to keep track of the current mode
//   const [isDarkMode, setIsDarkMode] = useState(true);
//   const [newProfile, setNewProfile] = useState(0);
//   const [lastMonth, setLastMonth] = useState(0);
//   const [profilePercentage, setProfilePercentage] = useState(0);
//   const [totalProfile, setTotalProfile] = useState(0);
//   const [transactions, setTransaction] = useState([]);
//   const [balance, setBalance] = useState(0);
//   // Function to toggle dark mode
//   const toggleDarkMode = () => {
//     setIsDarkMode(!isDarkMode);
//   };

//   useEffect(() => {
//     const fetchUserProfiles = async () => {
//       try {
//         const response = await axios.get(`${BASE_URL}/api/wallet`);
//         if (response.data.status === "success") {
//           setNewProfile(response.data.new_profile);
//           setLastMonth(response.data.last_month_profile);
//           setProfilePercentage(response.data.percentage);
//           setTotalProfile(response.data.total_profile);
//           setTransaction(response.data.transactions);
//           setBalance(response.data.balance);
//         } else {
//           console.error("Failed to fetch user profiles:", response.data.error);
//         }
//       } catch (error) {
//         console.error(
//           "Error fetching user profiles:",
//           error.response.data.error
//         );
//       }
//     };

//     fetchUserProfiles();
//   }, []);

//   const [isShare, setShare] = useState(false);
//   const handleShare = () => {
//     setShare(!isShare);
//   };

//   const [walletdetail, setWalletDetail] = useState(false);
//   const handlewallet = () => {
//     setWalletDetail(!walletdetail);
//   };

//   const [currentPage, setCurrentPage] = useState(1);
//   const [entitiesPerPage, setEntitiesPerPage] = useState(4);

//   const indexOfLastEntity = currentPage * entitiesPerPage;
//   const indexOfFirstEntity = indexOfLastEntity - entitiesPerPage;

//   let currentEntities = transactions.slice(
//     indexOfFirstEntity,
//     indexOfLastEntity
//   );

//   const emptyRowCount = entitiesPerPage - currentEntities.length;

//   const handlePrevClick = () => {
//     setCurrentPage(currentPage - 1);
//   };

//   const handleNextClick = () => {
//     if (currentEntities.length === entitiesPerPage) {
//       setCurrentPage(currentPage + 1);
//     }
//   };

//   const handleDropdownChange = (e) => {
//     setEntitiesPerPage(parseInt(e.target.value));
//     setCurrentPage(1);
//   };

//   return (
//     <div className={`${isDarkMode ? "dark" : "light"}`}>
//       <Header toggleDarkMode={toggleDarkMode} isDarkMode={isDarkMode} />

//       <div
//         className={` ${
//           isDarkMode ? "bg-[#05295A] text-white" : "bg-white text-black"
//         }  flex min-h-screen font-dmsans pt-5`}
//       >
//         <Sidebar isDarkMode={isDarkMode} />

//         <div className="lg:w-[85%] w-full relative lg:container lg:mx-auto">
//           <div className="flex md:flex-row flex-col mx-5 items-center gap-10">
//             <div className="md:w-1/3 w-full  ">
//               <p className="font-bold text-[#38B6FF] mb-3"> Profiles Created</p>
//               <div
//                 className={`border border-white 2xl:h-[12rem] h-[9rem] flex flex-col justify-center ${
//                   isDarkMode
//                     ? "bg-gradient-to-r from-[#b0b0b062] to-[#05295B]"
//                     : "bg-[#3ab7ff28]"
//                 }   rounded-lg shadow-md shadow-[#3AB6FF99] `}
//               >
//                 <div className="flex justify-around items-center">
//                   <p className="2xl:text-5xl text-4xl font-semibold">
//                     {newProfile}
//                   </p>
//                   <img src="../assets/Chart.png" />
//                 </div>
//                 <div className="flex justify-around items-center mt-3">
//                   <div className="flex flex-col items-center gap-1">
//                     <p className="text-base">This Month</p>
//                     <p className="text-[#3EDD87] font-bold text-base">
//                       {profilePercentage} %
//                     </p>
//                   </div>
//                   <div className="flex flex-col items-center gap-1">
//                     <p className="text-base">Last Month</p>
//                     <p className="text-[#F46565] font-bold text-base">
//                       {lastMonth}
//                     </p>
//                   </div>
//                   <div className="flex flex-col items-center gap-1">
//                     <p className="text-base">Total</p>
//                     <p
//                       className={`${
//                         isDarkMode ? "text-[#CABBB2]" : "text-black"
//                       } font-bold text-base`}
//                     >
//                       {totalProfile}
//                     </p>
//                   </div>
//                 </div>
//               </div>
//             </div>
//             <div className="md:w-1/3 w-full  ">
//               <p className="font-bold text-[#38B6FF] mb-3">Ownership</p>
//               <div
//                 className={`border border-white 2xl:h-[12rem] h-[9rem] flex flex-col justify-center ${
//                   isDarkMode
//                     ? "bg-gradient-to-r from-[#b0b0b062] to-[#05295B]"
//                     : "bg-[#3ab7ff28]"
//                 }  rounded-lg shadow-md shadow-[#3AB6FF99]`}
//               >
//                 <div className="flex justify-around items-center">
//                   <p className="2xl:text-5xl text-4xl font-semibold">
//                     {totalProfile}
//                   </p>
//                   <img src="../assets/Chart.png" />
//                 </div>
//                 <div className="flex justify-around mt-3">
//                   <p className="text-base flex gap-3">
//                     {" "}
//                     Total Ownership’s
//                     <span className=" text-[#1EEF32] font-bold">
//                       Transferred
//                     </span>{" "}
//                   </p>
//                 </div>
//               </div>
//             </div>
//             <div className="md:w-1/3 w-full  ">
//               <p className="font-bold text-[#38B6FF] mb-3">Matic Balance</p>
//               <div
//                 className={`border border-white 2xl:h-[12rem] h-[9rem] flex flex-col justify-center ${
//                   isDarkMode
//                     ? "bg-gradient-to-r from-[#b0b0b062] to-[#05295B]"
//                     : "bg-[#3ab7ff28]"
//                 }  rounded-lg shadow-md shadow-[#3AB6FF99]`}
//               >
//                 <div className="flex justify-around items-center">
//                   <p className="2xl:text-5xl text-3xl font-semibold">
//                     {balance.toFixed(4)} MATIC
//                   </p>
//                 </div>
//               </div>
//             </div>
//             {/* <div className="md:w-1/3 w-full  mt-9 ">
//               <div className="2xl:h-[12rem] h-[9rem] bg-white flex justify-center items-center  border border-[#38B6FF] rounded-lg ">
//                 <div className="flex flex-col justify-center items-center gap-2 " onClick={handleShare}>
//                   <img src="../assets/Plus.png" />
//                   <p className="text-[#3AB6FF]">Add Highlight</p>
//                 </div>
//               </div>
//               {isShare && (
//                 <div className="md:w-[100%] z-10 rounded-2xl modal-overlay fixed inset-0 flex flex-col lg:flex-row items-center justify-center bg-white bg-opacity-10 backdrop-blur-md" data-aos="fade-zoom-in">
//                   <div className='relative'>
//                     <img src='/assets/Group 427320848.png' className='w-[90%] md:w-[80%] mx-auto'></img>
//                     <div className='absolute inset-0 w-[80%] md:w-[75%] mx-auto'>
//                       <div className='flex justify-end' >
//                         <button className=' shadow-xl rounded-lg text-xl text-white font-bold    transition-transform transform-gpu hover:-translate-y-1 hover:shadow-lg mt-3 mr-2' onClick={handleShare} ><FontAwesomeIcon icon={faXmark}></FontAwesomeIcon></button>
//                       </div>
//                       <div className='md:pt-20 pb-5 justify-center flex gap-2'>
//                         <div><input type='checkbox'></input></div>
//                         <p className='font-bold'>Are you sure you want to share your health reports?</p>
//                       </div>
//                       <div className='flex justify-center'><button className='font-bold text-white py-2 px-10 rounded-lg bg-gradient-to-bl from-[#004696] to-[#3AB6FF] to-90%'>Confirm</button></div>
//                     </div>
//                   </div>
//                 </div>
//               )}
//             </div> */}
//           </div>

//           <div className="pt-5 mx-5">
//             <div className="flex justify-between">
//               <p className="text-[#38B6FF] font-bold ">Transaction History</p>
//               <div className="flex items-center gap-2">
//                 <p className="text-[#38B6FF]">Filter</p>
//                 <FontAwesomeIcon icon={faSliders} size="xs" />
//               </div>
//             </div>

//             <div className="mt-3">
//               <div
//                 className={`${
//                   isDarkMode ? "bg-white/10" : "bg-[#3ab7ff28]"
//                 } w-full rounded-md`}
//               >
//                 <p
//                   className={` ${
//                     isDarkMode ? "text-white" : "text-black"
//                   } text-end 2xl:text-base text-xs px-3 2xl:pt-10 pt-2`}
//                 >
//                   Show no of entity
//                   <select
//                     className="ml-2 outline-none rounded-md bg-transparent border-[1px]"
//                     onChange={handleDropdownChange}
//                     value={entitiesPerPage}
//                   >
//                     <option className="text-black" value="4">
//                       4
//                     </option>
//                     <option className="text-black" value="5">
//                       5
//                     </option>
//                     <option className="text-black" value="10">
//                       10
//                     </option>
//                   </select>
//                 </p>
//                 {/* <div className="mt-5">
//               <div className={` ${isDarkMode ? 'bg-white/10' : 'bg-[#3ab7ff28]'} rounded-md  overflow-hidden overflow-x-scroll overflow-y-scroll`}> */}
//                 <div className=" overflow-hidden overflow-x-scroll overflow-y-scroll">
//                   <table className="w-full">
//                     <thead className="">
//                       <tr
//                         className={`border-b  border-white/40 2xl:text-2xl ${
//                           isDarkMode ? "text-[#C8C2E8]" : "text-black"
//                         } `}
//                       >
//                         <th className="md:px-10 px-20 2xl:py-7 py-5 text-left">
//                           Kit Number
//                         </th>
//                         <th className="md:px-10 px-20 2xl:py-7 py-5 text-left">
//                           Wallet address
//                         </th>
//                         <th className="md:px-10 px-20 2xl:py-7 py-5 text-left">
//                           Transaction Hash
//                         </th>
//                         <th className="md:px-10 px-20 2xl:py-7 py-5 text-left">
//                           Date
//                         </th>
//                         <th className="md:px-10 px-20 2xl:py-7 py-5 text-left">
//                           Status
//                         </th>
//                       </tr>
//                     </thead>
//                     <tbody>
//                       {currentEntities.length === 0 ? (
//                         <tr>
//                           <td colSpan="5" className={`text-center py-5 font-bold ${isDarkMode ? 'text-white' : 'text-black'} `}>
//                             No transaction history found
//                           </td>
//                         </tr>
//                       ) : (
//                         currentEntities.map((data, index) => (
//                           <tr
//                             key={index}
//                             className={`${
//                               isDarkMode ? "text-white/75" : "text-black"
//                             }`}
//                           >
//                             <td className="2xl:text-xl text-sm px-10 lg:text-left text-center 2xl:py-5 py-3">
//                               <div className="flex items-center gap-2">
//                                 <div className="bg-white 2xl:w-11 2xl:h-12 w-7 h-8 p-1 rounded-sm relative">
//                                   <img
//                                     src="../assets/Group 427320861.png"
//                                     className="w-5 2xl:w-8 absolute"
//                                   />
//                                 </div>
//                                 <p className="text-[#3AB6FF]">
//                                   {data.kitNumber}
//                                 </p>
//                               </div>
//                             </td>
//                             <td className="2xl:text-xl text-sm px-10 lg:text-left text-center 2xl:py-5 py-3 font-semibold">
//                               <button className=" cursor-pointer ">
//                                 {data.wallet_address}
//                               </button>
//                             </td>
//                             <td className="2xl:text-xl text-sm px-10 lg:text-left text-center 2xl:py-5 py-3 ">
//                               <a
//                                 href={
//                                   "https://amoy.polygonscan.com/tx/" +
//                                   data.thx_hash
//                                 }
//                                 className="cursor-pointer"
//                                 target="_blank"
//                               >
//                                 {data.thx_hash}
//                               </a>
//                             </td>
//                             <td className="2xl:text-xl text-sm px-10 lg:text-left text-center 2xl:py-5 py-3">
//                               {data.created_at}
//                             </td>
//                             <td
//                               className={`2xl:text-xl text-sm px-10 lg:text-left text-center 2xl:py-5 font-semibold py-3 ${
//                                 data.status === "Pending"
//                                   ? "text-yellow-500"
//                                   : data.status === "Approved"
//                                   ? "text-green-500"
//                                   : data.status === "Failed"
//                                   ? "text-red-500"
//                                   : ""
//                               }`}
//                             >
//                               {data.status}
//                             </td>
//                           </tr>
//                         ))
//                       )}
//                       {Array.from({ length: emptyRowCount }).map((_, index) => (
//                         <tr key={`empty-${index}`} className="h-14">
//                           <td>&nbsp;</td>
//                           <td>&nbsp;</td>
//                           <td>&nbsp;</td>
//                         </tr>
//                       ))}
//                     </tbody>
//                     {walletdetail && (
//                       <div
//                         className={`absolute top-0 left-0 w-full h-full overflow-hidden overflow-y-auto md:overflow-hidden ${
//                           isDarkMode
//                             ? "bg-[#05295A] text-white"
//                             : "bg-white text-black"
//                         }`}
//                       >
//                         <div className="flex gap-2 mx-10">
//                           <button
//                             className="text-[#3AB6FF99] font-semibold"
//                             onClick={handlewallet}
//                           >
//                             Transaction /
//                           </button>
//                           <p className="text-[#3AB6FF] font-semibold">
//                             Explore
//                           </p>
//                         </div>
//                         <div
//                           className={`${
//                             isDarkMode ? "bg-[#37547b]" : "bg-[#EDEDED]"
//                           } flex md:flex-row flex-col-reverse md:items-start items-center gap-5 mt-3 mx-10 md:rounded-br-[10rem] border border-white/30  p-7 `}
//                         >
//                           <div className="md:w-[70%] w-full ">
//                             <div className="flex justify-between">
//                               <p className="w-[50%] px-3 font-bold">Hash Id</p>
//                               <p
//                                 className={`w-[50%] ${
//                                   isDarkMode
//                                     ? "text-white/70"
//                                     : "text-[#05295A]"
//                                 } font-bold break-words`}
//                               >
//                                 62e33c90d578c25cf8eda0c46b175981d5114ea8531e9272accfe1cbfeda38
//                               </p>
//                             </div>
//                             <hr
//                               className={`my-5 ${
//                                 isDarkMode
//                                   ? "border border-white/30"
//                                   : "border border-[#37547b75]"
//                               } `}
//                             ></hr>

//                             <div className="flex justify-between">
//                               <p className="w-[50%] px-3 font-bold">To</p>
//                               <div className="w-[50%] flex items-center gap-2">
//                                 <p
//                                   className={` ${
//                                     isDarkMode
//                                       ? "text-white/70"
//                                       : "text-[#05295A]"
//                                   } font-bold  text-left overflow-x-hidden`}
//                                 >
//                                   e46b175981d5114ea8531e9272ac
//                                 </p>
//                                 <FontAwesomeIcon
//                                   icon={faCopy}
//                                   style={{ color: "#6AC8FF" }}
//                                 />
//                               </div>
//                             </div>
//                             <hr
//                               className={`my-5 ${
//                                 isDarkMode
//                                   ? "border border-white/30"
//                                   : "border border-[#37547b75]"
//                               } `}
//                             ></hr>

//                             <div className="flex justify-between">
//                               <p className="w-[50%] px-3 font-bold">From</p>
//                               <p
//                                 className={`w-[50%] ${
//                                   isDarkMode
//                                     ? "text-white/70"
//                                     : "text-[#05295A]"
//                                 } font-bold break-words`}
//                               >
//                                 1KOgH1kvbQ60nIpn7bf0u7txeUv
//                               </p>
//                             </div>
//                             <hr
//                               className={`my-5 ${
//                                 isDarkMode
//                                   ? "border border-white/30"
//                                   : "border border-[#37547b75]"
//                               } `}
//                             ></hr>

//                             <div className="flex justify-between">
//                               <p className="w-[50%] px-3 font-bold">Time</p>
//                               <p
//                                 className={`w-[50%] ${
//                                   isDarkMode
//                                     ? "text-white/70"
//                                     : "text-[#05295A]"
//                                 } font-bold`}
//                               >
//                                 7:15pm 24/04/2024
//                               </p>
//                             </div>
//                             <hr
//                               className={`my-5 ${
//                                 isDarkMode
//                                   ? "border border-white/30"
//                                   : "border border-[#37547b75]"
//                               } `}
//                             ></hr>

//                             <div className="flex justify-between">
//                               <p className="w-[50%] px-3 font-bold">Status</p>
//                               <p className="w-[50%] font-bold text-green-500">
//                                 Success
//                               </p>
//                             </div>
//                             <hr
//                               className={`my-5 ${
//                                 isDarkMode
//                                   ? "border border-white/30"
//                                   : "border border-[#37547b75]"
//                               } `}
//                             ></hr>
//                           </div>
//                           <div className="w-[30%]">
//                             <img src="../assets/Untitled design (8) 1.png" />
//                           </div>
//                         </div>
//                       </div>
//                     )}
//                   </table>
//                 </div>
//               </div>
//               <div className=" flex justify-between text-xs px-10 pt-3">
//                 <button
//                   className="bg-white/30 rounded-md px-5 py-1"
//                   onClick={handlePrevClick}
//                   disabled={currentPage === 1}
//                 >
//                   <FontAwesomeIcon icon={faArrowLeft} />
//                 </button>
//                 <button
//                   className="bg-white/30 rounded-md px-5 py-1"
//                   onClick={handleNextClick}
//                   disabled={currentEntities.length < entitiesPerPage}
//                 >
//                   <FontAwesomeIcon icon={faArrowRight} />
//                 </button>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Wallet;






import React, { useState, useEffect } from "react";
import Header from "./Header";
import Sidebar from "./Sidebar";
import "../App.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRight,
  faArrowLeft,
  faCopy,
  faSliders,
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { BASE_URL } from "../config";
import DataSpinners from "../Spinner";
import { useLocation } from "react-router-dom";


const Wallet = () => {

  const [isDarkMode, setIsDarkMode] = useState(true);
  const [newProfile, setNewProfile] = useState(0);
  const [lastMonth, setLastMonth] = useState(0);
  const [profilePercentage, setProfilePercentage] = useState(0);
  const [totalProfile, setTotalProfile] = useState(0);
  const [transactions, setTransaction] = useState([]);
  const [balance, setBalance] = useState(0);
  const [spinner, setSpinner] = useState(false);

  
  const toggleDarkMode = () => {
    setIsDarkMode(!isDarkMode);
  };


  const [searchQuery, setSearchQuery] = useState("");
  const [filteredTransactions, setFilteredTransaction] = useState([]);
  const location = useLocation();


  useEffect(() => {
    const fetchUserProfiles = async () => {
      try {
        setSpinner(true);
        const response = await axios.get(`${BASE_URL}/api/wallet`);
        console.log("fetchUserProfiles", response.data);
        if (response.data.status === "success") {
          setNewProfile(response.data.new_profile);
          setLastMonth(response.data.last_month_profile);
          setProfilePercentage(response.data.percentage);
          setTotalProfile(response.data.total_profile);
          setTransaction(response.data.transactions);
          setBalance(response.data.balance);
          setSpinner(false);
        } else {
          console.error("Failed to fetch user profiles:", response.data.error);
          setSpinner(false);
        }
      } catch (error) {
        setSpinner(false);
        console.error(
          "Error fetching user profiles:",
          error.response.data.error
        );
      }
    };

    fetchUserProfiles();
  }, []);


  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const searchParam = queryParams.get('search');

    console.log('searchParam',searchParam)
    if (searchParam) {
        setSearchQuery(searchParam);
    }
}, [location.search]);


useEffect(() => {
  const filterProfiles = () => {
      if (searchQuery) {
          const lowercasedQuery = searchQuery.toLowerCase();
          const filtered = transactions.filter(profile => 
              (profile.name && profile.name.toLowerCase().includes(lowercasedQuery)) ||
              (profile.email && profile.email.toLowerCase().includes(lowercasedQuery)) ||
              (profile.wallet_address && profile.wallet_address.toLowerCase().includes(lowercasedQuery)) ||
              (profile.kitNumber && profile.kitNumber.toLowerCase().includes(lowercasedQuery)) ||
              (profile.thx_hash && profile.thx_hash.toLowerCase().includes(lowercasedQuery)) ||
              (profile.balance && profile.balance.toLowerCase().includes(lowercasedQuery)) ||
              (profile.view_status && profile.view_status.toLowerCase().includes(lowercasedQuery)) ||            
              (profile.created_at && profile.created_at.toLowerCase().includes(lowercasedQuery)) 

              
          );
          setFilteredTransaction(filtered);
      } else {
        setFilteredTransaction(transactions);
      }
  };
  filterProfiles();
}, [searchQuery, transactions]);



  const [isShare, setShare] = useState(false);

  const handleShare = () => {
    setShare(!isShare);
  };

  const [walletdetail, setWalletDetail] = useState(false);
  const handlewallet = () => {
    setWalletDetail(!walletdetail);
  };

  const [currentPage, setCurrentPage] = useState(1);
  const [entitiesPerPage, setEntitiesPerPage] = useState(5);

  const indexOfLastEntity = currentPage * entitiesPerPage;
  const indexOfFirstEntity = indexOfLastEntity - entitiesPerPage;

  let currentEntities = filteredTransactions.slice(
    indexOfFirstEntity,
    indexOfLastEntity
  );

  const emptyRowCount = entitiesPerPage - currentEntities.length;

  const handlePrevClick = () => {
    setCurrentPage(currentPage - 1);
  };

  const handleNextClick = () => {
    if (currentEntities.length === entitiesPerPage) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handleDropdownChange = (e) => {
    setEntitiesPerPage(parseInt(e.target.value));
    setCurrentPage(1);
  };


  const convertUTCToIST = (utcDate) => {
    const date = new Date(utcDate);
    return date.toLocaleString('en-IN', { timeZone: 'Asia/Kolkata' });
  };

  return (
    <div className={`${isDarkMode ? "dark" : "light"}`}>
      <Header toggleDarkMode={toggleDarkMode} isDarkMode={isDarkMode} />

      <div
        className={` ${
          isDarkMode ? "bg-[#05295A] text-white" : "bg-white text-black"
        }  flex min-h-screen font-dmsans pt-5`}
      >
        <Sidebar isDarkMode={isDarkMode} />

        <div className="lg:w-[80%] w-full relative lg:container lg:mx-auto">
          <div className="flex md:flex-row flex-col mx-5 items-center gap-10">
            <div className="md:w-1/3 w-full  ">
              <p className="font-bold text-[#38B6FF] mb-3"> Profiles Created</p>
              <div
                className={`border border-white 2xl:h-[12rem] h-[9rem] flex flex-col justify-center ${
                  isDarkMode
                    ? "bg-gradient-to-r from-[#b0b0b062] to-[#05295B]"
                    : "bg-[#3ab7ff28]"
                }   rounded-lg shadow-md shadow-[#3AB6FF99] `}
              >
                <div className="flex justify-around items-center">
                  <p className="2xl:text-5xl text-4xl font-semibold">
                    {newProfile}
                  </p>
                  <img src="../assets/Chart.png" />
                </div>
                <div className="flex justify-around items-center mt-3">
                  <div className="flex flex-col items-center gap-1">
                    <p className="text-base">This Month</p>
                    <p className="text-[#3EDD87] font-bold text-base">
                      {profilePercentage} %
                    </p>
                  </div>
                  <div className="flex flex-col items-center gap-1">
                    <p className="text-base">Last Month</p>
                    <p className="text-[#F46565] font-bold text-base">
                      {lastMonth}
                    </p>
                  </div>
                  <div className="flex flex-col items-center gap-1">
                    <p className="text-base">Total</p>
                    <p
                      className={`${
                        isDarkMode ? "text-[#CABBB2]" : "text-black"
                      } font-bold text-base`}
                    >
                      {totalProfile}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="md:w-1/3 w-full  ">
              <p className="font-bold text-[#38B6FF] mb-3">Ownership</p>
              <div
                className={`border border-white 2xl:h-[12rem] h-[9rem] flex flex-col justify-center ${
                  isDarkMode
                    ? "bg-gradient-to-r from-[#b0b0b062] to-[#05295B]"
                    : "bg-[#3ab7ff28]"
                }  rounded-lg shadow-md shadow-[#3AB6FF99]`}
              >
                <div className="flex justify-around items-center">
                  <p className="2xl:text-5xl text-4xl font-semibold">
                    {totalProfile}
                  </p>
                  <img src="../assets/Chart.png" />
                </div>
                <div className="flex justify-around mt-3">
                  <p className="text-base flex gap-3">
                    {" "}
                    Total Ownership’s
                    <span className=" text-[#1EEF32] font-bold">
                      Transferred
                    </span>{" "}
                  </p>
                </div>
              </div>
            </div>
            <div className="md:w-1/3 w-full  ">
              <p className="font-bold text-[#38B6FF] mb-3">Matic Balance</p>
              <div
                className={`border border-white 2xl:h-[12rem] h-[9rem] flex flex-col justify-center ${
                  isDarkMode
                    ? "bg-gradient-to-r from-[#b0b0b062] to-[#05295B]"
                    : "bg-[#3ab7ff28]"
                }  rounded-lg shadow-md shadow-[#3AB6FF99]`}
              >
                <div className="flex justify-around items-center">
                  <p className="2xl:text-5xl text-3xl font-semibold">
                    {balance.toFixed(4)} MATIC
                  </p>
                </div>
              </div>
            </div>
            {/* <div className="md:w-1/3 w-full  mt-9 ">
              <div className="2xl:h-[12rem] h-[9rem] bg-white flex justify-center items-center  border border-[#38B6FF] rounded-lg ">
                <div className="flex flex-col justify-center items-center gap-2 " onClick={handleShare}>
                  <img src="../assets/Plus.png" />
                  <p className="text-[#3AB6FF]">Add Highlight</p>
                </div>
              </div>
              {isShare && (
                <div className="md:w-[100%] z-10 rounded-2xl modal-overlay fixed inset-0 flex flex-col lg:flex-row items-center justify-center bg-white bg-opacity-10 backdrop-blur-md" data-aos="fade-zoom-in">
                  <div className='relative'>
                    <img src='/assets/Group 427320848.png' className='w-[90%] md:w-[80%] mx-auto'></img>
                    <div className='absolute inset-0 w-[80%] md:w-[75%] mx-auto'>
                      <div className='flex justify-end' >
                        <button className=' shadow-xl rounded-lg text-xl text-white font-bold    transition-transform transform-gpu hover:-translate-y-1 hover:shadow-lg mt-3 mr-2' onClick={handleShare} ><FontAwesomeIcon icon={faXmark}></FontAwesomeIcon></button>
                      </div>
                      <div className='md:pt-20 pb-5 justify-center flex gap-2'>
                        <div><input type='checkbox'></input></div>
                        <p className='font-bold'>Are you sure you want to share your health reports?</p>
                      </div>
                      <div className='flex justify-center'><button className='font-bold text-white py-2 px-10 rounded-lg bg-gradient-to-bl from-[#004696] to-[#3AB6FF] to-90%'>Confirm</button></div>
                    </div>
                  </div>
                </div>
              )}
            </div> */}
          </div>

          <div className="pt-5 mx-5">
            <div className="flex justify-between">
              <p className="text-[#38B6FF] font-bold ">Transaction History</p>
              <div className="flex items-center gap-2">
                <p className="text-[#38B6FF]">Filter</p>
                <FontAwesomeIcon icon={faSliders} size="xs" />
              </div>
            </div>

            <div className="mt-3">
              <div
                className={`${
                  isDarkMode ? "bg-white/10" : "bg-[#3ab7ff28]"
                } w-full rounded-md`}
              >
                <p
                  className={` ${
                    isDarkMode ? "text-white" : "text-black"
                  } text-end 2xl:text-base text-xs px-3 2xl:pt-10 pt-2`}
                >
                  Show no of entity
                  <select
                    className="ml-2 outline-none rounded-md bg-transparent border-[1px]"
                    onChange={handleDropdownChange}
                    value={entitiesPerPage}
                  >
                    <option className="text-black" value="5">
                      5
                    </option>
                    <option className="text-black" value="10">
                      10
                    </option>
                    <option className="text-black" value="15">
                      15
                    </option>
                  </select>
                </p>

                <div className=" overflow-hidden overflow-x-scroll overflow-y-scroll">
                  {/* <table className="w-full">
                    <thead className="">
                      <tr
                        className={`border-b  border-white/40 2xl:text-2xl ${
                          isDarkMode ? "text-[#C8C2E8]" : "text-black"
                        } `}
                      >
                        <th className="md:px-10 px-14 2xl:py-7 py-5 text-left">
                          Kit Number
                        </th>
                        <th className="md:px-10 px-14 2xl:py-7 py-5 text-left">
                          Wallet address
                        </th>
                        <th className="md:px-10 px-14 2xl:py-7 py-5 text-left">
                          Transaction Hash
                        </th>
                        <th className="md:px-10 px-14 2xl:py-7 py-5 text-left">
                          Date
                        </th>
                        <th className="md:px-10 px-14 2xl:py-7 py-5 text-left">
                          Status
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {currentEntities.length === 0 ? (
                        <tr>
                          <td colSpan="5" className={`text-center py-5 font-bold ${isDarkMode ? 'text-white' : 'text-black'} `}>
                            No transaction history found
                          </td>
                        </tr>
                      ) : (
                        currentEntities.map((data, index) => (
                          <tr
                            key={index}
                            className={`${
                              isDarkMode ? "text-white/75" : "text-black"
                            }`}
                          >
                            <td className=" 2xl:text-xl text-sm px-5 lg:px-5 lg:text-left text-center 2xl:py-5 py-3">
                              <div className=" flex items-center gap-2">
                                <div className="bg-white 2xl:w-11 2xl:h-12 md:w-10 w-8 h-8 p-1 rounded-sm relative">
                                  <img
                                    src="../assets/Group 427320861.png"
                                    className="w-5 2xl:w-8 absolute"
                                  />
                                </div>
                                <p className="text-[#3AB6FF]">
                                  {data.kitNumber}
                                </p>
                              </div>
                            </td>
                            <td className=" 2xl:text-xl text-sm px-5 lg:px-5 lg:text-left text-center 2xl:py-5 py-3 font-semibold">
                              <button className=" cursor-pointer ">
                                {data.wallet_address}
                              </button>
                            </td>
                            <td className=" 2xl:text-xl text-sm px-5 lg:px-5 lg:text-left text-center 2xl:py-5 py-3 ">
                              <a
                                href={
                                  "https://amoy.polygonscan.com/tx/" +
                                  data.thx_hash
                                }
                                className="cursor-pointer"
                                target="_blank"
                              >
                                {data.thx_hash}
                              </a>
                            </td>
                            <td className=" 2xl:text-xl text-sm px-5 lg:px-5 lg:text-left text-center 2xl:py-5 py-3">
                              {data.created_at}
                            </td>
                            <td
                              className={` 2xl:text-xl text-sm px-5 lg:px-5 lg:text-left text-center 2xl:py-5 font-semibold py-3 ${
                                data.status === "Pending"
                                  ? "text-yellow-500"
                                  : data.status === "Approved"
                                  ? "text-green-500"
                                  : data.status === "Failed"
                                  ? "text-red-500"
                                  : ""
                              }`}
                            >
                              {data.status}
                            </td>
                          </tr>
                        ))
                      )}
                      {Array.from({ length: emptyRowCount }).map((_, index) => (
                        <tr key={`empty-${index}`} className="h-14">
                          <td>&nbsp;</td>
                          <td>&nbsp;</td>
                          <td>&nbsp;</td>
                        </tr>
                      ))}
                    </tbody>
                    {walletdetail && (
                      <div
                        className={`absolute top-0 left-0 w-full h-full overflow-hidden overflow-y-auto md:overflow-hidden ${
                          isDarkMode
                            ? "bg-[#05295A] text-white"
                            : "bg-white text-black"
                        }`}
                      >
                        <div className="flex gap-2 mx-10">
                          <button
                            className="text-[#3AB6FF99] font-semibold"
                            onClick={handlewallet}
                          >
                            Transaction /
                          </button>
                          <p className="text-[#3AB6FF] font-semibold">
                            Explore
                          </p>
                        </div>
                        <div
                          className={`${
                            isDarkMode ? "bg-[#37547b]" : "bg-[#EDEDED]"
                          } flex md:flex-row flex-col-reverse md:items-start items-center gap-5 mt-3 mx-10 md:rounded-br-[10rem] border border-white/30  p-7 `}
                        >
                          <div className="md:w-[70%] w-full ">
                            <div className="flex justify-between">
                              <p className="w-[50%] px-3 font-bold">Hash Id</p>
                              <p
                                className={`w-[50%] ${
                                  isDarkMode
                                    ? "text-white/70"
                                    : "text-[#05295A]"
                                } font-bold break-words`}
                              >
                                62e33c90d578c25cf8eda0c46b175981d5114ea8531e9272accfe1cbfeda38
                              </p>
                            </div>
                            <hr
                              className={`my-5 ${
                                isDarkMode
                                  ? "border border-white/30"
                                  : "border border-[#37547b75]"
                              } `}
                            ></hr>

                            <div className="flex justify-between">
                              <p className="w-[50%] px-3 font-bold">To</p>
                              <div className="w-[50%] flex items-center gap-2">
                                <p
                                  className={` ${
                                    isDarkMode
                                      ? "text-white/70"
                                      : "text-[#05295A]"
                                  } font-bold  text-left overflow-x-hidden`}
                                >
                                  e46b175981d5114ea8531e9272ac
                                </p>
                                <FontAwesomeIcon
                                  icon={faCopy}
                                  style={{ color: "#6AC8FF" }}
                                />
                              </div>
                            </div>
                            <hr
                              className={`my-5 ${
                                isDarkMode
                                  ? "border border-white/30"
                                  : "border border-[#37547b75]"
                              } `}
                            ></hr>

                            <div className="flex justify-between">
                              <p className="w-[50%] px-3 font-bold">From</p>
                              <p
                                className={`w-[50%] ${
                                  isDarkMode
                                    ? "text-white/70"
                                    : "text-[#05295A]"
                                } font-bold break-words`}
                              >
                                1KOgH1kvbQ60nIpn7bf0u7txeUv
                              </p>
                            </div>
                            <hr
                              className={`my-5 ${
                                isDarkMode
                                  ? "border border-white/30"
                                  : "border border-[#37547b75]"
                              } `}
                            ></hr>

                            <div className="flex justify-between">
                              <p className="w-[50%] px-3 font-bold">Time</p>
                              <p
                                className={`w-[50%] ${
                                  isDarkMode
                                    ? "text-white/70"
                                    : "text-[#05295A]"
                                } font-bold`}
                              >
                                7:15pm 24/04/2024
                              </p>
                            </div>
                            <hr
                              className={`my-5 ${
                                isDarkMode
                                  ? "border border-white/30"
                                  : "border border-[#37547b75]"
                              } `}
                            ></hr>

                            <div className="flex justify-between">
                              <p className="w-[50%] px-3 font-bold">Status</p>
                              <p className="w-[50%] font-bold text-green-500">
                                Success
                              </p>
                            </div>
                            <hr
                              className={`my-5 ${
                                isDarkMode
                                  ? "border border-white/30"
                                  : "border border-[#37547b75]"
                              } `}
                            ></hr>
                          </div>
                          <div className="w-[30%]">
                            <img src="../assets/Untitled design (8) 1.png" />
                          </div>
                        </div>
                      </div>
                    )}
                  </table> */}

                  <table className="w-full">
                    <thead className="">
                      <tr
                        className={`border-b border-white/40 ${
                          isDarkMode ? "text-[#C8C2E8]" : "text-black"
                        } `}
                      >
                        <th className="px-4 py-3">Kit Number</th>
                        <th className="px-4 py-3">Transaction Hash</th>
                        <th className="px-4 py-3">Date</th>
                        <th className="px-4 py-3">Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {currentEntities.length === 0 ? (
                        <tr>
                          <td
                            colSpan="5"
                            className={`text-center py-5 font-bold ${
                              isDarkMode ? "text-white" : "text-black"
                            }`}
                          >
                            No transaction history found
                          </td>
                        </tr>
                      ) : (
                        currentEntities.map((data, index) => (
                          <tr
                            key={index}
                            className={`${
                              isDarkMode ? "text-white/75" : "text-black"
                            } text-sm`}
                          >
                            <td className="px-4 py-3 whitespace-nowrap">
                              <div className="flex items-center gap-2">
                                <div className="bg-white 2xl:w-11 2xl:h-12 w-7 h-8 p-1 rounded-sm relative">
                                  <img
                                    src="../assets/Group 427320861.png"
                                    className="w-5 absolute"
                                  />
                                </div>
                                <p className="text-[#3AB6FF]">
                                  {data.kitnumber}
                                </p>
                              </div>
                            </td>

                            <td className="px-4 py-3 whitespace-nowrap">
                              <a
                                href={
                                  "https://amoy.polygonscan.com/tx/" +
                                  data.thx_hash
                                }
                                className="cursor-pointer truncate"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {data.thx_hash}
                              </a>
                            </td>
                            <td className="px-4 py-3 whitespace-nowrap">
                            {convertUTCToIST(data.created_at)}
                             
                            </td>
                            <td
                              className={`px-4 py-3 whitespace-nowrap text-center font-semibold ${
                                data.view_status === 0
                                  ? "text-green-500"
                                  : "text-red-500"
                              }`}
                            >
                              {data.view_status === 0 ? "Success" : "Fail"}
                            </td>
                          </tr>
                        ))
                      )}
                      {/* {Array.from({ length: emptyRowCount }).map((_, index) => (
                        <tr key={`empty-${index}`} className="h-14">
                          <td>&nbsp;</td>
                          <td>&nbsp;</td>
                          <td>&nbsp;</td>
                        </tr>
                      ))} */}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className=" flex justify-between text-xs px-10 pt-3">
                <button
                  className="bg-white/30  cursor-pointer rounded-md px-5 py-1"
                  onClick={handlePrevClick}
                  disabled={currentPage === 1}
                >
                  <FontAwesomeIcon icon={faArrowLeft} />
                </button>
                <button
                  className="bg-white/30 cursor-pointer rounded-md px-5 py-1"
                  onClick={handleNextClick}
                  disabled={currentEntities.length < entitiesPerPage}
                >
                  <FontAwesomeIcon icon={faArrowRight} />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {spinner ? <DataSpinners /> : ""}
    </div>
  );
};

export default Wallet;