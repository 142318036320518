import React, { useState, useRef, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';
import axios from 'axios';
import toast, { Toaster } from 'react-hot-toast';
import { BASE_URL } from '../config';
import Sidebar from './Sidebar';
import Header from './Header';

const Changepassword = ({ length = 4 }) => {
  const [email, setEmail] = useState('');
  const [showOtpInput, setShowOtpInput] = useState(false);
  const [otp1, setOtp1] = useState(new Array(length).fill(''));
  const [isVerifyClicked, setIsVerifyClicked] = useState(false);
  const user_email = useSelector((state) => state.admin_email);
  const [isDarkMode, setIsDarkMode] = useState(true);

  const handleSendOTP = () => {
    setShowOtpInput(!showOtpInput);
    axios
      .post(`${BASE_URL}/api/admin/admin_resetPasswordOtp`, { email: user_email })
      .then((response) => {
        console.log("response",response)
        console.log('Email sent successfully');
        toast.success('OTP sent successfully');
      })
      .catch((error) => {
        toast.error('Error sending OTP');
        console.error('Error sending email:', error);
      });
  };

  const handleCloseOTP = () => {
    setShowOtpInput(!showOtpInput);
  };

  const handleChange = (index, e) => {
    const value = e.target.value;
    if (isNaN(value)) return;

    const newOtp = [...otp1];
    newOtp[index] = value.substring(value.length - 1);
    setOtp1(newOtp);

    const combinedOtp = newOtp.join('');
    if (combinedOtp.length === length) onOtpSubmit(combinedOtp);

    // Move focus to the next input field if available and the current field is filled
    if (value && index < length - 1 && inputRefs.current[index + 1]) {
      inputRefs.current[index + 1].focus();
    }
  };

  const inputRefs = useRef([]);

  useEffect(() => {
    if (inputRefs.current[0]) {
      inputRefs.current[0].focus();
    }
  }, []);

  const onOtpSubmit = (otp) => {
    console.log('Success', otp);
    if (isVerifyClicked) {
      const combinedData = {
        email: user_email,
        emailOtp: otp,
      };

      axios
        .post(`${BASE_URL}/api/admin/admin_verifyOTP`, combinedData)
        .then((response) => {
          if (response.data.success) {
            console.log("response",response.data )
            toast.success('OTP verification successful');
            console.log('OTP verification successful');

            if (isVerifyClicked) {
              setShowOtpInput(false);
            }
            setNewPassword(true);
          } else {
            toast.error('Invalid OTP');
            console.error('Invalid OTP');
          }
        })
        .catch((error) => {
          toast.error('Error verifying OTP');
          console.error('Error verifying OTP:', error);
        });
    }
  };

  const handleClick = (index) => {
    inputRefs.current[index].setSelectionRange(1, 1);

    if (index > 0 && !otp1[index - 1]) {
      inputRefs.current[otp1.indexOf('')].focus();
    }
  };

  const handleKeyDown = (index, e) => {
    if (
      e.key === 'Backspace' &&
      !otp1[index] &&
      index > 0 &&
      inputRefs.current[index - 1]
    ) {
      inputRefs.current[index - 1].focus();
    }
  };

  const handleVerifyOTP = () => {
    setIsVerifyClicked(true);
    onOtpSubmit(otp1.join(''));
  };

  const [newpassword, setNewPassword] = useState('');
  const [password, setPassword] = useState('');
  const [isPasswordStrong, setIsPasswordStrong] = useState(false);

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);

    // Check if password meets the criteria for a strong password
    setIsPasswordStrong(
      newPassword.length >= 8 &&
        /[A-Z]/.test(newPassword) &&
        /[a-z]/.test(newPassword) &&
        /[0-9]/.test(newPassword) &&
        /[^A-Za-z0-9]/.test(newPassword)
    );
  };

  const handlepassword = () => {
    if (isPasswordStrong) {
      // Password is strong, submit email and password to server API
      const data = {
        email: user_email,
        password: password,
      };

      axios
        .post(`${BASE_URL}/api/admin/admin_UpdatePassword`, data)
        .then((response) => {
          if (response.data.success) {
            console.log('response',response.data)
            toast.success('Password updated successfully');
            console.log('Password updated successfully');
            setShowOtpInput(false);
            setNewPassword(false);
          } else {
            toast.error('Failed to update password');
            console.error('Failed to update password');
          }
        })
        .catch((error) => {
          toast.error('Error updating password');
          console.error('Error updating password:', error);
        });
    } else {
      toast.error('Password is not strong enough');
    }
  };

  const toggleDarkMode = () => {
    setIsDarkMode(!isDarkMode);
  };


  return (

    <div className={`relative ${isDarkMode ? 'dark' : 'light'}`}>

    <Header toggleDarkMode={toggleDarkMode} isDarkMode={isDarkMode} />
    <div className="">
      <img src="../assets/Ellipse 271.png" className="absolute bottom-0 right-0 " alt="Background" />
    </div>
    <div className={` ${isDarkMode ? 'bg-[#05295A]' : 'bg-white'} flex min-h-screen font-inter pt-5`}>
      <Sidebar isDarkMode={isDarkMode} />
      <div className="lg:w-[80%] w-full mx-5 custom-scrollbar">


    <div className='w-full h-screen flex justify-center mt-20'>
      <div className={`${isDarkMode ? "text-white":"text-black"}`}>
        <Toaster />


        <div className={`flex flex-col mt-10`}>
          <label className='font-bold '>Email</label>
          <input
            type='email'
            className='mt-2 mb-5 py-1.5 pl-7 rounded-lg border bg-white/10 outline-none'
            value={user_email}
            readOnly
          />

          <div className='mt-5 z-10'>
            <button
              onClick={handleSendOTP}
              className='w-full  px-10 md:px-20 py-3 text-sm font-bold rounded-xl bg-gradient-to-br from-[#3AB6FF] via-[#004696] to-[#FF3131] to-80%'
            >
              Send Otp
            </button>
          </div>
          {showOtpInput && (
            <div className='rounded-2xl z-50 w-full modal-overlay fixed inset-0 flex flex-col items-center justify-center bg-black bg-opacity-10 backdrop-blur-md'>
              <div className='flex flex-col justify-center items-center bg-white/25 p-10 rounded-lg relative'>
                <button onClick={handleCloseOTP}>
                  <FontAwesomeIcon
                    icon={faXmark}
                    className='absolute top-3 right-4'
                  />
                </button>
                <p className='text-base font-semibold'>Enter OTP sent</p>
                <div className='mt-3'>
                  {otp1.map((value, index) => (
                    <input
                      key={index}
                      type='text'
                      ref={(input) => (inputRefs.current[index] = input)}
                      value={value}
                      onChange={(e) => handleChange(index, e)}
                      onClick={() => handleClick(index)}
                      onKeyDown={(e) => handleKeyDown(index, e)}
                      className='w-10 h-10 m-3 bg-transparent focus:outline-none border border-gray-300 rounded-md text-center text-sm'
                    />
                  ))}
                </div>
                <button
                  onClick={handleVerifyOTP}
                  className='w-full mt-5 px-10 md:px-20 py-3 text-sm font-bold rounded-xl bg-gradient-to-br from-[#3AB6FF] via-[#004696] to-[#FF3131] to-80%'
                >
                  Verify OTP
                </button>
              </div>
            </div>
          )}
          {newpassword && (
            <div className='rounded-2xl z-50 w-full modal-overlay fixed inset-0 flex flex-col items-center justify-center bg-black bg-opacity-10 backdrop-blur-md'>
              <div className='flex flex-col justify-center bg-white/25 p-10 rounded-lg relative'>
                <button onClick={() => setNewPassword(false)}>
                  <FontAwesomeIcon
                    icon={faXmark}
                    className='absolute top-3 right-4'
                  />
                </button>
                <label className='text-white font-bold mx-5'>
                  Enter New password
                </label>
                <input
                  type='password'
                  value={password}
                  onChange={handlePasswordChange}
                  className='w-full px-5 py-1.5 m-3 bg-transparent focus:outline-none border border-gray-300 rounded-md text-left text-sm'
                />
                <button
                  onClick={handlepassword}
                  className='w-full mt-5 px-10 md:px-20 py-3 text-sm font-bold rounded-xl bg-gradient-to-br from-[#3AB6FF] via-[#004696] to-[#FF3131] to-80%'
                >
                  Submit
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
    </div>
    </div> 
       </div>
  );
};

export default Changepassword;
