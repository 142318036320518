import React, { useState } from "react";
import "../App.css";
import Header from "./Header";
import Sidebar from "./Sidebar";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import { BASE_URL } from "./config";

const SubAdmin = () => {
  const [isDarkMode, setIsDarkMode] = useState(true);
  const [emailid, setEmailId] = useState("");
  const [username, setUserName] = useState("");
  const [role, setRole] = useState("sub_admin");

  const toggleDarkMode = () => {
    setIsDarkMode(!isDarkMode);
  };

  const handleRoleChange = (e) => {
    setRole(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!emailid) {
      toast.error("Please fill in the email field");
      return;
    }

    // Mandatory access rights for sub-admins
    const mandatoryAccess = ["Create Profile", "Upload Document", "Profiles"];

    try {
      const response = await axios.post(`${BASE_URL}/api/admin/sub_admin_creation`, { 
        email: emailid, 
        role: role,
        access: mandatoryAccess // Sending mandatory access rights
      });
      if (response.data.status === "success") {
        toast.success("Sub Admin Profile created successfully");
        setTimeout(() => {
          window.location.href = "/Admin/SubAdmin";
        }, 500);
      }
    } catch (error) {
      toast.error("Already created for this mail ID");
      console.error("Error creating sub admin:", error.message);
    }
  };

  return (
    <div>
      <Header toggleDarkMode={toggleDarkMode} isDarkMode={isDarkMode} />
      <Toaster />
      <div className={` ${isDarkMode ? 'bg-[#05295A] text-white' : 'bg-[#3ab7ff28] text-black'} min-h-screen flex relative `}>
        <Sidebar isDarkMode={isDarkMode} />
        <div className="lg:w-[80%] w-full flex justify-center mt-5 mx-auto">
          <div className=" xl:w-[45%] sm:w-[50%] w-[80%] ">
            <div className="">
              <div className={` sm:px-10 px-5 py-7 2xl:py-14 font-notosans rounded-xl shadow-2xl border-white/20 border backdrop-blur-xl ${isDarkMode ? 'bg-gradient-to-b from-[#BFBFBF10] to-[#00000024]' : 'bg-[#EDEDED]'}`}>
                <div>
                  <p className="font-semibold text-3xl 2xl:text-5xl">Create Sub Admin Profile</p>
                </div>

                <div className="mt-5 flex">
                  <label htmlFor="role" className={`block text-base font-semibold ${isDarkMode ? 'text-white' : 'text-black'} w-[20%] py-2`}>
                    Role
                  </label>
                  <select
                    id="role"
                    name="role"
                    className={`bg-transparent focus:outline-none border w-[80%] ${isDarkMode ? "border-white/60" : "border-[#05295A]"}  rounded-lg w-full px-4 py-0.5  text-sm`}
                    value={role}
                    onChange={handleRoleChange}
                  >
                    <option className="text-black" value="admin">Admin</option>
                    <option className="text-black" value="sub_admin">Sub Admin</option>
                  </select>
                </div>

                <div className="mt-5 relative ">
                  <input
                    type="text"
                    placeholder="User Name"
                    className={`bg-transparent focus:outline-none border  ${isDarkMode ? 'border-white/60' : 'border-[#05295A]'} rounded-lg w-full px-4 py-1.5 md:text-base text-sm`}
                    value={username}
                    onChange={(e) => setUserName(e.target.value)}
                  />
                </div>

                <div className="mt-5">
                  <input
                    type="text"
                    placeholder="Email Id"
                    className={`bg-transparent focus:outline-none border ${isDarkMode ? 'border-white/60' : 'border-[#05295A]'}  rounded-lg w-full px-4 py-1.5 md:text-base text-sm `}
                    value={emailid}
                    onChange={(e) => setEmailId(e.target.value)}
                  />
                </div>

                <div className="mt-14 mb-5">
                  <button
                    className="w-full text-white md:p-1.5 p-1 rounded-xl font-semibold md:text-lg bg-gradient-to-r from-[#3AB6FF] via-[#004696] to-[#FF3131] "
                    onClick={handleSubmit}
                  >
                    Submit
                  </button>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubAdmin;
