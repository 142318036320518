import { faCheckCircle, faCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { BASE_URL } from "./config";
import axios from "axios";
import DataSpinners from "./Spinner";

export default function Accesshistory() {
  const [currentPage, setCurrentPage] = useState(1);
  const [entitiesPerPage, setEntitiesPerPage] = useState(5);
  const [spinner, setSpinner] = useState(false);

  const email = useSelector((state) => state.user_email);

  const [tableEntries, setTableEntries] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      setSpinner(true);
      const response = await axios.post(
        `${BASE_URL}/api/fetch_request_access`,
        { to_email: email }
      );
      setTableEntries(response.data);
      setSpinner(false);

      console.log("response", response);
    } catch (error) {
      setSpinner(false);
      console.error("Error fetching notifications:", error.message);
    }
  };

  const indexOfLastEntity = currentPage * entitiesPerPage;
  const indexOfFirstEntity = indexOfLastEntity - entitiesPerPage;

  let currentEntities = tableEntries.slice(
    indexOfFirstEntity,
    indexOfLastEntity
  );

  const emptyRowCount = entitiesPerPage - currentEntities.length;

  const handlePrevClick = () => {
    setCurrentPage(currentPage - 1);
  };

  const handleNextClick = () => {
    if (currentEntities.length === entitiesPerPage) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handleDropdownChange = (e) => {
    setEntitiesPerPage(parseInt(e.target.value));
    setCurrentPage(1);
  };

  return (
    <div className="md:h-screen overflow-hidden overflow-y-auto w-[100%]">
      <div className="md:w-[95%] bg-white/20 rounded-2xl py-10 px-5 md:p-10">
        <p className=" text-end text-xs px-3 mb-2">
          Show no of entity
          <select
            className="ml-2 outline-none rounded-md bg-transparent border-[1px]"
            onChange={handleDropdownChange}
            value={entitiesPerPage}
          >
            <option className="text-black" value="5">
              5
            </option>
            <option className="text-black" value="10">
              10
            </option>
            <option className="text-black" value="15">
              15
            </option>
          </select>
        </p>
        <div className=" pb-5 rounded-xl overflow-hidden overflow-x-auto ">
          <table className="w-full text-center">
            <thead className="">
              <tr className=" text-sm md:text-md">
                <th className="py-5 px-5 md:px-0">Name</th>
                <th className="py-5 px-5 md:px-0">Date of Request</th>
                <th className="py-5 px-5 md:px-0">Request Form </th>
                <th className="py-5 px-5 md:px-0">Report Type</th>
                <th className="py-5 px-5 md:px-0">Status</th>
              </tr>
            </thead>
            <tbody>
              {tableEntries.length === 0 ? (
                <tr>
                  <td colSpan="5" className="text-center py-4 font-bold">
                    No data found
                  </td>
                </tr>
              ) : (
                currentEntities.map((entity, index) => (
                  <tr
                    key={index}
                    className="h-16 bg-white/20 border-y-4 border-[#37547b] text-xs md:text-sm rounded-md"
                  >
                    <td className="rounded-l-md px-5 md:px-0">
                      {entity.from_email}
                    </td>
                    <td className="px-5 md:px-0">
                      {new Date(entity.created_at).toLocaleDateString("en-IN")}
                      {"   -   "}
                      {new Date(entity.created_at).toLocaleTimeString("en-IN")}
                    </td>
                    <td
                      className={`px-5 md:px-0 ${
                        entity.view_status === null
                          ? "text-yellow-500"
                          : entity.view_status === "1"
                          ? "text-[#1EEF32]"
                          : "text-[#FF0000]"
                      }`}
                    >
                      {entity.view_status === null
                        ? "Pending"
                        : entity.view_status === "1"
                        ? "Approved"
                        : "Rejected"}
                      <FontAwesomeIcon
                        icon={faCheckCircle}
                        className="pl-1 text-xs"
                      />
                    </td>
                    <td className="px-5 md:px-0">{entity.document_type}</td>
                    <td className="px-5 md:px-0 rounded-r-md">
                      {entity.view_status === "1"
                        ? "Active"
                        : entity.view_status === "0"
                        ? "Expired"
                        : "Pending"}
                      <FontAwesomeIcon
                        icon={faCircle}
                        className={`text-[7.5px] pl-1 ${
                          entity.view_status === "1"
                            ? "text-[#1EEF32]"
                            : entity.view_status === "0"
                            ? "text-[#FF0000]"
                            : "text-[#FFFF00]"
                        }`}
                      />
                    </td>
                  </tr>
                ))
              )}
              {/* Add empty rows if needed */}
              {Array.from({ length: emptyRowCount }).map((_, index) => (
                <tr key={`empty-${index}`} className="h-14">
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="mt-2 flex justify-between text-xs px-3">
          <button
            className="bg-white/30 rounded-md px-5 py-1"
            onClick={handlePrevClick}
            disabled={currentPage === 1}
          >
            Prev
          </button>
          <button
            className="bg-white/30 rounded-md px-5 py-1"
            onClick={handleNextClick}
            disabled={currentEntities.length < entitiesPerPage}
          >
            Next
          </button>
        </div>
      </div>
      {spinner ? <DataSpinners /> : ""}
    </div>
  );
}
