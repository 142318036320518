import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'
import { faArrowRight, faCircle } from '@fortawesome/free-solid-svg-icons';
import 'slick-carousel/slick/slick.css';
import Slider from 'react-slick';
import './App.css';
import LandingHeader from './LandingHeader';
import LandingFooter from './LandingFooter';

export default function Landing() {
    const [activeButton, setActiveButton] = useState('div1');

    const handleAnchorClick = (id) => {
        setActiveButton(id);
    };
    const [currentSlide, setCurrentSlide] = useState(0);

    const [asked1, setAsked1] = useState(false);
    const [asked2, setAsked2] = useState(false);
    const [asked3, setAsked3] = useState(false);
    const [asked4, setAsked4] = useState(false);

    const handleAsked1 = () => {
        setAsked1(!asked1);
    };

    const handleAsked2 = () => {
        setAsked2(!asked2);
    };

    const handleAsked3 = () => {
        setAsked3(!asked3);
    };

    const handleAsked4 = () => {
        setAsked4(!asked4);
    };

    const settings = {
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000,
        responsive: [
            {
                breakpoint: 640,
                settings: {
                    slidesToShow: 1,
                },
            },
        ],
    };
    const settings1 = {
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000,
        afterChange: (current) => setCurrentSlide(current),
        responsive: [
            {
                breakpoint: 640,
                settings: {
                    slidesToShow: 1,
                },
            },
        ],
    };

    return (
        <div className='font-poppins'>
            <LandingHeader />
            <div className='sec-1 flex flex-col gap-5 md:gap-10 md:flex-row w-[90%] mx-auto '>
                <div className='md:w-[50%] grid content-center gap-7'>
                    <p className='pt-10 md:pt-0 text-2xl md:text-5xl md:leading-[3.5rem] font-semibold'>Securely Store and Access Your Precision Health Information Anywhere, Anytime</p>
                    <p className='text-[#878C91] text-base'>Precisya offers a revolutionary solution to digitize and store your Precision health Information as BlockChain Enabled Profile Access, ensuring data integrity, privacy, and accessibility.</p>
                    <div><button className='px-10 py-3 text-white bg-black rounded-full text-sm font-bold'>Get Started <FontAwesomeIcon className='text-sm pl-2' icon={faArrowRight}></FontAwesomeIcon></button></div>
                    <div className='flex justify-between gap-2 md:gap-0'>
                        <div className='w-[25%]'>
                            <p className='text-sm md:text-xs font-bold'>Trusted by the world's popular countries</p>
                        </div>
                        <div className=''>
                            <p className='font-bold text-[#8C9498]'>America</p>
                        </div>
                        <div>
                            <p className='font-bold text-[#8C9498]'>Middle East</p>
                        </div>
                        <div>
                            <p className='font-bold text-[#8C9498]'>Europe</p>
                        </div>
                        <div>
                            <p className='font-bold text-[#8C9498]'>Malaysia</p>
                        </div>
                    </div>
                </div>
                <div class="md:w-[50%] grid content-center gap-5">
                    <div class="flex justify-center">
                        <img 
                        src='../assets/Doctor.png '
                        //src="../assets/Untitled design (4) 1 (1).png"
                        alt='Doctor'
                         class="w-[60%]" />
                    </div>
                    <div class="relative flex justify-center">
                        <img src="../assets/Group 427320839.png" class="md:w-[90%]" alt="pic1" />
                        <img src="../assets/31.png" class="absolute inset-0 ml-auto -translate-y-[5rem] w-[50%]" alt="pic2" />
                    </div>
                </div>
            </div>
            <div className='sec-2 my-10 w-[90%] mx-auto '>
                <div className='flex flex-col md:flex-row gap-5 md:gap-10'>
                    <div className='md:w-[50%]'>
                        <p className='font-semibold text-2xl md:text-4xl'>We Provide the best service with Personalized Healthcare</p>
                    </div>
                    <div className='md:w-[50%]'>
                        <p className='text-[#878C91] '> As a world leader in precision health, Precisya leverages the world's largest genomic big data aggregated over the last 12 years creating a genomic universe of its own to analyze the sequenced DNA profile of individuals, generating Genetic Reports that serve as a blueprint for Precision Health.</p>
                    </div>
                </div>
                <div className='flex flex-col md:flex-row justify-center gap-5 mt-7' >
                    <div className='rounded-2xl py-10 grid content-between text-white md:w-[30%] bg-cover ' style={{ backgroundImage: 'url("../assets/bg.png")' }}>
                        <div className='px-10'>
                            <p className='flex'><p className='text-4xl md:text-7xl font-bold'>720 </p><span className='font-bold text-2xl md:text-6xl text-[#3AB6FE] md:pt-2'>+</span></p>
                            <p className='text-[#AEB1B5] mt-3'>Happy Users Around The World</p>
                        </div>
                        <div className='flex justify-center'>
                            <img src='../assets/Ellipse 1.png' className='w-[4rem]'></img>
                            <img src='../assets/Ellipse 2.png' className='w-[4rem]'></img>
                            <img src='../assets/Ellipse 3.png' className='w-[4rem]'></img>
                            <img src='../assets/Ellipse 4.png' className='w-[4rem]'></img>
                            <div><p className='font-bold text-6xl pt-2'>+</p></div>
                        </div>
                    </div>
                    <div className=' relative'>
                        <img src='../assets/Group 427320842.png' className='w-[100%]'></img>
                        <img src='../assets/Group 427320837.png' className='w-[20%] md:w-auto absolute inset-0 ml-auto mt-auto translate-y-5 translate-x-5'></img>
                    </div>
                </div>
            </div>
            <div className='sec-3 bg-gray-100 py-3'>
                <div className=' w-[98%] mx-auto rounded-2xl bg-center bg-cover text-white ' style={{ backgroundImage: 'url("../assets/bg.png")' }}>
                    <div className='py-10 px-7 backdrop-blur-md rounded-2xl'>
                        <h1 className='font-semibold text-center text-2xl md:text-4xl md:leading-[3rem]'>Experience the Power of Precisya  Transforming Healthcare with Secure Digital NFT</h1>
                        <div className='flex gap-3 md:gap-10 justify-center py-10 text-xs md:text-sm'>
                            <button
                                className={`md:w-[15%] px-3 py-1 md:py-3 ${activeButton === 'div1' ? ' border-2 border-white bg-[#3AB6FF] text-black font-semibold duration-500 rounded-full ' : ' border-2 border-white rounded-full'}`}
                                onClick={() => handleAnchorClick('div1')}>
                                Immutable Security
                            </button>
                            <button
                                className={`md:w-[15%] px-3 py-1 md:py-3 ${activeButton === 'div2' ? ' border-2 border-white bg-[#3AB6FF] text-black font-semibold duration-500 rounded-full ' : ' border-2 border-white rounded-full'} `}
                                onClick={() => handleAnchorClick('div2')}>
                                Universal Access
                            </button>
                            <button
                                className={`md:w-[15%] px-3 py-1 md:py-3 ${activeButton === 'div3' ? ' border-2 border-white bg-[#3AB6FF] text-black font-semibold duration-500 rounded-full ' : ' border-2 border-white rounded-full'}`}
                                onClick={() => handleAnchorClick('div3')}>
                                Privacy Protection
                            </button>
                        </div>
                        <div id='div1' className='duration-500' style={{ display: activeButton === 'div1' ? 'block' : 'none' }}>
                            <div className='w-[90%] flex flex-col md:flex-row gap-5 md:gap-0 justify-between mx-auto'>
                                <div className='md:w-[40%]'>
                                    <div>
                                        <img src='../assets/1.png' className='rounded-full border-8'></img>
                                    </div>
                                </div>
                                <div className='md:w-[50%] grid content-center duration-500'>
                                    <p className='text-lg font-semibold'>We Believe You are <br />“PRECIOUS”</p>
                                    <p className='mt-5 md:mt-10'>Your Precision health information is meticulously encrypted using cutting-edge cryptographic techniques and securely stored on the blockchain. This ensures not only tamper-proof protection but also guarantees the utmost confidentiality of your sensitive health data. With Precisya’s Blockchain enabled profile, rest assured that your information remains safeguarded against unauthorized access and maintains its integrity throughout its lifecycle.</p>
                                </div>
                            </div>
                        </div>
                        <div id='div2' className='duration-500' style={{ display: activeButton === 'div2' ? 'block' : 'none' }}>
                            <div className='w-[90%] flex flex-col md:flex-row gap-5 md:gap-0 justify-between mx-auto'>
                                <div className='md:w-[40%]'>
                                    <div>
                                        <img src='../assets/2.png' className='rounded-full border-8'></img>
                                    </div>
                                </div>
                                <div className='md:w-[50%] grid content-center '>
                                    <p className='text-lg font-semibold'>We Believe You are <br />“PRECIOUS”</p>
                                    <p className='mt-5 md:mt-10'>Access your lab reports with unparalleled ease from any corner of the globe, at any hour of the day, via our intuitive and user-friendly web interface or mobile application. Whether you're traveling, at work, or simply relaxing at home, Precisya ensures seamless accessibility to your vital health information. Say goodbye to the constraints of traditional paper-based records and embrace the freedom of retrieving your lab reports whenever and wherever you need them, all at your fingertips.</p>
                                </div>
                            </div>
                        </div>
                        <div id='div3' style={{ display: activeButton === 'div3' ? 'block' : 'none' }}>
                            <div className='w-[90%] flex flex-col md:flex-row gap-5 md:gap-0 justify-between mx-auto'>
                                <div className='md:w-[40%]'>
                                    <div>
                                        <img src='../assets/3.png' className='rounded-full border-8'></img>
                                    </div>
                                </div>
                                <div className='md:w-[50%] grid content-center '>
                                    <p className='text-lg font-semibold'>We Believe You are <br />“PRECIOUS”</p>
                                    <p className='mt-5 md:mt-10'>Precisya goes the extra mile to safeguard your privacy by anonymizing your data and restricting access solely to authorized individuals. Our robust security protocols ensure that your sensitive health information remains confidential and protected from prying eyes. With Precisya's NFT, you can trust that your personal data is shielded against any unauthorized access or breaches, allowing you to confidently manage your healthcare journey with peace of mind.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='w-[90%] mx-auto py-10 '>
                    <Slider className='w-[90%] mx-auto md:mx-0 md:w-[100%]' {...settings1} >
                        <div>
                            <p className='md:text-2xl font-semibold md:leading-[3rem]'>“Precisya's unparalleled expertise lies in harnessing the world's most advanced genomic big data accumulated over the past 12 years. Through this wealth of data, they have established a unique genomic ecosystem. Their Genetic Reports serve as a cornerstone for Precision Health, providing unparalleled insights into individuals' DNA profiles.”</p>
                            <div className='mt-10 flex justify-between'>
                                <div className='flex'>
                                    <div>
                                        <img src='../assets/Ellipse 5.png'></img>
                                    </div>
                                    <div className='pl-3 grid content-center'>
                                        <p className='font-bold'>Michael Kaizer</p>
                                        <p className='text-[#878C91]'> of Basecamp Corp</p>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div>
                            <p className='md:text-2xl font-semibold md:leading-[3rem]'>“Precisya's unparalleled expertise lies in harnessing the world's most advanced genomic big data accumulated over the past 12 years. Through this wealth of data, they have established a unique genomic ecosystem. Their Genetic Reports serve as a cornerstone for Precision Health, providing unparalleled insights into individuals' DNA profiles.”</p>
                            <div className='mt-10 flex justify-between'>
                                <div className='flex'>
                                    <div>
                                        <img src='../assets/Ellipse 5.png'></img>
                                    </div>
                                    <div className='pl-3 grid content-center'>
                                        <p className='font-bold'>Michael Kaizer</p>
                                        <p className='text-[#878C91]'> of Basecamp Corp</p>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div>
                            <p className='md:text-2xl font-semibold md:leading-[3rem]'>“Precisya's unparalleled expertise lies in harnessing the world's most advanced genomic big data accumulated over the past 12 years. Through this wealth of data, they have established a unique genomic ecosystem. Their Genetic Reports serve as a cornerstone for Precision Health, providing unparalleled insights into individuals' DNA profiles.”</p>
                            <div className='mt-10 flex justify-between'>
                                <div className='flex'>
                                    <div>
                                        <img src='../assets/Ellipse 5.png'></img>
                                    </div>
                                    <div className='pl-3 grid content-center'>
                                        <p className='font-bold'>Michael Kaizer</p>
                                        <p className='text-[#878C91]'> of Basecamp Corp</p>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </Slider>
                </div>

                <div className='absolute -translate-y-[3rem] flex items-center justify-end w-[90%] mx-auto pb-10'>
                    <p className='flex'>
                        <span className='font-bold'>{`${currentSlide + 1 < 10 ? `0${currentSlide + 1}` : currentSlide + 1}`}</span>
                        <span className='text-[#878C91]'>/03</span>
                    </p>
                </div>
            </div>
            <div className='sec-4 bg-gray-100 py-3'>
                <div className='bg-white rounded-2xl p-10 w-[98%] mx-auto flex flex-col md:flex-row gap-10'>
                    <div className='md:w-[50%]'>
                        <h1 className='font-semibold text-2xl md:text-4xl'>Frequently Asked Questions</h1>
                        <p className='text-[#878C91] leading-[1.5rem] mt-10'><span className='font-bold text-black'>Why Us? </span> Over 2.2 Million DNA Tests Done and Counting, 89+ Million Polymorphisms and Mutations Analyzed, 25+ Million Scientific Publications.<br />
                            Own Neural Network Able to Read, Interpret, and Perform Tasks on Millions of Scientific Publications and Biobank Data! More than 36 Comprehensive Panels from Preventive to chronic diseases.<br />
                            Personalized Health plan based on your Genetic Results and Blood Exam Results.
                        </p>
                        <button className='mt-10 py-3 rounded-full border-2 border-black font-bold px-10 text-sm'>Contact Us</button>
                    </div>
                    <div className='md:w-[50%] flex flex-col'>
                        <div className='border-y-2 border-black p-5 duration-500'>
                            <div className='flex'>
                                <div className='w-[90%]'>
                                    <h1 className='font-bold text-lg'>How secure are Precision Health Information with precisya?</h1>
                                </div>
                                <div className='w-[10%] flex items-center justify-center'>
                                    <button className='font-bold text-xl' onClick={handleAsked1}>{asked1 ? '-' : '+'}</button>
                                </div>
                            </div>
                            <div className={`duration-500 ease-in-out ${asked1 ? 'pt-3 max-h-[100%] overflow-auto' : 'max-h-0 overflow-hidden'}`}>
                                <p className='text-sm text-[#878C91]'>
                                    Precisya employs state-of-the-art encryption technology to ensure the utmost security of your lab reports. Your data is stored securely on the blockchain, providing tamper-proof protection against unauthorized access.
                                </p>
                            </div>
                        </div>

                        <div className='p-5 duration-500'>
                            <div className='flex'>
                                <div className='w-[90%]'>
                                    <h1 className='font-bold text-lg'>Can I share my Precision Health Information with healthcare providers or family members?</h1>
                                </div>
                                <div className='w-[10%] flex items-center justify-center'>
                                    <button className='font-bold text-xl' onClick={handleAsked2}>{asked2 ? '-' : '+'}</button>
                                </div>
                            </div>
                            <div className={`duration-500 ease-in-out ${asked2 ? 'pt-3 max-h-[100%] overflow-auto' : 'max-h-0 overflow-hidden'}`}>
                                <p className='text-sm text-[#878C91]'>
                                    Precisya employs state-of-the-art encryption technology to ensure the utmost security of your lab reports. Your data is stored securely on the blockchain, providing tamper-proof protection against unauthorized access.
                                </p>
                            </div>
                        </div>

                        <div className='border-y-2 border-black p-5 duration-500'>
                            <div className='flex'>
                                <div className='w-[90%]'>
                                    <h1 className='font-bold text-lg'>Can I access my Precision Health Information from multiple devices?</h1>
                                </div>
                                <div className='w-[10%] flex items-center justify-center'>
                                    <button className='font-bold text-xl' onClick={handleAsked3}>{asked3 ? '-' : '+'}</button>
                                </div>
                            </div>
                            <div className={`duration-500 ease-in-out ${asked3 ? 'pt-3 max-h-[100%] overflow-auto' : 'max-h-0 overflow-hidden'}`}>
                                <p className='text-sm text-[#878C91]'>
                                    Precisya employs state-of-the-art encryption technology to ensure the utmost security of your lab reports. Your data is stored securely on the blockchain, providing tamper-proof protection against unauthorized access.
                                </p>
                            </div>
                        </div>

                        <div className='border-b-2 border-black p-5 duration-500'>
                            <div className='flex'>
                                <div className='w-[90%]'>
                                    <h1 className='font-bold text-lg'>Is my privacy protected with precisya?</h1>
                                </div>
                                <div className='w-[10%] flex items-center justify-center'>
                                    <button className='font-bold text-xl' onClick={handleAsked4}>{asked4 ? '-' : '+'}</button>
                                </div>
                            </div>
                            <div className={`duration-500 ease-in-out ${asked4 ? 'pt-3 max-h-[100%] overflow-auto' : 'max-h-0 overflow-hidden'}`}>
                                <p className='text-sm text-[#878C91]'>
                                    Precisya employs state-of-the-art encryption technology to ensure the utmost security of your lab reports. Your data is stored securely on the blockchain, providing tamper-proof protection against unauthorized access.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='sec-5 bg-gray-100 '>
                <div className='py-10 w-[90%] mx-auto'>
                    <div className='flex flex-col md:flex-row'>
                        <div className='md:w-[50%] grid content-center'>
                            <h1 className='font-semibold text-2xl md:text-4xl'>Testimonials from Precisya Users</h1>
                        </div>
                        <div className='md:w-[50%]'>
                            <p className='text-[#878C91] text-sm'>We have more than 60 clinical and non clinical Panel offerings covering the entire healthcare spectrum by identifying potential health risks early on,</p>
                            <button className='mt-10 py-3 rounded-full border-2 border-black font-bold px-5 text-sm'>Join Our Community</button>
                        </div>
                    </div>
                    <Slider className='my-5 w-[90%] mx-auto md:mx-0 md:w-[100%]' {...settings}>
                        <div className='px-5'>
                            <div className=' bg-white border rounded-2xl p-5'>
                                <div className='flex justify-between'>
                                    <div><FontAwesomeIcon className='text-xs text-purple-600' icon={faCircle}></FontAwesomeIcon></div>
                                    <div><p className='text-[#878C91] font-bold'>Sarah D</p></div>
                                </div>
                                <p className='font-semibold text-lg py-3'>Precisya has made managing my health records effortless. I no longer worry about losing paper documents or privacy breaches.</p>
                            </div>

                        </div>
                        <div className='px-5'>
                            <div className=' bg-white border rounded-2xl p-5'>
                                <div className='flex justify-between'>
                                    <div><FontAwesomeIcon className='text-xs text-red-600' icon={faCircle}></FontAwesomeIcon></div>
                                    <div><p className='text-[#878C91] font-bold'>Dr. John Smith</p></div>
                                </div>
                                <p className='font-semibold text-lg py-3'>Precisya has streamlined our processes and improved patient care by ensuring accurate and secure access to lab reports.</p>
                            </div>

                        </div>
                        <div className='px-5'>
                            <div className=' bg-white border rounded-2xl p-5'>
                                <div className='flex justify-between'>
                                    <div><FontAwesomeIcon className='text-xs text-yellow-500' icon={faCircle}></FontAwesomeIcon></div>
                                    <div><p className='text-[#878C91] font-bold'>Emily R.</p></div>
                                </div>
                                <p className='font-semibold text-lg py-3'>Precisya has given me control over my healthcare journey. Knowing my data is securely stored and accessible anytime is invaluable.</p>
                            </div>

                        </div>
                    </Slider>

                </div>
            </div>
            <div className='sec-6 bg-gray-100 pb-10'>
                <div className='w-[90%] mx-auto py-5 md:py-10 px-5 md:px-20 rounded-3xl' style={{ backgroundImage: 'url("../assets/bg.png")' }}>
                    <p className='text-2xl md:text-5xl text-white md:leading-[4rem]'>Ready to Transform Your Healthcare Experience?</p>
                    <div className='mt-3 md:mt-0 flex justify-center md:justify-end'>
                        <button className='md:absolute md:-translate-y-[3rem] bg-white py-3 px-10 text-sm font-bold rounded-full'>Get Started<FontAwesomeIcon icon={faArrowRight} className='pl-2 text-base'></FontAwesomeIcon></button>
                    </div>
                </div>
            </div>
            <LandingFooter />
        </div >
    )
}