

import { faCheckCircle, faCircle, faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import toast, { Toaster } from "react-hot-toast";
import { BASE_URL } from "../config";
import DataSpinners from "../Spinner";
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';



export default function DoctorProfile() {
  const [currentPage, setCurrentPage] = useState(1);
  const [entitiesPerPage, setEntitiesPerPage] = useState(5);
  const [userProfiles, setUserProfiles] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [formPopupData, setFormPopupData] = useState(false);
  const [spinner, setSpinner] = useState(false);


  useEffect(() => {
    const fetchUserProfiles = async () => {
      try {
        setSpinner(true)
        const response = await axios.get(`${BASE_URL}/api/get_user_profiles_data_fordoctors`);
        if (response.data.status === "success") {
          setUserProfiles(response.data.userProfiles);
        } else {
          console.error("Failed to fetch user profiles:", response.data.error);
        }
        setSpinner(false)
      } catch (error) {
        setSpinner(false)
        console.error("Error fetching user profiles:", error.response.data.error);
      }
    };

    fetchUserProfiles();
  }, []);

 

  const filteredProfiles = userProfiles.filter(profile =>
    profile.email.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const indexOfLastEntity = currentPage * entitiesPerPage;
  const indexOfFirstEntity = indexOfLastEntity - entitiesPerPage;
  const currentEntities = filteredProfiles.slice(indexOfFirstEntity, indexOfLastEntity);
  const emptyRowCount = entitiesPerPage - currentEntities.length;

  const handlePrevClick = () => {
    setCurrentPage(currentPage - 1);
  };

 
  const handleNextClick = () => {
    const remainingEntities = filteredProfiles.length - indexOfLastEntity;
    if (remainingEntities > 0) {
      setCurrentPage(currentPage + 1);
    }
  };


  const handleDropdownChange = (e) => {
    setEntitiesPerPage(parseInt(e.target.value));
    setCurrentPage(1);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1); // Reset to the first page whenever the search term changes
  };

  const [openDetailsForProfile, setOpenDetailsForProfile] = useState(null);
  const profileToDisplay = userProfiles.find(profile => profile.kitNumber === openDetailsForProfile);

  const handleFormClick = async (formType, kitNumber) => {
    try {
      const profile = userProfiles.find(profile => profile.kitNumber === kitNumber);
      if (!profile) {
        console.error("Profile not found for kit number:", kitNumber);
        return;
      }

      const { email } = profile;
      const response = await axios.post(`${BASE_URL}/api/check-access`, {
        kitNumber,
        from_email: "ADMIN",
        to_email: email,
        formType,
      });

      const { view_status } = response.data;
      if (view_status === "1") {
        window.open(profile[formType], "_blank");
      } else {
        setFormPopupData(true);
        setFormPopupData({ formUrl: profile[formType], formType });
      }
    } catch (error) {
      console.error("Error:", error.message);
    }
  };

  const handleSendRequestAccess1 = async (kitNumber, formType) => {
    try {
      const profile = userProfiles.find(profile => profile.kitNumber === kitNumber);
      if (profile) {
        const { email } = profile;
        const response = await axios.post(`${BASE_URL}/api/request-access`, {
          kitNumber,
          from_email: "ADMIN",
          to_email: email,
          formType,
        });

        if (response.data.status === "success") {
          setFormPopupData(false);
        } else {
          console.error("Failed to send access request:", response.data.error);
          toast.error(response.data.error);
          setFormPopupData(false);
        }
      } else {
        console.error("Profile not found for kit number:", kitNumber);
      }
    } catch (error) {
      console.error("Error sending request:", error.message);
    }
  };
  const [isDarkMode, setIsDarkMode] = useState(true);
  const [userId, setUserId] = useState("");
  const [isOpen, setIsOpen] = useState(1);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const user_id = searchParams.get('id');
    setUserId(user_id);
  }, []);



  const Nft = [
    { name: "Order Form", img: "../assets/Order 2.jpg" },
    { name: "Consent Form", img: "../assets/Consent 2.jpeg" },
    { name: "DNA Raw Data", img: "../assets/DNA 4.jpg" },
    { name: "Genetic Report", img: "../assets/Generic 1.jpeg" },
    { name: "Blood Report", img: "../assets/Blood report 1.jpg" },
  ];

  const openModal = (id) => {
    setUserId(id);
    setIsOpen(2);
  };


  const closeModal = () => {
    setIsOpen(1);
  };

  const [documentType, setDocumentType] = useState('');
  const [kitNumber, setKitNumber] = useState('');
  const [imgUrl, setImgUrl] = useState('');
  const [reportNum, setReportNum] = useState('');
  const [viewNft, setViewNft] = useState([]);
  const [formPopup, setFormPopup] = useState(false);
  const from_email = useSelector((state) => state.doctor_email);


  const [currentViewPage, setCurrentViewPage] = useState(1);
  const itemsPerPage = 6;
  const indexOfLastItem = currentViewPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = viewNft.slice(indexOfFirstItem, indexOfLastItem);


  const nextPage = () => {
    if (currentViewPage < Math.ceil(viewNft.length / itemsPerPage)) {
      setCurrentViewPage(currentViewPage + 1);
    }
  };

  const prevPage = () => {
    if (currentViewPage > 1) {
      setCurrentViewPage(currentViewPage - 1);
    }
  };

 

  const handleViewReport = async (id, name, img) => {
    setUserId(id);
    setImgUrl(img);
    setIsOpen(3);
    
    const formData = {
      userId: id,
      documentType: name
    };

    try {
      const response = await axios.post(`${BASE_URL}/api/get_reports`, formData);
      console.log("response handleViewReport",response)
      setViewNft(response.data.data);
    } catch (error) {
      console.error("Error fetching reports:", error);
    }
  };

  console.log("reports", Nft)
  const [created_report_at, setCreatedReportAt] = useState(null);

  const handleView = async (id, document_url, document_type, kitnumber, reportNumber, created_at, to_email) => {
    setReportNum(reportNumber);
    setCreatedReportAt(created_at);
    
    try {
      const response = await axios.post(`${BASE_URL}/api/viewDoctorAccess`, { 
        kitnumber, 
        from_email, 
        reportNumber, 
        created_at, 
        to_email 
      });
      
      if (response.data.status === '1') {
        window.open(document_url.replace("../client/public/", "/"), '_blank');
      } else {
        setFormPopup(true);
        setDocumentType(document_type);
        setKitNumber(kitnumber);
      }
    } catch (error) {
      console.error('Error viewing document:', error);
    }
  };

  console.log("ddddddddddd", document)
  console.log("imageUrl", imgUrl)
  console.log("0000000000000000nummmm", reportNum)
  const [accessRequestId, setAccessRequestId] = useState(null);

  const handleSendRequestAccess = async () => {
    try {
      const getProfile = await axios.post(`${BASE_URL}/api/find_by_id`, { id: userId });
      if (getProfile.data.status === 'success') {
        const email = getProfile.data.data[0].email;
        const response = await axios.post(`${BASE_URL}/api/doctor_request_access`, { kitNumber, from_email: from_email, to_email: email, formType: documentType, reportNumber: reportNum, created_report_at: created_report_at });
        if (response.data.status === 'success') {
          setFormPopup(false);
          setAccessRequestId(response.data.id);

          toast.success('Access request sent successfully');
        } else {
          console.error('Failed to send access request:', response.data.error);
          toast.error(response.data.error);
          setFormPopup(false);
        }
      }
    } catch (error) {
      console.error('Error sending request:', error.message);
    }
  };


  const truncateString = (str, num) => {
    if (str.length <= num) {
      return str;
    }
    return str.slice(0, num) + '...';
  };

  const handleNavigateBack = () => {
    setIsOpen(2);
  };

  

  return (
    <div className=' md:p-10 mt-5'>
     
      <div>
        {isOpen === 1 ? (
          <div className='w-[100%] bg-white/20 rounded-2xl py-10 px-5 md:p-10'>
            <div className='flex flex-col md:flex-row gap-5 md:gap-0 justify-between'>
              <div className='md:w-[35%] flex border rounded-md'>
                <FontAwesomeIcon icon={faMagnifyingGlass} className='w-[5%] pl-2 mt-2' />
                <input
                  className='bg-transparent py-1 w-[95%] outline-none pl-2 placeholder:text-sm'
                  placeholder='Search Profile'
                  value={searchTerm}
                  onChange={handleSearchChange}
                />                </div>
              <div className='flex items-center'>
                <p className=' text-xs px-3 mb-2'>Show no of entity
                  <select className='ml-2 outline-none rounded-md bg-transparent border-[1px]' onChange={handleDropdownChange} value={entitiesPerPage}>
                    <option className='text-black' value="5">5</option>
                    <option className='text-black' value="10">10</option>
                    <option className='text-black' value="15">15</option>
                  </select>
                </p>
              </div>
            </div>
            <div className=' pb-5 rounded-xl overflow-hidden overflow-x-auto ' >
              <table className='w-full text-center'>
                <thead className=''>
                  <tr className=' text-sm md:text-md'>
                    <th className='py-5 px-5 md:px-0'>S.No</th>
                    <th className='py-5 px-5 md:px-0'>Email</th>
                    <th className='py-5 px-5 md:px-0'>Wallet Address</th>
                    <th className='py-5 px-5 md:px-0'>View Profile</th>
                  </tr>
                </thead>
                <tbody >
                  {currentEntities.map((entity, index) => (
                    <tr key={index} className='h-16 bg-white/20 border-y-4 border-[#37547b] text-xs md:text-sm rounded-md '>
                      <td className='rounded-l-md px-5 md:px-0'>{indexOfFirstEntity + index + 1}</td>
                      <td className='px-5 md:px-0'>{entity.email}</td>
                      <td className='px-5 md:px-0 '>{entity.wallet_address}</td>
                      <td className="text-sm px-5 text-center 2xl:py-5 py-3 font-semibold whitespace-nowrap">
                        <a className='cursor-pointer' onClick={() => openModal(entity.id)}>
                          View Profile
                        </a>
                        {/* <button onClick={handleOpenDetails(data.kitNumber)} className=' cursor-pointer ' >View Profile</button> */}
                      </td>                                </tr>
                  ))}
                  {/* Add empty rows if needed */}

                </tbody>
              </table>
            </div>
            <div className='mt-2 flex justify-between text-xs px-3'>
              <button className='bg-white/30 rounded-md px-5 py-1' onClick={handlePrevClick} disabled={currentPage === 1}>Prev</button>
              <button className='bg-white/30 rounded-md px-5 py-1' onClick={handleNextClick} disabled={currentEntities.length < entitiesPerPage}>Next</button>
            </div>
          </div>) : null}
        {spinner ? <DataSpinners /> : ""}
        {isOpen === 2 ? (
          <div className={`w-[100%] bg-white/20 rounded-2xl py-10 px-5 md:p-7`}>
            <FontAwesomeIcon icon={faArrowLeft} className='text-white cursor-pointer' onClick={closeModal} />
            <div className={`flex justify-end  font-inter`}>
              <div className='lg:w-[95%] lg:mx-auto mx-10 z-10 relative'>
                <div className='h-screen md:h-full overflow-hidden overflow-y-auto md:overflow-hidden'>
                  <div className={`${isDarkMode ? 'bg-white/25' : 'bg-[#3ab7ff28]'} rounded-lg p-5 cursor-pointer`}>
                    <div className='w-full'>
                      <div className='flex flex-wrap justify-center items-center gap-5'>
                        {Nft.map((data, index) => (
                          <div key={index} className='bg-white h-[13rem] p-2 rounded-lg flex flex-col justify-center items-center md:w-[24%] sm:w-[30%] w-[12rem]' 
                          //onClick={() => window.location.href = `/Doctor/ViewReport?id=${userId}&document_type=${data.name}&imgUrl=${data.img}`}
                          onClick={() => handleViewReport(userId,data.name,data.img)}>
                            <img src={data.img} alt='Nft' className='sm:w-9/12 w-8/12 h-[10rem]' />
                            <p className='text-sm text-[#000000A6]'>{data.name}</p>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : null}
        {isOpen === 3 ? (
          <div>
            <div className={`w-[100%] py-10 px-5 md:p-7`}>
              <div className={`flex min-h-screen font-inter pt-5 `}>
                <div className='lg:w-[100%]  z-10 relative'>
                  <div className='h-screen md:h-full overflow-hidden overflow-y-auto md:overflow-hidden '>
                    <button className={`${isDarkMode ? 'text-white' : 'text-black'}`} onClick={handleNavigateBack}>
                      <FontAwesomeIcon icon={faArrowLeft} />
                    </button>
                    <div className={` ${isDarkMode ? 'bg-white/25' : 'bg-[#3ab7ff28]'} w-[90%] mx-auto mt-5 rounded-lg py-5 cursor-pointer`}>
                      <div className='w-full'>
                        <div className='flex flex-wrap justify-center items-center gap-5'>
                          {currentItems.map((data, index) => (
                            <div key={index} className="bg-white p-3 rounded-lg flex flex-col justify-center items-center md:w-[25%]"
                              onClick={() => handleView(data.id, data.document_url, data.document_type, data.kitnumber, data.reportNumber, data.created_at, data.email)}>
                              <div className="flex relative justify-center items-center pr-5">
                                <div className="">
                                  <p className="text-sm w-5 text-[#000000A6] font-medium rotate-90"></p>
                                </div>
                                <img src={imgUrl} alt='Nft' className='h-[10rem]' />
                              </div>
                              <div className="w-full overflow-hidden overflow-x-auto mt-2">
                                <p className="text-sm text-[#000000A6] text-center">
                                  {truncateString(data.reportNumber, 9)}{data.created_at.split('T')[0]}
                                </p>
                              </div>
                            </div>
                          ))}
                        </div>
                        <div className="mt-4 flex justify-between w-full px-4">
                          <button
                            onClick={prevPage}
                            disabled={currentPage === 1}
                            className="bg-gray-300 text-black py-0.5 px-4 rounded disabled:opacity-50"
                          >
                            <FontAwesomeIcon icon={faArrowLeft} size='sm' />
                          </button>
                          <button
                            onClick={nextPage}
                            disabled={currentPage === Math.ceil(viewNft.length / itemsPerPage)}
                            className="bg-gray-300 text-black py-0.5 px-4 rounded disabled:opacity-50"
                          >
                            <FontAwesomeIcon icon={faArrowRight} size='sm' />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {formPopup && (
              <div className="fixed inset-0 flex items-center justify-center z-50">
                <div className="fixed inset-0 bg-gray-900 bg-opacity-50"></div>
                <div className="relative bg-white rounded-lg p-8 shadow-lg">
                  <h2 className="text-2xl font-bold mb-4">Request Access</h2>
                  <p className="text-gray-700 mb-6">You need permission to access this form.</p>
                  <div className="flex justify-end">
                    <button className="bg-blue-500 text-white px-4 py-2 rounded mr-2" onClick={handleSendRequestAccess}>Send Request Access</button>
                    <button onClick={() => setFormPopup(false)} className="bg-gray-300 text-gray-700 px-4 py-2 rounded">Cancel</button>
                  </div>
                </div>
              </div>
            )}
          </div>
        ) : null}
      </div>
    </div>
  )
}