import { Routes, Route, Navigate } from "react-router-dom";
import Dashboard  from './Admin panel/Dashboard';
import Landing from './Landing';
import Profile from './Admin panel/Profile';
 import DeleteProfile from "./Admin panel/DeleteProfile";
 import Doctor_Profile from "./Admin panel/DoctorProfile";
// import Createprofile from "./Admin panel/Createprofile";
import Notifications from "./Admin panel/Notifications";
import SubAdmin from "./Admin panel/SubAdmin";
import SubadminProfile from "./Admin panel/SubAdminProfiles";
import Myprofile from './Myprofile';
import UserDashboard from './UserDashboard';
import UserHeader from './UserHeader';
import ViewProfile from "./ViewProfile";
import AdminViewProfile from "./Admin panel/ViewProfile"
import Login from "./Admin panel/Login";
import Wallet from "./Admin panel/Wallet";
import UserLogin from "./Login";
import Uploaddocument from "./Admin panel/Uploaddocument";
import CreateProfile from "./Admin panel/CreateProfile2";
import ViewReports from "./Admin panel/ViewReport";
import ViewuserReports from "./ViewReports";
import DoctorLogin from "./Doctor_panel/doctorLogin";
import DoctorProfile from "./Doctor_panel/Profile";
import Notification from "./Doctor_panel/Notification";
import DoctorDashboard from "./Doctor_panel/DoctorDashBoard";
import ViewProfiles from "./Doctor_panel/ViewProfiles";
import ViewReport from "./Doctor_panel/ViewReport";
import { useSelector } from "react-redux";
import PasswordReset from "./PasswordReset";
import Changepassword from "./Admin panel/ChangePassword";
import UserBloodReport from "./UserBloodReport";
import SubadminLogin from "./Admin panel/SubadminLogin";
function App() {
  const doctor_email = useSelector((state) => state.doctor_email);
  const user_email = useSelector((state) => state.user_email);
  const admin_email = useSelector((state) => state.admin_email);
  return (
    <div className="App">

      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/password-reset" element={<PasswordReset />} />
        {/* <Route path="/Myprofile" element={user_email ? <Myprofile /> : <Navigate to="/userLogin" />} /> */}

        <Route path="/UserDashboard" element={user_email ? <UserDashboard /> : <Navigate to="/userLogin" />} />
        <Route path="/UserHeader" element={user_email ? <UserHeader /> : <Navigate to="/userLogin" />} />
        <Route path="/UserBloodReport" element={user_email ? <UserBloodReport /> : <Navigate to="/UserBloodReport" />} />
        
        <Route path="/ViewProfile"  element={user_email ? <ViewProfile /> : <Navigate to="/userLogin" />} />

        <Route path="/userLogin" element={ <UserLogin />} />

        <Route path="/ViewUserReports" element={user_email ? <ViewuserReports /> : <Navigate to="/userLogin" />} />


        {/* Admin Panel */}
        <Route path="/Admin/Dashboard" element={admin_email ? <Dashboard /> : <Navigate to="/Admin/Login" />} />
        <Route path="/Admin/Profile" element={  admin_email ? <Profile /> : <Navigate to="/Admin/Login"/> } />
        <Route path="/Admin/DoctorProfile" element={  admin_email ? <Doctor_Profile /> : <Navigate to="/Admin/Login"/> } />

        <Route path="/Admin/Notifications" element={  admin_email ? <Notifications /> : <Navigate to="/Admin/Login"/> } />
      
        <Route path="/Admin/DeleteProfile" element={  admin_email ? <DeleteProfile /> : <Navigate to="/Admin/Login"/> } />
        <Route path="/Admin/ChangePassword" element={  admin_email ? <Changepassword /> : <Navigate to="/Admin/Login"/> } />

        {/* <Route path="/Admin/Createprofile" element={<Createprofile />} /> */}
        <Route path="/Admin/Login" element={<Login />} />
        <Route path="/Admin/SubadminLogin" element={<SubadminLogin />} />

        <Route path="/Admin/Wallet" element={  admin_email ? <Wallet /> : <Navigate to="/Admin/Login"/> } />
       
        <Route path="/Admin/SubAdmin" element={  admin_email ? <SubAdmin /> : <Navigate to="/Admin/Login"/> } />
  
        <Route path="/Admin/SubadminProfile" element={  admin_email ? <SubadminProfile /> : <Navigate to="/Admin/Login"/> } />
     
        <Route path="/Admin/Uploaddocument" element={  admin_email ? <Uploaddocument /> : <Navigate to="/Admin/Login"/> } />
      
        <Route path="/Admin/CreateProfile" element={  admin_email ? <CreateProfile /> : <Navigate to="/Admin/Login"/> } />
      
        <Route path="/Admin/ViewProfile" element={  admin_email ? <AdminViewProfile /> : <Navigate to="/Admin/Login"/> } />
        
        <Route path="/Admin/ViewReports" element={  admin_email ? <ViewReports /> : <Navigate to="/Admin/Login"/> } />
   

        {/* Doctor Panel */}

        <Route path="/Doctor/DoctorLogin" element={<DoctorLogin />} />
        <Route path="/Doctor/DoctorProfile" element={doctor_email ? <DoctorProfile /> : <Navigate to="/Doctor/DoctorLogin" />} />
   
        <Route path="/Doctor/Notification" element={doctor_email ? <Notification /> : <Navigate to="/Doctor/DoctorLogin" />} />
    
        <Route path="/Doctor/DoctorDashboard" element={doctor_email ? <DoctorDashboard /> : <Navigate to="/Doctor/DoctorLogin" />} />
        <Route path="/Doctor/ViewProfiles" element={doctor_email ? <ViewProfiles /> : <Navigate to="/Doctor/DoctorLogin" />} />
  
        <Route path="/Doctor/ViewReport" element={doctor_email ? <ViewReport /> : <Navigate to="/Doctor/DoctorLogin" />} />

  


      </Routes>
     
    </div>
  );
}

export default App;