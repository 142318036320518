import React from 'react';
// import { Spinner } from "@material-tailwind/react";


const DataSpinners = () => {
  return (
    <div className="fixed inset-0 z-30 flex items-center justify-center w-screen h-screen bg-black bg-opacity-10 backdrop-blur-md">
      <div className='flex items-center gap-5'>
   <div class="relative flex justify-center items-center">
    <div class="absolute animate-spin rounded-full h-32 w-32 border-t-4 border-b-4 border-[#05295A]"></div>
    <img src='../assets/Logo.png'  class=" h-10 w-20"/>
</div><br/>
   <p className='text-white text-2xl font-bold'>Loading....</p>
   </div>
</div>
  );
};

export default DataSpinners; 