import React, { useState, useEffect } from 'react';
import DoctorHeader from './DoctorHeader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFolderOpen, faShareNodes, faWallet, faClockRotateLeft, faFire, faBell, faArrowRightFromBracket } from '@fortawesome/free-solid-svg-icons';
import DoctorProfile from './Profile';
import Notification from './Notification';
import Changepassword from './Changepassword';
import { useSelector, useDispatch } from 'react-redux';
import { setDoctorEmail } from "../store";
import toast, { Toaster } from "react-hot-toast";


export default function DoctorDashboard() {

    const [selectedTab, setSelectedTab] = useState('DoctorProfile');
    const [activeTab, setActiveTab] = useState('DoctorProfile');
    const [active, setActive] = useState('div1');
    const [isOpenNavbar, setOpenNavbar] = useState(false);
    const [unreadNotifications, setUnreadNotifications] = useState(5);

    const count = useSelector((state) => state.notificationCount);
    const dispatch = useDispatch();
    const Logout =()=> {
        dispatch(setDoctorEmail(''));
        toast.success('Logged out Successfully...Redirecting to Login Page')
        setTimeout(()=>{
            window.location.href="/Doctor/DoctorLogin"
        },500)
        
      }

    console.log("cccccount", count)
    const handleOpenNavbar = () => {
        setOpenNavbar(!isOpenNavbar);
    };


    const handleTabClick = (tab) => {
        setSelectedTab(tab);
        setActiveTab(tab === activeTab ? null : tab);
        if (tab === 'Change Password') {
            handleAnchorClick('div3');
        } else {
            switch (tab) {
                case 'Notification':
                    handleAnchorClick('div2');
                    break;
                case 'Change Password':
                    handleAnchorClick('div3');
                    break;
                default:
                    handleAnchorClick('div1');
            }
        }
    };

    
    const handleAnchorClick = (tab) => {
        setActive(tab);
    };

   

    const iconMap = {
        'file': faFolderOpen,
        'share': faShareNodes,
        'wallet': faWallet,
        'clock': faClockRotateLeft,
        'fire': faFire,
        'bell': faBell
    };

    const reloadPage = () => {
        window.location.reload();
    };
   

    const TabButton = ({ label, tab, icon, onClick, badge }) => {
        return (
            <button
                className={`flex justify-center gap-3 w-[80%] mx-auto rounded-xl py-2.5 ${tab === activeTab ? 'bg-gradient-to-r from-[#3AB6FF] via-[#004696] to-[#FF3131]' : ''}`}
                onClick={onClick}
            >
                <div className='relative w-[10%]'>
                    <FontAwesomeIcon icon={icon} className='text-lg' />
                    {badge > 0 && (
                        <span className='absolute -top-1 -right-1 inline-block w-4 h-4 text-[10px] font-bold text-white bg-red-600 rounded-full text-center'>
                            {badge}
                        </span>
                    )}
                </div>
                <span className="w-[75%] md:w-[60%] text-start">{label}</span>
            </button>
        );
    };
  


    return (
        <div className='font-poppins bg-cover bg-no-repeat text-white' style={{ backgroundImage: 'url("/assets/bg3.png")' }}>
            <DoctorHeader selectedTab={selectedTab} />
            <Toaster />
            <div className='p-5 md:flex 2xl:h-screen overflow-hidden overflow-y-scroll'>
                <div class="hidden md:block w-[25%]">
                    <nav className='mt-[30%] '>
                        <div className='text-center py-3 rounded-2xl bg-gradient-to-r from-[#3AB6FF] via-[#004696] to-[#FF3131] mb-5 mx-auto w-[80%] uppercase font-bold cursor-pointer' onClick={reloadPage}>Doctor Dashboard</div>
                        <div>
                            <TabButton label='Profiles' tab='DoctorProfile' icon={iconMap['file']} onClick={() => { handleTabClick('DoctorProfile'); reloadPage(); }}  />
                            <TabButton label='Notification' tab='Notification' icon={iconMap['bell']} onClick={() => handleTabClick('Notification')} badge={count} />
                            <TabButton label='Change Password' tab='Change Password' icon={iconMap['clock']} onClick={() => handleTabClick('Change Password')} />
                        </div>
                        <div onClick={() => Logout()} className='flex justify-center mt-32'><button><FontAwesomeIcon icon={faArrowRightFromBracket} style={{ transform: 'scaleX(-1)'}}  className='pl-2 text-black'/>LogOut</button></div>
                    </nav>
                </div>
                <div className='block md:hidden'>
                    <button onClick={handleOpenNavbar}>
                        <svg class="w-6 h-6 text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 1h14M1 6h14M1 11h7" />
                        </svg>
                    </button>
                    {isOpenNavbar && (
                        <nav className='w-[80%] z-10 absolute border rounded-3xl bg-[#05295A] text-white grid content-around ' data-aos="fade-right">
                            <div>
                                <div className='py-5'>
                                    <TabButton label='DoctorProfile' tab='DoctorProfile' icon={iconMap['file']} onClick={() => handleTabClick('DoctorProfile')} />
                                    <TabButton label='Notification' tab='Notification' icon={iconMap['bell']} onClick={() => handleTabClick('Notification')} />
                                    <TabButton label='Change Password' tab='Change Password' icon={iconMap['clock']} onClick={() => handleTabClick('Change Password')} />
                                </div>
                                <div            
                                className='flex justify-center pb-5 w-[85%]'><button>                                    
                                <FontAwesomeIcon icon={faArrowRightFromBracket} style={{ transform: 'scaleX(-1)'}}  className='pl-2 text-black'/>Logout</button></div>

                            </div>
                                                 
                        </nav>
                    )}
                </div>

                <div id='div1' style={{ display: active === 'div1' ? 'block' : 'none' }} className='md:w-[75%]'>
                    <DoctorProfile />
                </div>
                <div id='div2' style={{ display: active === 'div2' ? 'block' : 'none' }} className='md:w-[75%]' >
                    <Notification />
                </div>
                <div id='div3' style={{ display: active === 'div3' ? 'block' : 'none' }} className='md:w-[75%]'>
                    <Changepassword />
                </div>
            </div>
        </div>
    )
}