
import React, { useEffect, useState } from "react";
// import Header from "./Header";
// import Sidebar from "./Sidebar";
import Modal from "./Modal";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faCopy,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";
import { BASE_URL } from "./config";
import DataSpinners from "./Spinner";
// import toast, { Toaster } from 'react-hot-toast';
import { toast, ToastContainer } from 'react-toastify';


export default function DeleteProfile() {


  const [showModal3, setShowModal3] = useState(false);
  const [selectedToken3, setSelectedToken3] = useState(null);
  const [isDelete, setIsDelete] = useState(false);
  const [clickedToken, setClickedToken] = useState(null);
  const [kitNumber, setKitNumber] = useState(null);
  const [walletAddress, setWalletAddress] = useState(null); // New state variable for wallet_address
  const [wallet_Address, setWallet_Address] = useState("");
  const [reportData, setReportData] = useState([]);
  const [reportDoc, setReportDoc] = useState([]);

  const [loading, setLoading] = useState(false);
  const [userProfile, setUserProfile] = useState(null);
  const [to_email, setToemail] = useState("");
  const [showMessage, setShowMessage] = useState(false);
  const [messageContent, setMessageContent] = useState(""); // new state to hold the message

  const [isDarkMode, setIsDarkMode] = useState(true);


  const [reportNumber, setReportNumber] = useState("");


  const [filteredKits, setFilteredKits] = useState([]);






  // const handleDelete = () => {
  //   setDelete(!isDelete);
  // };

  const toggleDarkMode = () => {
    setIsDarkMode(!isDarkMode);
  };

  console.log("report Data", reportData);

  console.log("report Data", reportData);

  const handleTokenSelect3 = (token) => {
    setSelectedToken3(token);
    setShowModal3(false);
    setClickedToken(token?.name);
    console.log("Selected token:", token?.name);
  };
  console.log("clickedToken", clickedToken);

  const [isCopied, setIsCopied] = useState(false);
  const [selectedOption, setSelectedOption] = useState("user"); // Default to 'user'

  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
  };

  console.log("00000000000000", selectedOption);
  const copyToClipboard = () => {
    const textToCopy = document.getElementById("address").innerText;
    navigator.clipboard.writeText(textToCopy);
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 2000);
  };

  const email = useSelector((state) => state.user_email);
  console.log("to email", to_email);
  console.log("report", reportData);
  const [isChecked, setIsChecked] = useState(false);
  const [reportNum, setReportNum] = useState("");
  const [kit, setKit] = useState("");
  const [created_report_at, setCreatedReportAt] = useState("");

  const getUserProfileByEmail = async () => {
    try {
      const response = await axios.post(
        `${BASE_URL}/api/get-userProfile-email`,
        { email: email }
      );
      console.log("response", response);

      setUserProfile(response.data.userProfile);
      //setKitNumber(response.data.userProfile.kitNumber)
      setWalletAddress(response.data.userProfile.wallet_address);
    } catch (error) {
      console.log(error.message);
    }
  };
  console.log("user profile", userProfile);

  useEffect(() => {
    if (email) {
      getUserProfileByEmail();
    }
  }, [email]);

  useEffect(() => {
    const getReport = async () => {
      if (selectedToken3) {
        const report = selectedToken3.alt;
        const userId = userProfile.id;
        if (userId) {
          const response = await axios.get(
            `${BASE_URL}/api/get_report_by_category?id=${userId}&report=${report}`
          );
          const updatedReportData = response.data.data.map((report) => ({
            ...report,
            document_url: report.document_url.replace(
              "../client/public",
              "../client/public"
            ),
          }));
          setReportData(updatedReportData);

          if (updatedReportData && updatedReportData.length > 0) {
            const matchedReport = updatedReportData.find(
              (report) => report.reportNumber === kitNumber
            );
            if (matchedReport) {
              console.log("Matched Report:", matchedReport.reportNumber);
              setReportDoc(matchedReport.ipfsHash);
              setReportNum(matchedReport.reportNumber);
              setKit(matchedReport.kitnumber);
              setCreatedReportAt(matchedReport.created_at);
              // Store as array containing the matched report
            } else {
              setReportDoc([]); // Clear reportDoc if no match is found
            }
          } else {
            setReportDoc([]); // Clear reportDoc if no data is found
          }
        }
      }
    };
    getReport();
  }, [selectedToken3, kitNumber, userProfile]);
  console.log("reportDoc", reportDoc);
  const handleChange = (e) => {
    setKitNumber(e.target.value);
  };

  console.log(
    "kitNumber",
    kitNumber,
    "email",
    email,
    "clickedToken",
    clickedToken,
    reportDoc,
    reportNum,
    created_report_at
  );
  console.log("wallet_address", wallet_Address);






  useEffect(() => {
    const fetchReports = async () => {
      try {
        const response = await axios.post(`${BASE_URL}/api/getReportstodel`, { email });
        if (response.data.length === 0) {
          toast.error("Report not found");
        } else {
          setReportData(response.data);
        }
      } catch (error) {
        console.error("Error fetching reports:", error);
      }
    };
    fetchReports();
  }, [email]);

  // Handle report number change
  const handleReportChange = (e) => {
    const selectedReportNumber = e.target.value;
    setReportNumber(selectedReportNumber);

    // Filter the kit numbers based on the selected report number
    const filtered = reportData.filter((report) => report.reportNumber === selectedReportNumber);

    setFilteredKits(filtered);
    setKitNumber(""); // Reset kit number selection when the report changes
  };


  const handleKitChange = (e) => {
    setKitNumber(e.target.value);
  };

  const handleDelete = () => {
    setIsDelete(true); // Open modal
  };

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const handleConfirm = async () => {
    if (!isChecked) {
      toast.error("Please confirm that you want to delete your health reports.");
      return;
    }

    if (!reportNumber || !kitNumber) {
      toast.error("Please select both a report number and a kit number.");
      return;
    }

    setLoading(true);
    try {
      const response = await axios.delete(`${BASE_URL}/api/delete_user_documents`, {
        data: {
          kitnumber: kitNumber,
          reportNumber: reportNumber,
        },
      });

      if (response.data.status === "success") {
        setMessageContent("Document deleted successfully");  // success message
        setShowMessage(true);

        toast.success("Document deleted successfully");
        setTimeout(() => {
          setShowMessage(false);
          window.location.href = "/UserDashboard";
        }, 3000);

        setReportData([]);
        setReportNumber("");
        setKitNumber("");
      } else {
        setMessageContent("No reports found");  // error message
        setShowMessage(true);

        toast.error("No reports found");
        setTimeout(() => {
          setShowMessage(false);
          window.location.href = "/UserDashboard";
        }, 3000);

        setReportData([]);
        setReportNumber("");
        setKitNumber("");
      }
    } catch (error) {
      console.error("Error deleting document:", error);
      setMessageContent("Failed to delete document, No reports found");  // catch block error message
      setShowMessage(true);
      setTimeout(() => {
        setShowMessage(false);
        window.location.href = "/UserDashboard";
      }, 3000);

      setReportData([]);
      setReportNumber("");
      setKitNumber("");

      toast.error("Failed to delete document");
    } finally {
      setLoading(false);
      setIsDelete(false); // Close modal
    }
  };


  if (loading) {
    return <div><DataSpinners /></div>;
  }


  // const handleConfirm = async () => {
  //   if (!isChecked) {
  //     toast.error("Please confirm that you want to share your health reports.");
  //     console.log("Please confirm that you want to share your health reports.");
  //     return;
  //   }

  //   try {
  //     if (kitNumber != null) {
  //       setLoading(true);
  //       let apiUrl =
  //         selectedOption === "user"
  //           ? `${BASE_URL}/api/update-access`
  //           : `${BASE_URL}/api/doctor/doctor_update_access`;

  //       const response = await axios.post(apiUrl, {
  //         from_email: email,
  //         kitnumber: kit,
  //         from_wallet_address: walletAddress,
  //         to_wallet_address: wallet_Address,
  //         to_email: to_email,
  //         report_doc: reportDoc,
  //         document_type: clickedToken,
  //         reportNumber: reportNum,
  //         created_report_at: created_report_at,
  //       });

  //       console.log("response", response);

  //       if (response.data.status === "success") {
  //       //  toast.success("Profile shared successfully!");

  //       setShowMessage(true);

  //       setTimeout(() => {
  //         setShowMessage(false);
  //         window.location.href = "/UserDashboard";
  //       }, 3000);
  //       } else {
  //         toast.error("Failed to share profile.");
  //       }
  //     } else {
  //       toast.error("Please choose KitNumber.");
  //     }
  //   } catch (error) {
  //     console.error("Error updating view status:", error);
  //     toast.error("Error updating view status");
  //   } finally {
  //     setLoading(false);
  //     setDelete(false);
  //   }
  // };

  // const handleCheckboxChange = (e) => {
  //   setIsChecked(e.target.checked);
  // };

  return (


    <div className="flex justify-center">

      <ToastContainer

        style={{
          top: "20%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          position: "fixed",
          zIndex: "9999",
        }}
      />


      {/* <Toaster /> */}
      <div
        className="bg-[#132135] md:w-[75%] rounded-2xl py-10 px-5 md:p-10 flex justify-center"
        data-aos="fade-zoom-in"
      >
        <div className="w-full">
          <div className="w-[90%] mx-auto bg-white/30 rounded-xl "></div>
          <div
            className=" outline-none cursor-pointer px-5 flex justify-between py-2 rounded-lg bg-white/15"
            onClick={() => setShowModal3(true)}
          >
            <button className="flex items-center">
              {selectedToken3 ? (
                <>
                  <img
                    src={selectedToken3.src}
                    alt={selectedToken3.alt}
                    className="h-5 mr-2"
                  />
                  <p>{selectedToken3.label}</p>
                </>
              ) : (
                "Select"
              )}
            </button>
            <FontAwesomeIcon
              icon={faChevronDown}
              className="pt-1"
            ></FontAwesomeIcon>
          </div>
          <Modal
            visible={showModal3}
            Close={() => setShowModal3(false)}
            onSelectToken={handleTokenSelect3}
          />
          <div className="flex justify-center my-10">
            <div>
              <img src="/assets/Group 427320849.png"></img>
            </div>
          </div>
          <div className="flex justify-between items-center mt-5 border border-[#FFFFFF1A] bg-[#FFFFFF0D] rounded-xl gap-5 py-2.5 px-2 md:px-10">
            <select
              name="reports"
              className="w-full bg-transparent focus:outline-none"
              value={reportNumber}
              onChange={handleReportChange}
            >
              <option value="" className="text-black">Select Report Number</option>
              {Array.isArray(reportData) && reportData.map((report, index) => (
                <option className="text-black" key={index} value={report.reportNumber}>
                  {report.reportNumber}
                </option>
              ))}
            </select>
          </div>

          {/* Kit Number Dropdown */}
          <div className="flex justify-between items-center mt-5 border border-[#FFFFFF1A] bg-[#FFFFFF0D] rounded-xl gap-5 py-2.5 px-2 md:px-10">
            <select
              name="kits"
              className="w-full bg-transparent focus:outline-none"
              value={kitNumber}
              onChange={handleKitChange}
            >
              <option value="" className="text-black">Select Kit Number</option>
              {filteredKits.map((kit, index) => (
                <option className="text-black" key={index} value={kit.kitnumber}>
                  {kit.kitnumber}
                </option>
              ))}
            </select>
          </div>

          <p className="text-[rgb(137,140,169)] py-5 md:w-[75%] mx-auto text-center text-sm"></p>
          <div className="flex justify-center">
            <button
              className="text-white py-2 px-10 rounded-lg bg-gradient-to-bl from-[#004696] to-[#3AB6FF] to-90%"
              onClick={handleDelete}
            >
              Delete Your Report
            </button>
          </div>

          {isDelete && (
            <div
              className="md:w-[100%] rounded-2xl modal-overlay fixed inset-0 flex flex-col lg:flex-row items-center justify-center bg-white bg-opacity-10 backdrop-blur-md"
              data-aos="fade-zoom-in"
            >
              <div className="relative bg-white bg-opacity-30 p-10 rounded-xl">
                <div className="absolute top-2 right-2">
                  <button
                    className="shadow-xl rounded-lg text-xl text-white font-bold transition-transform transform-gpu hover:-translate-y-1 hover:shadow-lg"
                    onClick={() => setIsDelete(false)} // Close modal
                  >
                    <FontAwesomeIcon icon={faXmark} />
                  </button>
                </div>
                <div className="flex flex-col items-center space-y-4">
                  <div className="text-center">
                    <input
                      type="checkbox"
                      checked={isChecked}
                      onChange={handleCheckboxChange}
                    />
                    <label className="font-bold ml-2">
                      Are you sure you want to delete your health reports?
                    </label>
                  </div>
                  {loading ? (
                    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
                      <DataSpinners />
                    </div>
                  ) : (
                    <button
                      className="font-bold text-white py-2 px-10 rounded-lg bg-gradient-to-bl from-[#004696] to-[#3AB6FF] to-90%"
                      onClick={handleConfirm}
                    >
                      Confirm
                    </button>
                  )}

                </div>
              </div>
            </div>
          )}

          {showMessage && (
            <div
              className="fixed top-20 mt-9 right-10 p-3 bg-gradient-to-bl from-[#004696] to-[#3AB6FF] to-90% rounded-lg text-white shadow-lg"
              style={{ zIndex: 9999 }}
            >
              {messageContent}
            </div>
          )}


        </div>
      </div>
    </div>
  );
}
