import React, { useState, useEffect } from "react";
import Header from "./Header";
import Sidebar from "./Sidebar";
import "../App.css";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faCircleCheck,
  faArrowRight,
} from "@fortawesome/free-solid-svg-icons";
import toast, { Toaster } from "react-hot-toast";
import { BASE_URL } from "../config";
import DataSpinners from "../Spinner";
import { useLocation } from "react-router-dom";

const ImagesPerPage = 6;

const DoctorProfile = () => {
  // State to keep track of the current mode
  const [isDarkMode, setIsDarkMode] = useState(true);
  const [spinner, setSpinner] = useState(false);




  const [searchQuery, setSearchQuery] = useState("");
  const [filteredProfiles, setFilteredProfiles] = useState([]);
  const location = useLocation();


  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const searchParam = queryParams.get('search');

    console.log('searchParam',searchParam)
    if (searchParam) {
        setSearchQuery(searchParam);
    }
}, [location.search]);



  // Function to toggle dark mode
  const toggleDarkMode = () => {
    setIsDarkMode(!isDarkMode);
  };

  // Open particular profile details
  const [isOpenDetails, setIsOpenDetails] = useState(false);
  // const handleOpenDetails = () => {
  //     setOpenDetails(!isOpenDetails);
  // }

  const [openDetailsForProfile, setOpenDetailsForProfile] = useState(null);

  // const handleOpenDetails = (kitNumber)=>() => {
  //     setOpenDetailsForProfile(kitNumber);
  // };
  const handleOpenDetails = (kitNumber) => () => {
    setIsOpenDetails(!isOpenDetails);
    setOpenDetailsForProfile(kitNumber);
  };




  const [userProfiles, setUserProfiles] = useState([]);
  const [formPopupData, setFormPopupData] = useState(false);


  
  useEffect(() => {
    const fetchUserProfiles = async () => {
      try {
        setSpinner(true);
        const response = await axios.get(
          `${BASE_URL}/api/get_doctor_profiles_data`
        );
        if (response.data.status === "success") {
          setUserProfiles(response.data.userProfiles);
          setSpinner(false);
        } else {
          console.error("Failed to fetch user profiles:", response.data.error);
          setSpinner(false);
        }
      } catch (error) {
        setSpinner(false);
        console.error(
          "Error fetching user profiles:",
          error.response.data.error
        );
      }
    };

    fetchUserProfiles();
  }, []);


 


  useEffect(() => {
    const filterProfiles = () => {
        if (searchQuery) {
            const lowercasedQuery = searchQuery.toLowerCase();
            const filtered = userProfiles.filter(profile => 
                (profile.name && profile.name.toLowerCase().includes(lowercasedQuery)) ||
                (profile.email && profile.email.toLowerCase().includes(lowercasedQuery)) ||
                (profile.wallet_address && profile.wallet_address.toLowerCase().includes(lowercasedQuery)) ||
                (profile.created_at && profile.created_at.toLowerCase().includes(lowercasedQuery)) 

                
            );
            setFilteredProfiles(filtered);
        } else {
            setFilteredProfiles(userProfiles);
        }
    };
    filterProfiles();
}, [searchQuery, userProfiles]);


  console.log("user profiles", userProfiles);

  const [currentPage, setCurrentPage] = useState(1);
  const [entitiesPerPage, setEntitiesPerPage] = useState(5);

  const indexOfLastEntity = currentPage * entitiesPerPage;
  const indexOfFirstEntity = indexOfLastEntity - entitiesPerPage;

  // let currentEntities = userProfiles.slice(
 let currentEntities = filteredProfiles.slice(
    indexOfFirstEntity,
    indexOfLastEntity
  );

  const emptyRowCount = entitiesPerPage - currentEntities.length;

  const handlePrevClick = () => {
    setCurrentPage(currentPage - 1);
  };

  const handleNextClick = () => {
    if (currentEntities.length === entitiesPerPage) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handleDropdownChange = (e) => {
    setEntitiesPerPage(parseInt(e.target.value));
    setCurrentPage(1);
  };
  const profileToDisplay = userProfiles.find(
    (profile) => profile.kitNumber === openDetailsForProfile
  );
  console.log("profileToDisplay", profileToDisplay);

  const handleCloseDetails = () => () => {
    setIsOpenDetails(!isOpenDetails);
    setOpenDetailsForProfile(null);
  };



  const convertUTCToIST = (utcDate) => {
    const date = new Date(utcDate);
    return date.toLocaleString('en-IN', { timeZone: 'Asia/Kolkata' });
  };

  return (
    <div className={`relative ${isDarkMode ? "dark" : "light"}`}>
      <Toaster />
      <div className="">
        <img
          src="../assets/Ellipse 271.png"
          className=" absolute bottom-0 right-0 "
        />
      </div>

      <Header toggleDarkMode={toggleDarkMode} isDarkMode={isDarkMode} />

      <div
        className={`flex min-h-screen font-inter pt-5 ${
          isDarkMode ? "bg-[#05295A]" : "bg-white"
        } `}
      >
        <Sidebar isDarkMode={isDarkMode} />
        <div className="lg:w-[80%] w-full relative">
          <div className="lg:mx-10 mx-5 mt-10">
            <div
              className={`${
                isDarkMode ? "bg-white/10" : "bg-[#3ab7ff28]"
              } w-full rounded-md`}
            >
              <p
                className={` ${
                  isDarkMode ? "text-white" : "text-black"
                } text-end 2xl:text-base text-xs px-3 2xl:py-10 py-2`}
              >
                Show no of entity
                <select
                  className="ml-2 outline-none rounded-md bg-transparent border-[1px]"
                  onChange={handleDropdownChange}
                  value={entitiesPerPage}
                >
                  <option className="text-black" value="5">
                    5
                  </option>
                  <option className="text-black" value="10">
                    10
                  </option>
                  <option className="text-black" value="50">
                    50
                  </option>
                </select>
              </p>
              <div
                className={`   overflow-hidden overflow-x-scroll overflow-y-scroll`}
              >
                <table className="w-full">
                  <thead className="">
                    <tr
                      className={`border-b border-white/40 2xl:text-2xl ${
                        isDarkMode ? "text-[#C8C2E8]" : "text-black"
                      } `}
                    >
                      <th className="md:px-5 px-20 2xl:text-lg text-base py-5 text-center">
                        S.NO
                      </th>
                      <th className="md:px-5 px-20 2xl:text-lg text-base py-5 text-center">
                        Email
                      </th>
                      <th className="md:px-5 px-20 2xl:text-lg text-base py-5 text-center">
                        Wallet address
                      </th>
                      <th className="md:px-5 px-20 2xl:text-lg text-base py-5 text-center">
                      Created At
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentEntities.length === 0 ? (
                      <tr>
                        <td
                          colSpan="4"
                          className={`text-center py-5 font-bold ${
                            isDarkMode ? "text-white" : "text-black"
                          }`}
                        >
                          No data found
                        </td>
                      </tr>
                    ) : (
                      currentEntities.map((data, index) => (
                        <tr
                          key={index}
                          className={`${
                            isDarkMode ? "text-white/75" : "text-black"
                          }`}
                        >
                          <td className="text-sm px-5 text-center 2xl:py-5 py-3 whitespace-nowrap">
                          {indexOfFirstEntity + index + 1}
                          </td>
                          <td className="text-sm px-5 text-center 2xl:py-5 py-3 whitespace-nowrap">
                            {data.email}
                          </td>
                          <td className="text-sm px-5 text-center 2xl:py-5 py-3 font-semibold whitespace-nowrap">
                            {data.wallet_address}
                          </td>
                          <td className="text-sm px-5 text-center 2xl:py-5 py-3 font-semibold whitespace-nowrap">
                          {convertUTCToIST(data.created_at)}
                            {/* <button onClick={handleOpenDetails(data.kitNumber)} className=' cursor-pointer ' >View Profile</button> */}
                          </td>
                        </tr>
                      ))
                    )}
                    {/* {Array.from({ length: emptyRowCount }).map((_, index) => (
                      <tr key={`empty-${index}`} className="h-14">
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                      </tr>
                    ))} */}
                  </tbody>
                </table>
              </div>
            </div>
            <div className=" flex justify-between text-xs px-10 pt-5">
              <button
                className="bg-white/30 cursor-pointer rounded-md px-5 py-1"
                onClick={handlePrevClick}
                disabled={currentPage === 1}
              >
                <FontAwesomeIcon icon={faArrowLeft} />
              </button>
              <button
                className="bg-white/30  cursor-pointer rounded-md px-5 py-1"
                onClick={handleNextClick}
                disabled={currentEntities.length < entitiesPerPage}
              >
                <FontAwesomeIcon icon={faArrowRight} />
              </button>
            </div>
          </div>

          {isOpenDetails && (
            <div
              className={`absolute top-0 right-0  w-full h-full z-30 overflow-hidden overflow-y-auto md:overflow-hidden ${
                isDarkMode ? "bg-[#05295A] text-white" : "bg-white text-black"
              } rounded-md p-10 ' data-aos='fade-zoom-in`}
            >
              <FontAwesomeIcon
                icon={faArrowLeft}
                onClick={() => setIsOpenDetails(false)}
              ></FontAwesomeIcon>
        
            </div>
          )}
        </div>
      </div>


      {spinner ? <DataSpinners /> : ""}
    </div>
  );
};

export default DoctorProfile;