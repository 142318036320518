import { faFacebookF, faLinkedinIn, faXTwitter } from '@fortawesome/free-brands-svg-icons'
import { faInstagram } from '@fortawesome/free-brands-svg-icons/faInstagram'
import { faEnvelope, faLocationDot, faPhone } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

export default function LandingFooter() {
    return (
        <div className=' bg-gray-100 py-10'>
        <div className='font-poppins  flex flex-col md:flex-row gap-5 md:gap-0 justify-between w-[80%] md:w-[90%] mx-auto text-sm 2xl:text-base'>
            <div className='md:w-[40%]'>
                <div><img src='../assets/logofooter.png'></img></div>
                <p className='text-[#71717A] mt-5'>Join thousands of users already benefiting from Precisya’s secure lab reports as digital solution. Take control of your health data today and experience unparalleled peace of mind with Precisya’s Digital Health Profile.</p>
                <div className='mt-5 flex gap-5 text-[#192031] text-2xl'>
                    <a href="" ><FontAwesomeIcon icon={faFacebookF} className='bg-white px-3 py-2 rounded-full'></FontAwesomeIcon></a>
                    <a href="" ><FontAwesomeIcon icon={faXTwitter} className='bg-white px-3 py-2 rounded-full'></FontAwesomeIcon></a>
                    <a href="" ><FontAwesomeIcon icon={faLinkedinIn} className='bg-white px-3 py-2 rounded-full'></FontAwesomeIcon></a>
                    <a href="" ><FontAwesomeIcon icon={faInstagram} className='bg-white px-3 py-2 rounded-full'></FontAwesomeIcon></a>

                </div>
                
            </div>
            <div className='md:w-[10%] text-[#71717A] flex flex-col gap-2 md:gap-5'>
                <p className='text-[#192031] font-bold'>Navigation</p>
                <a>Home</a>
                <a>About us</a>
                <a>Services</a>
                <a>My Profile</a>
                <a>Contact</a>
            </div>
            <div className='md:w-[10%] text-[#71717A] flex flex-col gap-2 md:gap-5'>
                <p className='text-[#192031] font-bold'>Licence</p>
                <p>Privacy Policy</p>
                <p>Copyright</p>
                <p>Email Address</p>
               
            </div>
            <div className='md:w-[20%] text-[#71717A] flex flex-col gap-2 md:gap-5'>
                <p className='text-[#192031] font-bold'>Contact</p>
                <p className='flex gap-3'><FontAwesomeIcon icon={faPhone} className='text-lg text-[#192031]'></FontAwesomeIcon><p>+91 9346552742</p></p>
                <p className='flex gap-3'><FontAwesomeIcon icon={faEnvelope} className='text-lg text-[#192031]'></FontAwesomeIcon><p>Lab@Precisya.com</p></p>
                <p className='flex gap-3'><FontAwesomeIcon icon={faLocationDot} className='text-lg text-[#192031]'></FontAwesomeIcon><p>160 Robinson Rd, #06-01 SBF Center, Singapore 068914</p></p>
            </div>
            
        </div>
        </div>
    )
}
