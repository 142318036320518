import React, { useState, useEffect } from 'react';
import Header from './Header';
import Sidebar from './Sidebar';
import '../App.css' 
import DataSpinners from '../Spinner';
import axios from 'axios';
import { BASE_URL } from '../config';
import toast, { Toaster } from "react-hot-toast";
import { useSelector } from 'react-redux';

const CreateProfile = () => {
    const [isDarkMode, setIsDarkMode] = useState(true);
    const [loading, setLoading] = useState(true);
    const [email, setEmail] = useState('');
    const [isValid, setIsValid] = useState(false);
    const [selectedOption, setSelectedOption] = useState('user'); 
    const [relation, setRelation] = useState('self'); 
    const creatorEmail = useSelector((state) => state.admin_email);

    const toggleDarkMode = () => {
        setIsDarkMode(!isDarkMode);
    };

    const validateEmail = (email) => {
        const re = /\S+@\S+\.\S+/;
        return re.test(email);
    };

    const handleSubmit = async () => {
        if (isValid) {
            console.log("Creator Email:", creatorEmail);
    
            if (selectedOption === 'Doctor') {
                const response = await axios.get(`${BASE_URL}/api/findDoctorUser?email=${email}`);
                if (response.data.status === 'failed') {
                    toast.error(response.data.message);
                    return false;
                } else {
                    const create_profile = await axios.post(`${BASE_URL}/api/create_doctor_profile`, { email, creatorEmail, relation });
                    if (create_profile.data.status === 'failed') {
                        toast.error(create_profile.data.message);
                        setTimeout(() => window.location.reload(), 2000);
                    } else {
                        toast.success(create_profile.data.message);
                        setTimeout(() => window.location.reload(), 2000);
                    }
                }
            } else {
                const response = await axios.get(`${BASE_URL}/api/findUser?email=${email}`);
                if (response.data.status === 'failed') {
                    toast.error(response.data.message);
                    return false;
                } else {
                    console.log(creatorEmail);
                    const create_profile = await axios.post(`${BASE_URL}/api/create_profile`, { email, creatorEmail, relation });
    
                    if (create_profile.data.status === 'failed') {
                        toast.error(create_profile.data.message);
                        setTimeout(() => window.location.reload(), 2000);
                    } else {
                        toast.success(create_profile.data.message);
                        setTimeout(() => window.location.reload(), 2000);
                    }
                }
            }
        } else {
            toast.error("Please enter a valid email");
        }
    };
    

    const handleOptionChange = (e) => {
        setSelectedOption(e.target.value);
    };

    const handleRelationChange = (e) => {
        setRelation(e.target.value);
    };

    const handleEmail = async (e) => {
        setEmail(e.target.value);
        setIsValid(validateEmail(e.target.value));
    };

    useEffect(() => {
        setTimeout(() => setLoading(false), 1000);
    }, []);

    return (
        <div className={`relative ${isDarkMode ? 'dark' : 'light'}`}>
            <Toaster />
            <div className="">
                <img src="../assets/Ellipse 271.png" className="w-3/12 absolute bottom-0 right-0 " />
            </div>

            <Header toggleDarkMode={toggleDarkMode} isDarkMode={isDarkMode} />

            <div className={`flex min-h-screen pb-10 font-inter pt-5 ${isDarkMode ? 'bg-[#05295A]' : 'bg-white'} `}>
                <Sidebar isDarkMode={isDarkMode} />

                <div className='md:w-[50%] mx-auto'>
                    <div className={` ${isDarkMode ? 'border border-white/40' : 'border border-black/40 bg-[#3ab7ff28]'} relative p-10`} style={{ backgroundImage: 'radial-gradient(circle, #FFFFFF1C, #AFAFAF1C)' }}>
                        <img src='../assets/As1.png' className='absolute -bottom-8 right-0 w-20' />
                        <div className='flex flex-col justify-center items-center'>
                            <div className="flex justify-center">
                                <img src="../assets/Group 427320849.png" className="w-9/12 " />
                            </div>

                            <div className='w-[60%] mt-10'>
                                <label className={`${isDarkMode ? 'text-white' : 'text-black'}`}>Member </label>
                                <select
                                    value={selectedOption} onChange={handleOptionChange}
                                    className={`w-full ${isDarkMode ? 'text-white border border-white/50' : 'text-black border border-black/50'} focus:outline-none bg-white/15 px-4 py-2 shadow-sm shadow-white rounded-2xl mt-3 `}
                                    style={{ borderColor: isValid ? 'green' : 'red' }}
                                >
                                    <option value="user" className='text-black'>User</option>
                                    <option value="Doctor" className='text-black'>Doctor</option>
                                </select>
                            </div>

                            {/* Relation dropdown */}
                            <div className='w-[60%] mt-5'>
                                <label className={`${isDarkMode ? 'text-white' : 'text-black'}`}>Relation</label>
                                <select
                                    value={relation} onChange={handleRelationChange}
                                    className={`w-full ${isDarkMode ? 'text-white border border-white/50' : 'text-black border border-black/50'} focus:outline-none bg-white/15 px-4 py-2 shadow-sm shadow-white rounded-2xl mt-3`}
                                >
                                    
                                    <option className ="text-black" value="self">Self</option>
                                    <option className ="text-black" value="father">Father</option>
                                    <option className ="text-black" value="mother">Mother</option>
                                    <option className ="text-black" value="sister">Sister</option>
                                    <option className ="text-black" value="brother">Brother</option>
                                </select>
                            </div>

                            <div className='w-[60%] mt-3'>
                                <label className={`${isDarkMode ? 'text-white' : 'text-black'}`}>Email Id </label>
                                <input
                                    type='text'
                                    placeholder='Enter Email Id'
                                    name='email'
                                    className={`${isDarkMode ? 'text-white border border-white/50' : 'text-black border border-black/50'} w-full focus:outline-none bg-white/15 px-4 py-2 shadow-sm shadow-white rounded-2xl mt-3`}
                                    onChange={handleEmail}
                                    style={{ borderColor: isValid ? 'green' : 'red' }}
                                />

                                <div className='flex justify-center mt-10'>
                                    {loading ? <DataSpinners /> :
                                        <button onClick={handleSubmit} className={`${isDarkMode ? 'text-white border border-white' : 'text-black border border-black'} px-7 py-2 rounded-full`}>
                                            Create Profile
                                        </button>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CreateProfile;
