// import React, { useEffect, useState } from "react";

// import "../App.css";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import {
//   faCheck,
//   faCircleXmark,
//   faArrowLeft,
//   faArrowRight,
//   faTimesCircle,
//   faExclamationTriangle,
//   faCircle,
// } from "@fortawesome/free-solid-svg-icons";
// import { useSelector } from "react-redux";
// import { BASE_URL } from "../config";

// import axios from "axios";
// const Notification = () => {
//   const [isDarkMode, setIsDarkMode] = useState(true);

//   const toggleDarkMode = () => {
//     setIsDarkMode(!isDarkMode);
//   };

//   const email = useSelector((state)=>state.doctor_email)

//   const [table, setTable] = useState([]);

//   useEffect(() => {
//     fetchData();
//   }, []);

//   const fetchData = async () => {
//     try {
//       const response = await axios.post(
//         `${BASE_URL}/api/doctor_fetch_request_access`,
//         { email }
//       );
//       setTable(response.data);

//       console.log("response", response);
//     } catch (error) {
//       console.error("Error fetching notifications:", error.message);
//     }
//   };

//   console.log("00000000000000", table)

//   const [currentPage, setCurrentPage] = useState(1);
//   const [entitiesPerPage, setEntitiesPerPage] = useState(4);

//   const indexOfLastEntity = currentPage * entitiesPerPage;
//   const indexOfFirstEntity = indexOfLastEntity - entitiesPerPage;

//   let currentEntities = table.slice(indexOfFirstEntity, indexOfLastEntity);

//   const emptyRowCount = entitiesPerPage - currentEntities.length;

//   const handlePrevClick = () => {
//     setCurrentPage(currentPage - 1);
//   };

//   const handleNextClick = () => {
//     if (currentEntities.length === entitiesPerPage) {
//       setCurrentPage(currentPage + 1);
//     }
//   };

//   const handleDropdownChange = (e) => {
//     setEntitiesPerPage(parseInt(e.target.value));
//     setCurrentPage(1);
//   };

//   return (
//     <div>
//       <div className={`${isDarkMode ? "dark" : "light"}`}>
//         <div
//           className={`bg-[#05295A] ${
//             isDarkMode ? "bg-[#05295A]" : "bg-white"
//           } flex min-h-screen font-inter pt-5 `}
//         >

//           <div className="lg:w-[80%] w-full">
//             <div className="md:h-screen overflow-hidden overflow-y-auto w-[100%]">
//               <div
//                 className={`rounded-xl mx-5 ${
//                   isDarkMode ? "bg-white/20" : "bg-[#3ab7ff28]"
//                 } px-10 pb-5`}
//               >
//                 <p
//                   className={` ${
//                     isDarkMode ? "text-white" : "text-black"
//                   } text-end 2xl:text-base text-xs px-3 2xl:py-10 py-2`}
//                 >
//                   Show no of entity
//                   <select
//                     className="ml-2 outline-none rounded-md bg-transparent border-[1px]"
//                     onChange={handleDropdownChange}
//                     value={entitiesPerPage}
//                   >
//                     <option className="text-black" value="5">
//                       5
//                     </option>
//                     <option className="text-black" value="10">
//                       10
//                     </option>
//                     <option className="text-black" value="50">
//                       50
//                     </option>
//                   </select>
//                 </p>

//                 <div className=" rounded-xl overflow-hidden overflow-x-auto ">
//                   <table className="w-full text-center  ">
//                     <thead className="">
//                       <tr
//                         className={`2xl:text-xl sm:text-base text-sm  ${
//                           isDarkMode ? "text-white" : "text-black"
//                         }`}
//                       >
//                         <th className="py-5 px-5 md:px-0">From</th>
//                         <th className="py-5 px-5 md:px-0">Date</th>
//                         <th className="py-5 px-5 md:px-0">Kit Number </th>
//                         <th className="py-5 px-5 md:px-0">Notification Type</th>
//                         <th className="py-5 px-5 md:px-0">Status</th>
//                       </tr>
//                     </thead>
//                     <tbody className="">
//                       {currentEntities.length === 0 ? (
//                         <tr>
//                           <td colSpan="5" className={`py-5 text-center font-bold ${isDarkMode ? 'text-white' : 'text-black'}`}>
//                             No notifications found
//                           </td>
//                         </tr>
//                       ) : (
//                         currentEntities.map((data, index) => (
//                           <tr
//                             key={index}
//                             className={`bg-white rounded-xl 2xl:text-lg text-xs md:text-sm ${
//                               isDarkMode
//                                 ? "border-y-8 border-[#37547b]"
//                                 : "border-y-8 border-[#3ab7ff28]"
//                             }`}
//                           >
//                             <td className="py-5 px-5 md:px-0 rounded-l-xl">
//                               {data.from_email}
//                             </td>
//                             <td className="py-5 px-5 md:px-0">
//                               {new Date(data.created_at).toLocaleDateString(
//                                 "en-IN"
//                               )}{" "}
//                               ||{" "}
//                               {new Date(data.created_at).toLocaleTimeString(
//                                 "en-IN"
//                               )}
//                             </td>
//                             <td className="py-5 px-5 md:px-0">
//                               {data.kitnumber}
//                             </td>
//                             <td className="py-5 px-5 md:px-0">
//                               {data.document_type}
//                             </td>
//                             <td className="py-5 px-5 md:px-0 rounded-r-xl">
//                               {data.view_status === null ? (
//                                 <p className="text-yellow-500 gap-2 font-bold flex justify-center items-center">
//                                   Pending
//                                   <span>
//                                     <FontAwesomeIcon icon={faCircle} />
//                                   </span>
//                                 </p>
//                               ) : data.view_status === "1" ? (
//                                 <p className="text-[#1EEF32] font-bold flex gap-2 justify-center items-center">
//                                   Approved
//                                   <span>
//                                     <img
//                                       src="../assets/image 106.svg"
//                                       className="w-3"
//                                       alt="Approved"
//                                     />
//                                   </span>
//                                 </p>
//                               ) : data.view_status === "0" ? (
//                                 <p className="text-[#FF3131] gap-2 font-bold flex justify-center items-center">
//                                   Access Denied
//                                   <span>
//                                     <FontAwesomeIcon icon={faTimesCircle} />
//                                   </span>
//                                 </p>
//                               ) : (
//                                 <p className="text-red-500 gap-2 font-bold flex justify-center items-center">
//                                   Invalid Status
//                                   <span>
//                                     <FontAwesomeIcon
//                                       icon={faExclamationTriangle}
//                                     />
//                                   </span>
//                                 </p>
//                               )}
//                             </td>
//                           </tr>
//                         ))
//                       )}

//                       {Array.from({ length: emptyRowCount }).map((_, index) => (
//                         <tr key={`empty-${index}`} className="h-14">
//                           <td>&nbsp;</td>
//                           <td>&nbsp;</td>
//                           <td>&nbsp;</td>
//                         </tr>
//                       ))}
//                     </tbody>
//                   </table>
//                 </div>

//               </div>
//               <div className=" flex justify-between text-xs px-10 pb-5 mt-5">
//                   <button
//                     className={`${
//                       isDarkMode ? "bg-white/20" : "bg-[#3ab7ff28]"
//                     } rounded-md px-5 py-1`}
//                     onClick={handlePrevClick}
//                     disabled={currentPage === 1}
//                   >
//                     <FontAwesomeIcon icon={faArrowLeft} />
//                   </button>
//                   <button
//                     className={`${
//                       isDarkMode ? "bg-white/20" : "bg-[#3ab7ff28]"
//                     } rounded-md px-5 py-1`}
//                     onClick={handleNextClick}
//                     disabled={currentEntities.length < entitiesPerPage}
//                   >
//                     <FontAwesomeIcon icon={faArrowRight} />
//                   </button>
//                 </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Notification;

// import { faCheckCircle, faCircle, faMagnifyingGlass, faXmarkCircle, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import React, { useState, useEffect } from 'react';
// import { useSelector } from "react-redux";
// import { BASE_URL } from "../config";
// import axios from "axios";
// import { useDispatch } from 'react-redux';
// import { setNotificationCount } from '../store';

// export default function Notification() {
//     const [currentPage, setCurrentPage] = useState(1);
//     const dispatch = useDispatch();

//     const [entitiesPerPage, setEntitiesPerPage] = useState(4);
//     const [table, setTable] = useState([]);
//     const email = useSelector((state) => state.doctor_email);
//     const [searchTerm, setSearchTerm] = useState("");

//     useEffect(() => {
//         fetchData();
//     }, []);

//     const fetchData = async () => {
//         try {
//             const response = await axios.post(`${BASE_URL}/api/doctor_fetch_request_access`, { email });
//             setTable(response.data);
//             console.log("response", response);
//             dispatch(setNotificationCount(response.data.length));
//             console.log("cccccccccccccountttttt", response.data.length)

//         } catch (error) {
//             console.error("Error fetching notifications:", error.message);
//         }
//     };

//     console.log("00000000000000", table);

//     const filteredEntities = table.filter(entity =>
//         (entity.to_email?.toLowerCase() || '').includes(searchTerm.toLowerCase()) ||
//         (entity.kitnumber?.toLowerCase() || '').includes(searchTerm.toLowerCase()) ||
//         (entity.document_type?.toLowerCase() || '').includes(searchTerm.toLowerCase()) ||
//         (entity.status?.toLowerCase() || '').includes(searchTerm.toLowerCase())
//     );

//     const indexOfLastEntity = currentPage * entitiesPerPage;
//     const indexOfFirstEntity = indexOfLastEntity - entitiesPerPage;
//     const currentEntities = filteredEntities.slice(indexOfFirstEntity, indexOfLastEntity);
//     const emptyRowCount = entitiesPerPage - currentEntities.length;

//     const handlePrevClick = () => {
//         setCurrentPage(currentPage - 1);
//     };

//     const handleNextClick = () => {
//         if (currentEntities.length === entitiesPerPage) {
//             setCurrentPage(currentPage + 1);
//         }
//     };

//     const handleDropdownChange = (e) => {
//         setEntitiesPerPage(parseInt(e.target.value));
//         setCurrentPage(1);
//     };

//     const handleSearchChange = (e) => {
//         setSearchTerm(e.target.value);
//         setCurrentPage(1); // Reset to the first page whenever the search term changes
//     };

import {
  faCheckCircle,
  faMagnifyingGlass,
  faXmarkCircle,
  faQuestionCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { BASE_URL } from "../config";
import axios from "axios";
import {
  setNotificationCount,
  resetNotificationCountFromStorage,
} from "../store";
import DataSpinners from "../Spinner";

export default function Notification() {
  const [currentPage, setCurrentPage] = useState(1);
  const [entitiesPerPage, setEntitiesPerPage] = useState(5);
  const [table, setTable] = useState([]);
  const [previousDataLength, setPreviousDataLength] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [spinner, setSpinner] = useState(false);

  const email = useSelector((state) => state.doctor_email);
  const dispatch = useDispatch();

  useEffect(() => {
    fetchData();
    dispatch(setNotificationCount());
  }, []);

  // const fetchData = async () => {
  //     try {
  //         const response = await axios.post(`${BASE_URL}/api/doctor_fetch_request_access`, { email });
  //         const newData = response.data;
  //         newData.sort((a, b) => new Date(b.created_at) - new Date(a.created_at)); // Sort by latest created_at date
  //         setTable(newData);

  //         const storedLength = parseInt(localStorage.getItem("notificationLength")) || 0;
  //         console.log("storedLength", storedLength);

  //         if (newData.length > storedLength) {
  //             dispatch(setNotificationCount(newData.length - storedLength));
  //         }

  //         localStorage.setItem("notificationLength", newData.length);
  //     } catch (error) {
  //         console.error('Error fetching notifications:', error.message);
  //     }
  // };

  const fetchData = async () => {
    try {
      setSpinner(true);
      const response = await axios.post(
        `${BASE_URL}/api/doctor_fetch_request_access`,
        { email }
      );
      const newData = response.data;
      newData.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
      setTable(newData);

      const storedLength =
        parseInt(localStorage.getItem("notificationLength")) || 0;
      console.log("storedLength", storedLength);

      if (newData.length > storedLength) {
        dispatch(setNotificationCount(newData.length - storedLength));
      }

      // Check for changes in view_status from null to 1 or 0
      const statusChangedCount = newData.reduce((count, entity) => {
        const prevEntity = table.find((e) => e.id === entity.id); // Assuming each entity has a unique id
        if (
          entity.view_status === null &&
          (entity.view_status === "1" || entity.view_status === "0")
        ) {
          return count + 1;
        }
        return count;
      }, 0);

      if (statusChangedCount > 0) {
        dispatch(setNotificationCount(statusChangedCount));
      }

      localStorage.setItem("notificationLength", newData.length);
      setSpinner(false);
    } catch (error) {
      setSpinner(false);
      console.error("Error fetching notifications:", error.message);
    }
  };

  console.log("previousDataLength", previousDataLength);
  const filteredEntities = table.filter(
    (entity) =>
      (entity.to_email?.toLowerCase() || "").includes(
        searchTerm.toLowerCase()
      ) ||
      (entity.kitnumber?.toLowerCase() || "").includes(
        searchTerm.toLowerCase()
      ) ||
      (entity.document_type?.toLowerCase() || "").includes(
        searchTerm.toLowerCase()
      ) ||
      (entity.status?.toLowerCase() || "").includes(searchTerm.toLowerCase())
  );

  const indexOfLastEntity = currentPage * entitiesPerPage;
  const indexOfFirstEntity = indexOfLastEntity - entitiesPerPage;
  const currentEntities = filteredEntities.slice(
    indexOfFirstEntity,
    indexOfLastEntity
  );
  const emptyRowCount = entitiesPerPage - currentEntities.length;

  const handlePrevClick = () => {
    setCurrentPage(currentPage - 1);
  };

  // const handleNextClick = () => {
  //     if (currentEntities.length === entitiesPerPage) {
  //         setCurrentPage(currentPage + 1);
  //     }
  // };
  const handleNextClick = () => {
    const remainingEntities = filteredEntities.length - indexOfLastEntity;
    if (remainingEntities > 0) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handleDropdownChange = (e) => {
    setEntitiesPerPage(parseInt(e.target.value));
    setCurrentPage(1);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1);
  };

  const truncateString = (str, num) => {
    if (str.length <= num) {
      return str;
    }
    return str.slice(0, num) + "...";
  };

  return (
    <div className="md:p-10 h-[100vh]">
      <div className=" bg-white/20 rounded-2xl py-10 px-5 md:p-10">
        <div className="flex flex-col gap-5 md:gap-0 md:flex-row justify-between">
          <div className="md:w-[35%] flex border rounded-md">
            <FontAwesomeIcon
              icon={faMagnifyingGlass}
              className="w-[5%] pl-2 mt-2"
            />
            <input
              className="bg-transparent py-1 w-[95%] outline-none pl-2 placeholder:text-sm"
              placeholder="Search Profile"
              value={searchTerm}
              onChange={handleSearchChange}
            />
          </div>
          <div className="flex items-center">
            <p className=" text-xs px-3 mb-2">
              Show no of entity
              <select
                className="ml-2 outline-none rounded-md bg-transparent border-[1px]"
                onChange={handleDropdownChange}
                value={entitiesPerPage}
              >
                <option className="text-black" value="5">
                  5
                </option>
                <option className="text-black" value="10">
                  10
                </option>
                <option className="text-black" value="15">
                  15
                </option>
              </select>
            </p>
          </div>
        </div>
        <div className=" pb-5 rounded-xl overflow-hidden overflow-x-auto ">
          <table className="w-full text-center">
            <thead className="">
              <tr className=" text-sm md:text-md">
                <th className="py-5 px-5 md:px-0">From</th>
                <th className="py-5 px-5 md:px-0">To</th>

                <th className="py-5 px-5 md:px-0">Date</th>
                <th className="py-5 px-5 md:px-0">Kit-Number</th>
                <th className="py-5 px-5 md:px-0">Notification Type</th>
                <th className="py-5 px-5 md:px-0">Status</th>
              </tr>
            </thead>
            <tbody>
              {currentEntities.map((entity, index) => (
                <tr
                  key={index}
                  className={`h-16 border-y-4 border-[#37547b] text-black text-xs md:text-sm rounded-md ${
                    entity.status === "Approved"
                      ? "bg-[#2A8263]"
                      : entity.status === "Pending"
                      ? "bg-[#EAE345]/50"
                      : entity.status === "Access Denied"
                      ? "bg-[#FDB9B9]"
                      : "bg-white/20"
                  }`}
                >
                  <td className="rounded-l-md px-5 md:px-0">
                    {entity.from_email}
                  </td>

                  <td className="rounded-l-md px-5 md:px-0">
                    {truncateString(entity.to_email, 9)}
                  </td>

                  <td className="px-5 md:px-0">{entity.created_at}</td>
                  <td className="px-5 md:px-0">{entity.kitnumber}</td>
                  <td className="px-5 md:px-0">{entity.document_type}</td>
                  <td
                    className={`px-5 md:px-0 rounded-r-md font-bold ${
                      entity.view_status === "1" &&
                      entity.is_profile_shared === "1"
                        ? "text-[#1EEF32]"
                        : entity.view_status === "0"
                        ? "text-[#FF0000]"
                        : "text-[#F8EF15]"
                    }`}
                  >
                    {entity.view_status === "1" &&
                    entity.is_profile_shared === "1" ? (
                      <a
                        className="boarder-b"
                        href={entity.report_doc}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        View Report
                      </a>
                    ) : entity.view_status === "1" ? (
                      "Approved"
                    ) : entity.view_status === "0" ? (
                      "Access Denied"
                    ) : (
                      "Pending"
                    )}
                    <FontAwesomeIcon
                      icon={
                        entity.view_status === "1"
                          ? faCheckCircle
                          : entity.view_status === "0"
                          ? faXmarkCircle
                          : faQuestionCircle
                      }
                      className="pl-1 text-xs"
                    />
                  </td>
                </tr>
              ))}
              {/* Add empty rows if needed */}
              {Array.from({ length: emptyRowCount }).map((_, index) => (
                <tr key={`empty-${index}`} className="h-20 ">
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="mt-2 flex justify-between text-xs px-3">
          <button
            className="bg-white/30 rounded-md px-5 py-1"
            onClick={handlePrevClick}
            disabled={currentPage === 1}
          >
            Prev
          </button>
          <button
            className="bg-white/30 rounded-md px-5 py-1"
            onClick={handleNextClick}
            disabled={currentEntities.length < entitiesPerPage}
          >
            Next
          </button>
        </div>
      </div>
      {spinner ? <DataSpinners /> : ""}
    </div>
  );
}
