import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../App.css';
import Header from './Header';
import Sidebar from './Sidebar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import DataSpinners from "../Spinner";
import { BASE_URL } from "./config";
import toast, { Toaster } from 'react-hot-toast';
import { useSelector } from 'react-redux';

const DeleteProfile = () => {
  const [isDarkMode, setIsDarkMode] = useState(true);
  const [userDetails, setUserDetails] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showConfirmationReportModal, setShowConfirmationReportModal] = useState(false);
  const [userToDelete, setUserToDelete] = useState({ userId: null, email: '' });
  const creatorEmail = useSelector((state) => state.admin_email);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/api/admin/users_report`, {
          params: { creatorEmail }
        });
        setUserDetails(response.data);
      } catch (error) {
        console.error('Error fetching users:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchUsers();
  }, [creatorEmail]);

  const toggleDarkMode = () => {
    setIsDarkMode(!isDarkMode);
  };

  const handleDeleteUser = async () => {
    try {
      const { userId, email } = userToDelete;
      const response = await axios.delete(`${BASE_URL}/api/admin/delete_users/${userId}/${email}`);
      setUserDetails(userDetails.filter(user => user.user_id !== userId));

      if (response.data.status === "success") {
        toast.success(`${userId} deleted successfully`);
      }

      setShowConfirmationModal(false);
    } catch (error) {
      console.error('Error deleting user:', error);
      toast.error('Failed to delete user');
    }
  };

  const handleDeleteUserReport = async () => {
    try {
      const { userId, email } = userToDelete;
      const response = await axios.delete(`${BASE_URL}/api/admin/delete_users_report/${userId}/${email}`);
      setUserDetails(userDetails.filter(user => user.user_id !== userId));

      if (response.data.status === "success") {
        toast.success(`Deleted report access for ${email} successfully`);
      }

      setShowConfirmationReportModal(false);
    } catch (error) {
      console.error('Error deleting user report:', error);
      toast.error('Failed to delete report access');
    }
  };

  const openConfirmationModal = (userId, email) => {
    setUserToDelete({ userId, email });
    setShowConfirmationModal(true);
  };

  const openConfirmationReportModal = (userId, email) => {
    setUserToDelete({ userId, email });
    setShowConfirmationReportModal(true);
  };

  const closeConfirmationModal = () => {
    setShowConfirmationModal(false);
  };

  const closeConfirmationReportModal = () => {
    setShowConfirmationReportModal(false);
  };

  if (loading) {
    return <DataSpinners />;
  }

  return (
    <div className={`relative ${isDarkMode ? 'dark' : 'light'}`}>
      <Toaster />
      <Header toggleDarkMode={toggleDarkMode} isDarkMode={isDarkMode} />
      <div className={`flex min-h-screen font-inter pt-5 ${isDarkMode ? 'bg-[#05295A]' : 'bg-white'}`}>
        <Sidebar isDarkMode={isDarkMode} />
        <div className="lg:w-[80%] w-full mx-5 custom-scrollbar">
          <div className={`py-4 px-5 rounded-lg ${isDarkMode ? 'bg-white/25' : 'bg-[#3ab7ff28]'}`}>
            <div className="overflow-y-auto h-[28rem]">
              <div className="flex flex-col gap-3">
                {userDetails.map((user, index) => (
                  <div key={index} className="bg-white rounded-lg flex justify-between items-center p-2">
                    <div className="flex items-center gap-2">
                      <img src="../assets/Group 1000015035.png" className="w-4/12" alt="User Avatar" />
                      <p className="text-[#3AB6FF] font-semibold">{user.email}</p>
                    </div>
                    <div className="flex flex-col items-center">
                      <button
                        onClick={() => openConfirmationModal(user.user_id, user.email)}
                        className="flex justify-center items-center bg-gradient-to-r from-[#3AB6FF] via-[#004696] to-[#FF3131] font-bold rounded-full text-white p-1 mx-1"
                      >
                        Delete User
                      </button><br />
                      <button
                        onClick={() => openConfirmationReportModal(user.user_id, user.email)}
                        className="flex justify-center items-center bg-gradient-to-r from-[#3AB6FF] via-[#004696] to-[#FF3131] font-bold rounded-full text-white p-1 mx-1"
                      >
                        Delete Report Access
                      </button>

                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Confirmation Modal */}
      {showConfirmationModal && (
        <div className="fixed top-0 left-0 right-0 bottom-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
          <div className="bg-white p-5 rounded-lg shadow-lg">
            <p className="text-lg font-semibold">Are you sure you want to delete {userToDelete.email} profile?</p>
            <div className="flex justify-end gap-3 mt-5">
              <button onClick={handleDeleteUser} className="bg-red-500 hover:bg-red-600 text-white py-2 px-4 rounded-md">Delete</button>
              <button onClick={closeConfirmationModal} className="bg-gray-300 hover:bg-gray-400 text-gray-800 py-2 px-4 rounded-md">Cancel</button>
            </div>
          </div>
        </div>
      )}

      {/* Confirmation Report Modal */}
      {showConfirmationReportModal && (
        <div className="fixed top-0 left-0 right-0 bottom-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
          <div className="bg-white p-5 rounded-lg shadow-lg">
            <p className="text-lg font-semibold">Are you sure you want to delete report access for {userToDelete.email}?</p>
            <div className="flex justify-end gap-3 mt-5">
              <button onClick={handleDeleteUserReport} className="bg-red-500 hover:bg-red-600 text-white py-2 px-4 rounded-md">Delete</button>
              <button onClick={closeConfirmationReportModal} className="bg-gray-300 hover:bg-gray-400 text-gray-800 py-2 px-4 rounded-md">Cancel</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DeleteProfile;
