import React, { useState, useEffect } from "react";
import Header from "./Header";
import Sidebar from "./Sidebar";
import "../App.css";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faCircleCheck,
  faArrowRight,
} from "@fortawesome/free-solid-svg-icons";
import toast, { Toaster } from "react-hot-toast";
import { BASE_URL } from "../config";
import DataSpinners from "../Spinner";
import { useLocation } from "react-router-dom";
import { useSelector } from 'react-redux';


const ImagesPerPage = 6;

const Profile = () => {
  // State to keep track of the current mode
  const [isDarkMode, setIsDarkMode] = useState(true);
  const [spinner, setSpinner] = useState(false);




  const [searchQuery, setSearchQuery] = useState("");
  const [filteredProfiles, setFilteredProfiles] = useState([]);
  const location = useLocation();
  const creatorEmail = useSelector((state) => state.admin_email);


  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const searchParam = queryParams.get('search');

    console.log('searchParam', searchParam)
    if (searchParam) {
      setSearchQuery(searchParam);
    }
  }, [location.search]);



  // Function to toggle dark mode
  const toggleDarkMode = () => {
    setIsDarkMode(!isDarkMode);
  };

  // Open particular profile details
  const [isOpenDetails, setIsOpenDetails] = useState(false);
  // const handleOpenDetails = () => {
  //     setOpenDetails(!isOpenDetails);
  // }

  const [openDetailsForProfile, setOpenDetailsForProfile] = useState(null);

  // const handleOpenDetails = (kitNumber)=>() => {
  //     setOpenDetailsForProfile(kitNumber);
  // };
  const handleOpenDetails = (kitNumber) => () => {
    setIsOpenDetails(!isOpenDetails);
    setOpenDetailsForProfile(kitNumber);
  };




  const [userProfiles, setUserProfiles] = useState([]);
  const [formPopupData, setFormPopupData] = useState(false);
  useEffect(() => {
    const fetchUserProfiles = async () => {
      try {
        setSpinner(true);
        console.log("Fecthed email", creatorEmail);
        const response = await axios.get(`${BASE_URL}/api/get_user_profiles_data`, {
          params: { creatorEmail },
        });


        if (response.data.status === "success") {
          setUserProfiles(response.data.userProfiles);
          setSpinner(false);
        } else {
          console.error("Failed to fetch user profiles:", response.data.error);
          setSpinner(false);
        }
      } catch (error) {
        setSpinner(false);
        console.error(
          "Error fetching user profiles:",
          error.response.data.error
        );
      }
    };

    fetchUserProfiles();
  }, []);


  useEffect(() => {
    const filterProfiles = () => {
        if (searchQuery) {
            const lowercasedQuery = searchQuery.toLowerCase();
            const filtered = userProfiles.filter(profile => 
                (profile.name && profile.name.toLowerCase().includes(lowercasedQuery)) ||
                (profile.email && profile.email.toLowerCase().includes(lowercasedQuery)) ||
                (profile.wallet_address && profile.wallet_address.toLowerCase().includes(lowercasedQuery)) ||
                (profile.kitNumber && profile.kitNumber.toLowerCase().includes(lowercasedQuery)) 

                
            );
            setFilteredProfiles(filtered);
        } else {
            setFilteredProfiles(userProfiles);
        }
    };
    filterProfiles();
}, [searchQuery, userProfiles]);

  console.log("user profiles", userProfiles);

  const [currentPage, setCurrentPage] = useState(1);
  const [entitiesPerPage, setEntitiesPerPage] = useState(5);

  const indexOfLastEntity = currentPage * entitiesPerPage;
  const indexOfFirstEntity = indexOfLastEntity - entitiesPerPage;

  // let currentEntities = userProfiles.slice(
  let currentEntities = filteredProfiles.slice(
    indexOfFirstEntity,
    indexOfLastEntity
  );

  const emptyRowCount = entitiesPerPage - currentEntities.length;

  const handlePrevClick = () => {
    setCurrentPage(currentPage - 1);
  };

  const handleNextClick = () => {
    if (currentEntities.length === entitiesPerPage) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handleDropdownChange = (e) => {
    setEntitiesPerPage(parseInt(e.target.value));
    setCurrentPage(1);
  };
  const profileToDisplay = userProfiles.find(
    (profile) => profile.kitNumber === openDetailsForProfile
  );
  console.log("profileToDisplay", profileToDisplay);

  const handleCloseDetails = () => () => {
    setIsOpenDetails(!isOpenDetails);
    setOpenDetailsForProfile(null);
  };

  const handleFormClick = async (formType, kitNumber) => {
    try {
      const profile = userProfiles.find(
        (profile) => profile.kitNumber === kitNumber
      );
      if (!profile) {
        console.error("Profile not found for kit number:", kitNumber);
        return;
      }

      console.log("profile", profile, formType);
      const { email } = profile;

      const response = await axios.post(`${BASE_URL}/api/check-access`, {
        kitNumber,
        from_email: "ADMIN",
        to_email: email,
        formType,
      });
      console.log("response", response);
      const { view_status } = response.data;
      console.log("view_status", view_status);

      if (view_status === "1") {
        console.log("view_status 1");
        window.open(profile[formType], "_blank");
      } else {
        setFormPopupData(true);
        console.log("view_status 0");
        setFormPopupData({ formUrl: profile[formType], formType });
      }
    } catch (error) {
      console.error("Error:", error.message);
    }
  };

  const handleSendRequestAccess = async (kitNumber, formType) => {
    try {
      console.log(
        "Sending request for access with kitNumber:",
        kitNumber,
        formType
      );

      const profile = userProfiles.find(
        (profile) => profile.kitNumber === kitNumber
      );

      if (profile) {
        const { email } = profile;
        console.log("email", email, formType);

        const response = await axios.post(`${BASE_URL}/api/request-access`, {
          kitNumber,
          from_email: "ADMIN",
          to_email: email,
          formType,
        });
        console.log("response", response);
        // console.log("email", email)
        if (response.data.status === "success") {
          console.log("Access request sent successfully");

          setFormPopupData(false);
        } else {
          console.error("Failed to send access request:", response.data.error);
          toast.error(response.data.error);
          setFormPopupData(false);
        }
      } else {
        console.error("Profile not found for kit number:", kitNumber);
      }
    } catch (error) {
      console.error("Error sending request:", error.message);
    }
  };

  return (
    <div className={`relative ${isDarkMode ? "dark" : "light"}`}>
      <Toaster />
      <div className="">
        <img
          src="../assets/Ellipse 271.png"
          className=" absolute bottom-0 right-0 "
        />
      </div>

      <Header toggleDarkMode={toggleDarkMode} isDarkMode={isDarkMode} />

      <div
        className={`flex min-h-screen font-inter pt-5 ${isDarkMode ? "bg-[#05295A]" : "bg-white"
          } `}
      >
        <Sidebar isDarkMode={isDarkMode} />
        <div className="lg:w-[80%] w-full relative">
          <div className="lg:mx-10 mx-5 mt-10">
            <div
              className={`${isDarkMode ? "bg-white/10" : "bg-[#3ab7ff28]"
                } w-full rounded-md`}
            >
              <p
                className={` ${isDarkMode ? "text-white" : "text-black"
                  } text-end 2xl:text-base text-xs px-3 2xl:py-10 py-2`}
              >
                Show no of entity
                <select
                  className="ml-2 outline-none rounded-md bg-transparent border-[1px]"
                  onChange={handleDropdownChange}
                  value={entitiesPerPage}
                >
                  <option className="text-black" value="5">
                    5
                  </option>
                  <option className="text-black" value="10">
                    10
                  </option>
                  <option className="text-black" value="50">
                    50
                  </option>
                </select>
              </p>
              <div
                className={`   overflow-hidden overflow-x-scroll overflow-y-scroll`}
              >
                <table className="w-full">
                  <thead className="">
                    <tr
                      className={`border-b border-white/40 2xl:text-2xl ${isDarkMode ? "text-[#C8C2E8]" : "text-black"
                        } `}
                    >
                      <th className="md:px-5 px-20 2xl:text-lg text-base py-5 text-center">
                        S.NO
                      </th>
                      <th className="md:px-5 px-20 2xl:text-lg text-base py-5 text-center">
                        Email
                      </th>
                      <th className="md:px-5 px-20 2xl:text-lg text-base py-5 text-center">
                        Wallet address
                      </th>
                      <th className="md:px-5 px-20 2xl:text-lg text-base py-5 text-center">
                        View Profile
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentEntities.length === 0 ? (
                      <tr>
                        <td
                          colSpan="4"
                          className={`text-center py-5 font-bold ${isDarkMode ? "text-white" : "text-black"
                            }`}
                        >
                          No data found
                        </td>
                      </tr>
                    ) : (
                      currentEntities.map((data, index) => (
                        <tr
                          key={index}
                          className={`${isDarkMode ? "text-white/75" : "text-black"
                            }`}
                        >
                          <td className="text-sm px-5 text-center 2xl:py-5 py-3 whitespace-nowrap">
                            {indexOfFirstEntity + index + 1}
                          </td>
                          <td className="text-sm px-5 text-center 2xl:py-5 py-3 whitespace-nowrap">
                            {data.email}
                          </td>
                          <td className="text-sm px-5 text-center 2xl:py-5 py-3 font-semibold whitespace-nowrap">
                            {data.wallet_address}
                          </td>
                          <td className="text-sm px-5 text-center 2xl:py-5 py-3 font-semibold whitespace-nowrap">
                            <a href={`/Admin/viewProfile?id=${data.id}`}>
                              View Profile
                            </a>
                            {/* <button onClick={handleOpenDetails(data.kitNumber)} className=' cursor-pointer ' >View Profile</button> */}
                          </td>
                        </tr>
                      ))
                    )}
                    {/* {Array.from({ length: emptyRowCount }).map((_, index) => (
                      <tr key={`empty-${index}`} className="h-14">
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                      </tr>
                    ))} */}
                  </tbody>
                </table>
              </div>
            </div>
            <div className=" flex justify-between text-xs px-10 pt-5">
              <button
                className="bg-white/30 cursor-pointer rounded-md px-5 py-1"
                onClick={handlePrevClick}
                disabled={currentPage === 1}
              >
                <FontAwesomeIcon icon={faArrowLeft} />
              </button>
              <button
                className="bg-white/30  cursor-pointer rounded-md px-5 py-1"
                onClick={handleNextClick}
                disabled={currentEntities.length < entitiesPerPage}
              >
                <FontAwesomeIcon icon={faArrowRight} />
              </button>
            </div>
          </div>

          {isOpenDetails && (
            <div
              className={`absolute top-0 right-0  w-full h-full z-30 overflow-hidden overflow-y-auto md:overflow-hidden ${isDarkMode ? "bg-[#05295A] text-white" : "bg-white text-black"
                } rounded-md p-10 ' data-aos='fade-zoom-in`}
            >
              <FontAwesomeIcon
                icon={faArrowLeft}
                onClick={() => setIsOpenDetails(false)}
              ></FontAwesomeIcon>
              <div className="flex md:items-center w-[100%] overflow-hidden overflow-y-auto">
                <div className="flex flex-col md:flex-row gap-5 md:gap-0 w-[100%]">
                  <div className="md:w-[40%]">
                    <div className="flex justify-center">
                      <img
                        src="/assets/Group 427320849.png"
                        className="md:w-[80%]"
                      ></img>
                    </div>
                    <p className="text-center py-3">My Profile</p>
                    <div className="py-5">
                      <p className="flex justify-between w-[80%] mx-auto">
                        <p>Admin Access : </p>
                        <p className="text-[#1EEF32] text-semibold">
                          Approved
                          <FontAwesomeIcon
                            icon={faCircleCheck}
                            className=" pl-1"
                          ></FontAwesomeIcon>
                        </p>
                      </p>
                      <p className="flex justify-between w-[80%] mx-auto mt-5">
                        <p>Access Expiry :</p>
                        <p className="text-[#1EEF32] text-semibold">
                          16-05-2024
                        </p>
                      </p>
                    </div>
                    {/* <div className='flex flex-col md:flex-row gap-3 md:gap-6 pt-5 justify-center text-sm font-semibold'>
                                <button className='mx-auto md:mx-0 px-3 py-3 rounded-full border flex gap-2'><div><img src='/assets/Vector.png'></img></div> Share Profile</button>
                                <button className='mx-auto md:mx-0 px-3 py-3 rounded-full border flex gap-2'><div><img src='/assets/Vector2.png'></img></div> Delete Profile</button>
                                </div> */}
                  </div>
                  <div className="md:w-[60%]">
                    <div
                      className={`md:w-[90%] mx-auto ${isDarkMode ? "text-white" : "text-black"
                        } border md:h-[90%]`}
                    >
                      <h1 className="text-center underline text-3xl py-5">
                        Profile
                      </h1>
                      <div className="py-5 md:py-0 px-5 flex flex-col gap-5 text-sm md:text-md">
                        <p className="flex justify-between">
                          <p
                            className={`w-[50%] ${isDarkMode ? "text-white" : "text-black"
                              } `}
                          >
                            Kit Number :{" "}
                          </p>
                          <p className="w-[50%]">
                            {profileToDisplay?.kitNumber}
                          </p>
                        </p>
                        <p className="flex justify-between">
                          <p
                            className={`w-[50%] ${isDarkMode ? "text-white" : "text-black"
                              } `}
                          >
                            Mail Id :{" "}
                          </p>
                          <p className="w-[50%]">{profileToDisplay?.email}</p>
                        </p>
                        <p className="flex justify-between">
                          <p
                            className={`w-[50%] ${isDarkMode ? "text-white" : "text-black"
                              } `}
                          >
                            Status :{" "}
                          </p>
                          <p className="w-[50%]">{profileToDisplay?.status}</p>
                        </p>
                        {/* <p className='flex justify-between'><p className={`w-[50%] ${isDarkMode ? 'text-white' : 'text-black' } `}>Ownership : </p><p className='w-[50%]'>Transfered</p></p> */}
                        <p className="flex justify-between">
                          <p
                            className={`w-[50%] ${isDarkMode ? "text-white" : "text-black"
                              } `}
                          >
                            Order Form :{" "}
                          </p>
                          <p
                            className="w-[50%] underline"
                            onClick={() =>
                              handleFormClick(
                                "orderForm",
                                profileToDisplay?.kitNumber
                              )
                            }
                          >
                            Form-psy-OF019
                          </p>
                        </p>
                        <p className="flex justify-between">
                          <p
                            className={`w-[50%] ${isDarkMode ? "text-white" : "text-black"
                              } `}
                          >
                            Consent Form :{" "}
                          </p>
                          <p
                            className="w-[50%] underline"
                            onClick={() =>
                              handleFormClick(
                                "consentForm",
                                profileToDisplay?.kitNumber
                              )
                            }
                          >
                            Form-psy-OF019
                          </p>
                        </p>
                        <p className="flex justify-between">
                          <p
                            className={`w-[50%] ${isDarkMode ? "text-white" : "text-black"
                              } `}
                          >
                            DNA Raw Data :{" "}
                          </p>
                          <p
                            className="w-[50%] underline"
                            onClick={() =>
                              handleFormClick(
                                "dnaRawData",
                                profileToDisplay?.kitNumber
                              )
                            }
                          >
                            Form-psy-OF019
                          </p>
                        </p>
                        <p className="flex justify-between">
                          <p
                            className={`w-[50%] ${isDarkMode ? "text-white" : "text-black"
                              } `}
                          >
                            Genetic Report :{" "}
                          </p>
                          <p
                            className="w-[50%] underline"
                            onClick={() =>
                              handleFormClick(
                                "genericReport",
                                profileToDisplay?.kitNumber
                              )
                            }
                          >
                            Form-psy-OF019
                          </p>
                        </p>
                        <p className="flex justify-between">
                          <p
                            className={`w-[50%] ${isDarkMode ? "text-white" : "text-black"
                              } `}
                          >
                            Blood Report :{" "}
                          </p>
                          <p
                            className="w-[50%] underline"
                            onClick={() =>
                              handleFormClick(
                                "bloodReport",
                                profileToDisplay?.kitNumber
                              )
                            }
                          >
                            Form-psy-OF019
                          </p>
                        </p>
                        {/* <p className='flex justify-between'><p className={`w-[50%] ${isDarkMode ? 'text-white' : 'text-black' } `}>Order Form : </p><p className='w-[50%] underline' onClick={() => window.open(profileToDisplay?.orderForm.replace("../client/public", ""), "_blank")}>
                                Form-psy-OF019 </p></p>
                                <p className='flex justify-between'><p className={`w-[50%] ${isDarkMode ? 'text-white' : 'text-black' } `}>Consent Form : </p><p className='w-[50%] underline' onClick={() => window.open(profileToDisplay?.consentForm.replace("../client/public", ""), "_blank")}>Form-psy-OF019</p></p>
                                <p className='flex justify-between'><p className={`w-[50%] ${isDarkMode ? 'text-white' : 'text-black' } `}>DNA Raw Data : </p><p className='w-[50%] underline' onClick={() => window.open(profileToDisplay?.dnaRawData.replace("../client/public", ""), "_blank")}>Form-psy-OF019</p></p>
                                <p className='flex justify-between'><p className={`w-[50%] ${isDarkMode ? 'text-white' : 'text-black' } `}>Genetic Report : </p><p className='w-[50%] underline' onClick={() => window.open(profileToDisplay?.genericReport.replace("../client/public", ""), "_blank")}>Form-psy-OF019</p></p>
                                <p className='flex justify-between'><p className={`w-[50%] ${isDarkMode ? 'text-white' : 'text-black' } `}>Blood Report : </p><p className='w-[50%] underline' onClick={() => window.open(profileToDisplay?.bloodReport.replace("../client/public", ""), "_blank")} >Form-psy-OF019</p></p> */}
                      </div>
                    </div>
                    <div className="flex justify-end">
                      <img
                        src="/assets/As1.png"
                        className="-translate-y-[1rem] md:-translate-y-[4rem] md:w-[8rem]"
                      ></img>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      {profileToDisplay && formPopupData && (
        <div className="fixed inset-0 flex items-center justify-center z-50">
          <div className="fixed inset-0 bg-gray-900 bg-opacity-50"></div>
          <div className="relative bg-white rounded-lg p-8 shadow-lg">
            <h2 className="text-2xl font-bold mb-4">Request Access</h2>
            <p className="text-gray-700 mb-6">
              You need permission to access this form.
            </p>
            <div className="flex justify-end">
              <button
                onClick={() =>
                  handleSendRequestAccess(
                    profileToDisplay?.kitNumber,
                    formPopupData.formType
                  )
                }
                className="bg-blue-500 text-white px-4 py-2 rounded mr-2"
              >
                Send Request for Access
              </button>
              <button
                onClick={() => setFormPopupData(null)}
                className="bg-gray-300 text-gray-700 px-4 py-2 rounded"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}

      {spinner ? <DataSpinners /> : ""}
    </div>
  );
};

export default Profile;