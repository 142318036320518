

import React, { useState } from 'react';
import { faEye, faEyeSlash, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { BASE_URL } from './config';
import toast, { Toaster } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

const PasswordReset = () => {
  const [newpassword, setNewPassword] = useState("");
  const [confirmpassword, setConfirmPassword] = useState("");
  const [visible, setVisible] = useState(false);
  const [visible1, setVisible1] = useState(false);
  const [errors, setErrors] = useState({ newpassword: "", confirmpassword: "" });
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleNewPasswordVisibility = () => {
    setVisible(!visible);
  };

  const handleConfirmPasswordVisibility = () => {
    setVisible1(!visible1);
  };

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent default form submission
  
    // Validate fields
    const newpasswordError = newpassword ? "" : "Please provide your new password";
    const confirmpasswordError = confirmpassword ? "" : "Please confirm your password";
  
    setErrors({
      newpassword: newpasswordError,
      confirmpassword: confirmpasswordError,
    });
  
    if (newpasswordError || confirmpasswordError) {
      return;
    }
  
    if (newpassword !== confirmpassword) {
      toast.error("Passwords do not match.");
      return;
    }
  
    const passwordPattern = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
    if (!passwordPattern.test(newpassword)) {
      toast.error("Password must contain at least one number, one letter, and one special character.");
      return;
    }
  
    setLoading(true); 
  
    try {
      const token = new URLSearchParams(window.location.search).get("token");
      console.log('Token:', token); // Debugging line
  
      const response = await fetch(`${BASE_URL}/api/reset-password-verification`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ token, newpassword }),
      });
  
      const data = await response.json();
      console.log('Response data:', data); // Debugging line
  
      if (response.ok && data.status === "success") {
        toast.success("Password reset successful. ");
        setTimeout(() => {
          navigate("/");
        }, 2000);
      } else if (data.message === "Password must contain at least one number, one letter, and one special character") {
        toast.error("Password must contain at least one number, one letter, and one special character.");
      } else if (data.message === "Verification link has expired") {
        toast.error("Verification link has expired.");
      } else if (data.error === "Invalid token format") {
        toast.error(" Please use a valid verification link.");
      } else {
        toast.error("Failed to reset password. Please try again.");
      }
    } catch (error) {
      console.error("Error during password reset:", error.message);
      toast.error("An error occurred. Please try again.");
    } finally {
      setLoading(false); 
    }
  };
  
  return (
    <div className='font-poppins bg-cover min-h-screen py-10 bg-no-repeat text-white flex justify-center items-center' style={{ backgroundImage: 'url("/assets/bg3.png")' }}>
      <Toaster />
      <div className='lg:w-[50%] md:w-[70%] w-[95%] mx-auto bg-white/25 border border-[#E4E4E4BF] rounded-2xl p-10'>
        <p className='md:text-4xl text-3xl font-bold'>Password Reset</p>
        <div className='md:py-10 py-5'>
          <div className="w-[80%] mx-auto mt-5 relative">
            <input
              type={visible ? "text" : "password"}
              placeholder="New Password"
              className='bg-transparent focus:outline-none border rounded-lg w-full px-4 py-1.5 md:text-base text-sm'
              value={newpassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
            <button
              onClick={handleNewPasswordVisibility}
              className="absolute top-0 right-0 flex justify-center items-center h-[100%] w-[60px]"
            >
              <FontAwesomeIcon icon={visible ? faEye : faEyeSlash} size="sm" />
            </button>
          </div>
          {errors.newpassword && <p className="w-[80%] mx-auto text-red-500 text-xs">{errors.newpassword}</p>}

          <div className="w-[80%] mx-auto mt-5 relative">
            <input
              type={visible1 ? "text" : "password"}
              placeholder="Confirm Password"
              className='bg-transparent focus:outline-none border rounded-lg w-full px-4 py-1.5 md:text-base text-sm'
              value={confirmpassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
            <button
              onClick={handleConfirmPasswordVisibility}
              className="absolute top-0 right-0 flex justify-center items-center h-[100%] w-[60px]"
            >
              <FontAwesomeIcon icon={visible1 ? faEye : faEyeSlash} size="sm" />
            </button>
          </div>
          {errors.confirmpassword && <p className="w-[80%] mx-auto text-red-500 text-xs">{errors.confirmpassword}</p>}

          <div className='flex justify-center items-center mt-10'>
            <button
              type="submit"
              onClick={handleSubmit}
              className="px-10 md:px-20 py-3 text-sm font-bold rounded-xl bg-gradient-to-br from-[#3AB6FF] via-[#004696] to-[#FF3131] to-80%"
              disabled={loading} 
            >
              {loading ? (
                <FontAwesomeIcon icon={faSpinner} spin className="mr-2" /> 
              ) : (
                "Reset Password"
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PasswordReset;
