


import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { BASE_URL } from './config';
import toast, { Toaster } from "react-hot-toast";

const ViewuserReports = () => {
    const [isDarkMode, setIsDarkMode] = useState(true);
    const [Nft, setNft] = useState([]);
    const user_email = useSelector(state => state.user_email);
    console.log(user_email);

    useEffect(() => {
        const getRecords = async () => {
            const searchParams = new URLSearchParams(window.location.search);
            const user_id = searchParams.get('id');
            const document = searchParams.get('document_type');
            const formData = {
                userId: user_id,
                documentType: document
            }
            try {
                const response = await axios.post(`${BASE_URL}/api/get_reports`, formData);
                console.log("dataaaaaAAAAAAAAAA", response.data.data);
                setNft(response.data.data);
            } catch (error) {
                console.error('Error fetching records:', error.message);
                toast.error('Failed to fetch records.');
            }
        }
        getRecords();
    }, []);

    const toggleDarkMode = () => {
        setIsDarkMode(!isDarkMode);
    };

    return (
        <div className={`relative ${isDarkMode ? 'dark' : 'light'}`}>
            <Toaster />
            <div className="">
                <img src="../assets/Ellipse 271.png" className="absolute bottom-0 right-0" />
            </div>

            <div className={`flex min-h-screen font-inter pt-5 ${isDarkMode ? 'bg-[#05295A]' : 'bg-white'}`}>
                <div className='lg:w-[70%] mx-auto z-10 relative'>
                    <div className='h-screen md:h-full overflow-hidden overflow-y-auto md:overflow-hidden'>
                        <div className={` ${isDarkMode ? 'bg-white/25' : 'bg-[#3ab7ff28]'} rounded-lg py-5 cursor-pointer`}>
                            <div className='w-full'>
                                <div className='flex flex-wrap justify-center items-center gap-5'>
                                    {Nft.map((data, index) => (
                                        <div key={index} className='bg-white py-2 rounded-lg flex flex-col justify-center items-center lg:w-[24%]'>
                                            <img src='../assets/Group 427320861.png' alt='Nft' className='sm:w-10/12 w-8/12' />
                                            <p className='text-sm text-[#000000A6]'>{data.kitnumber}</p>
                                            <a href={data.document_url.replace("../client/public/", "/")} target="_blank" rel="noopener noreferrer" className='text-white text-center bg-[#4A3AFF] rounded-xl font-bold w-full py-3'>
                                                {`View ${data.document_type} Document`}
                                            </a>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ViewuserReports;
