import React, { useState, useEffect } from "react";
import { useLocation, Link } from "react-router-dom";
import "../App.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faXmark } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { setAdminEmail } from "../store";
import toast, { Toaster } from "react-hot-toast";
import axios from "axios";
import { BASE_URL } from "../config";

const Sidebar = ({ isDarkMode }) => {
  const count = useSelector((state) => state.adminNotificationCount);

  const SideMenu = [
    { img: "../assets/Vector (5).png", name: "Profiles", link: "/Admin/Profile" },
    { img: "../assets/Vector (5).png", name: "Doctor Profiles", link: "/Admin/DoctorProfile" },
    { img: "../assets/Vector (6).png", name: "Wallet", link: "/Admin/Wallet" },
    { img: "../assets/Vector (8).png", name: "Create Profile", link: "/Admin/CreateProfile" },
    { img: "../assets/Vector (9).png", name: "Notifications", link: "/Admin/Notifications" },
    { img: "../assets/upload.png", name: "Upload Document", link: "/Admin/Uploaddocument" },
    { img: "../assets/delete.png", name: "Delete Profile", link: "/Admin/DeleteProfile" },
    { img: "../assets/pass.png", name: "Change Password", link: "/Admin/ChangePassword" },
    { img: "../assets/Vector (8).png", name: "Sub Admin", link: "/Admin/SubAdmin" },
    { img: "../assets/Vector (5).png", name: "Sub Admin Profile", link: "/Admin/SubAdminProfile" },
  ];

  const [isAdmin, setIsAdmin] = useState(false);
  const [showHeader, setShowHeader] = useState(true);
  
  const [selectedLink, setSelectedLink] = useState("");
  
  const adminEmail = useSelector((state) => state.admin_email);
  const [adminsData, setAdminsData] = useState([]);
  const [allowedMenus, setAllowedMenus] = useState([]);

  const location = useLocation();
  const dispatch = useDispatch();

  // Fetch admin data on component mount
  useEffect(() => {
    const fetchUserProfiles = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/api/admin/get_admins_data`);
        if (response.data.status === "success") {
          setAdminsData(response.data.adminsData);
        } else {
          console.error("Failed to fetch user profiles:", response.data.error);
        }
      } catch (error) {
        console.error("Error fetching user profiles:", error.response?.data?.error || error.message);
      }
    };

    fetchUserProfiles();
  }, []);

  useEffect(() => {
    const currentAdmin = adminsData.find((admin) => admin.email === adminEmail);
    if (currentAdmin) {
      const isAdminRole = currentAdmin.role === "admin";
      setIsAdmin(isAdminRole);

      if (currentAdmin.role === "sub_admin") {
        setAllowedMenus([
          "Profiles",
          "Create Profile",
          "Delete Profile",
          "Notifications",
          "Upload Document",
          "Change Password",
          "Wallet"
        ]);
      }
    }
  }, [adminEmail, adminsData]);

  // Set selectedLink based on the current path using useLocation
  useEffect(() => {
    setSelectedLink(location.pathname);
  }, [location.pathname]);

  const handleLinkClick = (link) => {
    if (isAdmin || allowedMenus.includes(SideMenu.find((menu) => menu.link === link)?.name)) {
      setSelectedLink(link); // Update selectedLink on click
    } else {
      toast.error("You do not have access to this menu item.");
    }
  };

  const filteredMenu = isAdmin
    ? SideMenu
    : SideMenu.filter((item) => allowedMenus.includes(item.name));

  const Logout = () => {
    dispatch(setAdminEmail(""));
    toast.success("Logged Successfully...Redirecting to Login Page");
    setTimeout(() => {
      window.location.href = "/admin/Login";
    }, 500);
  };

  const toggleHeader = () => {
    setShowHeader(!showHeader);
  };

  return (
    <div className={`lg:w-[20%] ${isDarkMode ? "dark" : "light"}`}>
      <Toaster />
      <div className={`relative font-inter ${isDarkMode ? "bg-[#05295A] text-white" : "bg-white text-black"} min-h-screen`}>
        <div className="">
          <div className={`hidden h-full lg:flex flex-col justify-between items-center ease-in-out duration-500 min-h-screen p-3`}>
            <div>
              <div className="flex justify-center items-center bg-gradient-to-r from-[#3AB6FF] via-[#004696] to-[#FF3131] font-bold rounded-xl py-2 px-5">
                <Link to="/Admin/Dashboard">
                  <p className="font-bold text-sm text-center text-white">
                    {isAdmin ? "ADMIN DASHBOARD" : "SUB ADMIN DASHBOARD"}
                  </p>
                </Link>
              </div>

              <div className="flex justify-center mt-10">
                <ul className="flex flex-col gap-5 z-10 text-sm">
                  {filteredMenu.map((data, index) => (
                    <li key={index}>
                      <Link
                        to={data.link}
                        onClick={() => handleLinkClick(data.link)}
                        className={`w-full flex items-center gap-5 rounded-xl py-2 px-5 ${
                          selectedLink === data.link
                            ? "bg-gradient-to-r from-[#3AB6FF] via-[#004696] to-[#FF3131] font-bold text-white"
                            : ""
                        }`}
                      >
                        <img src={data.img} className="size-4" alt={data.name} />
                        <p>{data.name}</p>
                        {data.name === "Notifications" && count > 0 && (
                          <span className="ml-2 bg-red-500 text-white rounded-full w-6 h-6 flex items-center justify-center text-xs">
                            {count}
                          </span>
                        )}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div
              onClick={Logout}
              className="flex cursor-pointer justify-center items-center z-30 gap-5 my-10 transition duration-300 ease-in-out transform hover:scale-105"
            >
              <img src="../assets/logout-1 1.png" className="w-5" alt="Logout" />
              <p className="text-sm font-semibold">Log Out</p>
            </div>
          </div>
          <div className="">
            <img src="../assets/Ellipse 277.png" className="absolute bottom-0 left-0" alt="Ellipse" />
          </div>

          <div
            onClick={toggleHeader}
            className="absolute top-3 sm:right-0 md:-right-5 right-5 block md:hidden"
          >
            {showHeader ? (
              <FontAwesomeIcon icon={faBars} />
            ) : (
              <FontAwesomeIcon icon={faXmark} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
