import { faBell } from '@fortawesome/free-regular-svg-icons'
import { faBars, faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useRef, useState } from 'react'
import AOS from 'aos';
import 'aos/dist/aos.css';
import {useDispatch} from 'react-redux'
import { useSelector } from 'react-redux';
import { setUserEmail } from './store';
import toast, { Toaster } from "react-hot-toast";
import { useLocation, useNavigate } from 'react-router-dom';


AOS.init();

export default function UserHeader() {


    const userEmail = useSelector((state) => state.user_email);
    console.log("user email", userEmail)


    const navigate = useNavigate()
  
    const dispatch = useDispatch()
    const Logout =()=> {
      dispatch(setUserEmail(''));
      toast.success('Logged Out Successfully...Redirecting to Login Page')
      setTimeout(()=>{
        //  window.location.href="/userLogin"
     navigate("/userLogin")
      },500)
      
    }
    const dropdownRef = useRef(null);
    const [isServices, setServices] = useState(false);
    const [isMyProfile, setMyProfile] = useState(false);
    const [isDropdown, setDropdown] = useState(false);

    
    const handleServices = () => {
        setServices(!isServices)
    }
    const handleMyProfile = () => {
        setMyProfile(!isMyProfile);
    }
    const handleDropdown = () => {
        setDropdown(!isDropdown)
    }
    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setServices(false);
                setMyProfile(false);
                setDropdown(false);
            }
        };

        document.addEventListener('mousedown', handleOutsideClick);

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, []);


  return (
    <div className='font-poppins text-white'>
    <Toaster/>
            <div className='flex justify-between py-5 px-5 md:px-10'>
                <div>
                    <img src='../assets/Darklogo.png' className='w-[60%] md:w-auto'></img>
                </div>
               
                <div className='flex gap-3'>
                    <div><button className='py-3 rounded-full border-2 border-white font-bold px-10 text-sm hidden md:block' onClick={()=>Logout()}>Log Out</button></div>
                   
                {/*  <div><button className='text-xl block md:hidden' onClick={handleDropdown}><FontAwesomeIcon icon={faBars}></FontAwesomeIcon></button></div>
                   
                   {isDropdown && (
                        <div ref={dropdownRef} className='dropdown-content text-black z-50 w-[10rem] absolute right-5 top-[4rem]' data-aos="fade-up" >
                            <div className='flex flex-col gap-3 border-2 text-center bg-white rounded-lg p-3'>
                                <a href=''>Home</a>
                                <a href=''>About us</a>
                                <a className='cursor-pointer' onClick={handleServices}>Services <FontAwesomeIcon icon={faChevronDown} className='pl-1 pb-0.5 text-xs'></FontAwesomeIcon></a>
                                {isServices && (
                                    <div ref={dropdownRef} className='dropdown-content z-10 w-[7.5rem] absolute right-[80%] top-24 ' data-aos="fade-zoom-in">
                                        <div className='flex flex-col text-center gap-3 bg-white border rounded-lg p-5'>
                                            <a href=''>content 1</a>
                                            <a href=''>content 2</a>
                                            <a href=''>content 3</a>
                                        </div>
                                    </div>
                                )}
                                <a className='cursor-pointer' onClick={handleMyProfile}>My Profile<FontAwesomeIcon icon={faChevronDown} className='pl-1 pb-0.5 text-xs'></FontAwesomeIcon></a>
                                {isMyProfile && (
                                    <div ref={dropdownRef} className='dropdown-content z-10 w-[7.5rem] absolute right-[80%] top-20 ' data-aos="fade-zoom-in">
                                        <div className='flex flex-col text-center gap-3 bg-white border rounded-lg p-5'>
                                            <a href=''>part 1</a>
                                            <a href=''>part 2</a>
                                            <a href=''>part 3</a>
                                        </div>
                                    </div>
                                )}
                                <div className='flex justify-center'>
                                    <button className='py-1 rounded-full border-2 border-black font-bold px-3 text-sm' onClick={()=>Logout()}>Log Out</button>
                                </div>
                            </div>
                        </div>
                    )} */}
                </div>
            </div>
        </div>
  )
}