import React, { useState, useEffect } from "react";
import "../App.css";
import { useLocation, useNavigate } from "react-router-dom";
import {useDispatch, useSelector} from 'react-redux';
import toast, { Toaster } from "react-hot-toast";
import { setAdminEmail } from "../store";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faXmark,
  faSun,
  faMoon,
  faSliders,
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios"
import { BASE_URL } from "../config";

const Header = ({ toggleDarkMode, isDarkMode }) => {
  const [showHeader, setShowHeader] = useState(true);

  const toggleHeader = () => {
    setShowHeader(!showHeader);
  };





  const SideMenu = [
    {
      img: "../assets/Vector (5).png",
      name: "Profiles",
      link: "/Admin/Profile",
    },

    {
      img: "../assets/Vector (5).png",
      name: "Doctor Profile",
      link: "/Admin/DoctorProfile",
    },
    
    {
      img: "../assets/Vector (6).png",
      name: "Wallet",
      link: "/Admin/Wallet",
    },
   
    {
      img: "../assets/Vector (8).png",
      name: "Create Profile",
      link: "/Admin/CreateProfile",
    },
    {
      img: "../assets/Vector (9).png",
      name: "Notifications",
      link: "/Admin/Notifications",
    },
    // {
    //   img: "../assets/Vector (8).png",
    //   name: "Sub Admin",
    //   link: "/Admin/SubAdmin",
    // },
    // {
    //   img: "../assets/Vector (8).png",
    //     name: "Sub Admin Profiles",
    //     link: "/Admin/SubadminProfile",
    //   },
      {
        img: "../assets/upload.png",
        name: "Upload Document",
        link: "/Admin/Uploaddocument",
      },
      {
        img: "../assets/Vector (7).png",
        name: "Delete Profile",
        link: "/Admin/DeleteProfile",
      },
  ];

  const placeholder = () => {
    switch (location.pathname) {
      case "/Admin/Profile":
        return "Search Profiles";
        case "/Admin/DoctorProfile":
          return "Search DoctorProfile";
      case "/Admin/Wallet":
        return "Search Wallet";
      case "/Admin/DeleteProfile":
        return "Search Deleted Profiles";
      case "/Admin/CreateProfile":
        return "Search Created Profiles";
      case "/Admin/Notifications":
        return "Search Notifications";
      case "/Admin/SubAdmin":
        return "Search SubAdmin";
      case "/Admin/SubadminProfile":
        return "Search SubAdminProfile";
      case "/Admin/Uploaddocument":
        return "Search Upload Document";
      default:
        return "Search Dashboard";
    }
  };

  const adminEmail = useSelector((state) => state.admin_email);

  const [adminsData, setAdminsData] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false);








  useEffect(() => {
    const fetchUserProfiles = async () => {
      try {
        const response = await axios.get(
          `${BASE_URL}/api/admin/get_admins_data`
        );
        if (response.data.status === "success") {
          setAdminsData(response.data.adminsData);
        } else {
          console.error("Failed to fetch user profiles:", response.data.error);
        }
      } catch (error) {
        console.error(
          "Error fetching user profiles:",
          error.response.data.error
        );
      }
    };

    fetchUserProfiles();
  }, []);


  useEffect(() => {
    const checkAdminStatus = () => {
      const isAdmin = adminsData.some((admin) => admin.email === adminEmail && admin.role === "admin");
      setIsAdmin(isAdmin);
    };

    checkAdminStatus();
  }, [adminEmail, adminsData]);

 
    if (isAdmin) {
      SideMenu.push(
        {
          img: "../assets/Vector (8).png",
          name: "Sub Admin",
          link: "/Admin/SubAdmin",
        },
        {
          img: "../assets/Vector (5).png",
          name: "Sub Admin Profiles",
          link: "/Admin/SubadminProfile",
        }
      );
    }



  const navigation = () => {
    switch (location.pathname) {
      case "/Admin/Profile":
        return "Profile";
        case "/Admin/DoctorProfile":
          return "Doctor Profile";
      case "/Admin/Wallet":
        return "Wallet";
      case "/Admin/DeleteProfile":
        return "Delete Profile";
      case "/Admin/CreateProfile":
        return "Create Profile";
      case "/Admin/Notifications":
        return "Notifications";
      case "/Admin/SubAdmin":                      
        return "SubAdmin";
      case "/Admin/SubadminProfile":                      
        return "SubAdminProfile";
      case "/Admin/Uploaddocument":
        return "Upload Document";
      default:
        return "";
    }
  };


  const navigate = useNavigate();

  const [searchQuery, setSearchQuery] = useState("");


  const handleSearch = (e) => {
    e.preventDefault();
    if (searchQuery.trim() !== "") {
      switch (location.pathname) {
        case "/Admin/Profile":
          navigate(`/Admin/Profile?search=${searchQuery}`);
          break;
          case "/Admin/DoctorProfile":
            navigate(`/Admin/DoctorProfile?search=${searchQuery}`);
            break;
        case "/Admin/Wallet":
          navigate(`/Admin/Wallet?search=${searchQuery}`);
          break;
          case "/Admin/DeleteProfile":
              navigate(`/Admin/DeleteProfile?search=${searchQuery}`);
          break;

          case "/Admin/CreateProfile":
              navigate(`/Admin/CreateProfile?search=${searchQuery}`);
          break;

          case "/Admin/Notifications":
              navigate(`/Admin/Notifications?search=${searchQuery}`);
          break;
         case "/Admin/SubAdmin":                      
              navigate(`/Admin/SubAdmin?search=${searchQuery}`);
          break;
         case "/Admin/SubadminProfile":                      
              navigate(`/Admin/SubadminProfile?search=${searchQuery}`);
          break;
          ;
          case "/Admin/Uploaddocument":
              navigate(`/Admin/Uploaddocument?search=${searchQuery}`);
          break;
          ;


        default:
          navigate(`/Admin/Dashboard?search=${searchQuery}`);
      }
    } else {
      navigate(location.pathname);
      window.location.reload()
    }
  };





  const dispatch = useDispatch()
  const Logout =()=> {
    dispatch(setAdminEmail(''));
    toast.success('Logged Successfully...Redirecting to Login Page')
    setTimeout(()=>{
        window.location.href="/admin/Login"
    },500)
    
  }

  const location = useLocation();
  const [selectedLink, setSelectedLink] = useState(null);

  const showFilterIcon = location.pathname === "/Admin/Profile";

  useEffect(() => {
    const pathname = location.pathname;
    const index = SideMenu.findIndex((item) => item.link === pathname);
    setSelectedLink(index);
  }, [location.pathname, SideMenu]);

  const handleLinkClick = (index) => {
    setSelectedLink(index);
  };

  return (
    <div className={`${isDarkMode ? "dark" : "light"}`}>
       <Toaster />
    <div
      className={`font-inter  ${
        isDarkMode ? "bg-[#05295A] text-white" : "bg-white text-black"
      } `}
    >
        <div className="py-4">
          <div className="lg:container lg:mx-auto relative ">
            <div className="flex w-[95%]">
              {/* Logo and Welcome */}
              <div className="lg:w-[60%] flex items-center md:gap-10">
              <div className="">
                  {isDarkMode ? (
                    <img
                      src="../assets/Adminlogo.png"
                      className="md:h-12 h-8"
                    />
                  ) : (
                    <img src="../assets/Logo.png" className="md:h-12 h-8 " />
                  )}
                </div>

                {location.pathname === "/Admin/Dashboard" ? (
                  <div className="inline-block px-5">
                    <p className="text-base">
                      Welcome to
                      <span className="font-semibold"> Precisya </span>
                    </p>
                    <p className="text-xs text-[#CDCED0]">
                      Hello <span className="">Admin</span>
                    </p>
                  </div>
                ) : (
                  <div className="inline-block px-5">
                    <p className={`text-xs 2xl:text-base ${isDarkMode ? 'text-[#CDCED0]' : 'text-black' }  flex md:gap-2`}>
                      Admin Dashboard /
                      <span className={`font-bold ${isDarkMode ? 'text-white' : 'text-black' } `}>
                        {navigation()}
                      </span>
                    </p>
                  </div>
                )}
              </div>

              {/* Search bar */}
              <div className="md:w-[40%] hidden lg:flex justify-between items-center gap-10">
                <div>
                  <FontAwesomeIcon
                    icon={isDarkMode ? faMoon : faSun}
                    className="text-yellow-500 text-3xl cursor-pointer"
                    onClick={toggleDarkMode}
                  />
                </div>

                {/* <div className="relative w-full rounded-lg shadow-md shadow-[#3AB6FF]">
                  <input
                    type="text"
                    value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                    placeholder={placeholder()}
                    className="bg-white w-full  p-2.5 text-xs font-semibold rounded-lg pl-16 focus:outline-none text-black "
                  />
                  {showFilterIcon && (
                    <div className="absolute top-0 right-0 flex justify-center items-center h-[100%] md:w-[60px] w-[30px] ">
                      <FontAwesomeIcon
                        icon={faSliders}
                        className="text-gray-500"
                      />
                    </div>
                  )}

                  <div
                    type="text"
                    className="absolute top-0 left-0 flex justify-center items-center h-[100%] md:w-[60px] w-[30px] "
                  >
                    <img src="../assets/searchbox.png" className="w-4" />
                  </div>
                </div> */}





<div className="relative w-full rounded-lg shadow-md shadow-[#3AB6FF]">
                  <form onSubmit={handleSearch}>
                    <input
                      type="text"
                      value={searchQuery}
                      // onChange={handleSearch}

                      onChange={(e) => {{setSearchQuery(e.target.value)}}}
                      placeholder={placeholder()}
                      className="bg-white w-full  p-2.5 text-xs font-semibold rounded-lg pl-16 focus:outline-none text-black"
                    />


                    {/* {showFilterIcon && (
                      <div className="absolute top-0 right-0 flex justify-center items-center h-[100%] md:w-[60px] w-[30px]">
                        <FontAwesomeIcon icon={faSliders} className="text-gray-500" />
                      </div>
                    )} */}
                    <div className="absolute top-0 left-0 flex justify-center items-center h-[100%] md:w-[60px] w-[30px]">
                      <img src="../assets/searchbox.png" className="w-4" />
                    </div>
                  </form>
                </div>








                

                <div className="flex items-center gap-5">
                  {/* <img src="../assets/Frame 427320845.png" className="w-10" /> */}
                  <a href="/Admin/Notifications">
                    <img src="../assets/Frame 427320692.png" className="w-12" />
                  </a>
                </div>
              </div>
            </div>

            {/* Menu bar */}
            <div
              onClick={toggleHeader}
              className="absolute top-1 md:top-3 xl:right-0 right-5 block lg:hidden"
            >
              {showHeader ? (
                <FontAwesomeIcon icon={faBars} />
              ) : (
                <FontAwesomeIcon icon={faXmark} />
              )}
            </div>
            {/* Mobile Responsive */}
            {!showHeader ? (
              <div className={`fixed lg:hidden left-0 top-0 lg:w-[30%] sm:w-[50%] w-[80%] z-30 overflow-hidden overflow-y-auto ${isDarkMode ? 'bg-[#05295A] text-white' : 'bg-white text-black'}  h-full ease-in-out duration-500 z-50`}>
                <div className=" lg:hidden flex justify-center items-center gap-3 sm:p-3 mt-10 sm:mx-5 mx-3">
                <div>
                  <FontAwesomeIcon
                    icon={isDarkMode ? faMoon : faSun}
                    className="text-yellow-500 text-3xl cursor-pointer"
                    onClick={toggleDarkMode}
                  />
                </div>


                  {/* <div className="relative w-full rounded-lg shadow-md shadow-[#3AB6FF]">
                    <input
                      text="text"
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                      placeholder={placeholder()}
                      className="bg-white w-full p-2.5 text-xs font-semibold rounded-lg pl-14 focus:outline-none text-black "
                    />
                    <div
                      type="text"
                      className="absolute top-0 left-0 flex justify-center items-center h-[100%] md:w-[60px] w-[30px] "
                    >
                      <img src="../assets/searchbox.png" className="w-3" />
                    </div>
                  </div> */}



                  <div className="relative w-full rounded-lg shadow-md shadow-[#3AB6FF]">
                  <form onSubmit={handleSearch}>
                    <input
                      type="text"
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                      placeholder={placeholder()}
                      className="bg-white w-full  p-2.5 text-xs font-semibold rounded-lg pl-16 focus:outline-none text-black"
                    />
                    {/* {showFilterIcon && (
                      <div className="absolute top-0 right-0 flex justify-center items-center h-[100%] md:w-[60px] w-[30px]">
                        <FontAwesomeIcon icon={faSliders} className="text-gray-500" />
                      </div>
                    )} */}
                    <div className="absolute top-0 left-0 flex justify-center items-center h-[100%] md:w-[60px] w-[30px]">
                      <img src="../assets/searchbox.png" className="w-4" />
                    </div>
                  </form>
                </div>


                  <div className="">
                    {/* <img src="../assets/Frame 427320845.png" className="w-8" /> */}
                    <a href="/Admin/Notifications">
                      <img
                        src="../assets/Frame 427320692.png"
                        className="w-8"
                      />
                    </a>
                  </div>
                </div>

                <div
                  className={` lg:hidden flex flex-col   min-h-screen p-5  mt-5`}
                >
                  <div className="">
                  <div className="flex justify-center items-center bg-gradient-to-r from-[#3AB6FF] via-[#004696] to-[#FF3131] font-bold rounded-xl py-2 px-5">
              <a href="/Admin/Dashboard"><p className="font-bold text-sm text-center text-white">ADMIN DASHBOARD</p></a>
            </div>

                    <div className=" flex flex-col justify-center items-center mt-10  ">
                      <ul className="flex flex-col gap-5 text-sm overflow-hidden overflow-y-auto">
                        {SideMenu.map((data, index) => (
                          <li key={index}>
                            <a
                              href={data.link}
                              onClick={() => handleLinkClick(index)}
                              className={`flex items-center gap-5 rounded-xl py-2 px-5 ${
                                selectedLink === index
                                  ? "bg-gradient-to-r from-[#3AB6FF] via-[#004696] to-[#FF3131] font-bold text-white "
                                  : ""
                              }`}
                            >
                              <div className="flex items-center gap-5">
                                <img src={data.img} className="size-4" />
                                <p>{data.name}</p>
                              </div>
                            </a>
                          </li>
                        ))}
                      </ul>
                     
                    </div>
                    
                  </div>
                 
                </div>
                <div
              onClick={() => Logout()}
              className="flex cursor-pointer justify-center items-center z-30 gap-5 my-10 transition duration-300 ease-in-out transform hover:scale-105"
            >
              <img src="../assets/logout-1 1.png" className="w-5" />
              <p className="text-sm font-semibold">Log Out</p>
            </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
