
import { legacy_createStore } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const initialState = {
  user_email: '',
  admin_email:'',
  doctor_email:'',
  notificationCount:0,
  userNotificationCount: 0,
  adminNotificationCount: 0

};

const reducer = (state = initialState, action) => {
  switch (action.type) {

    case 'SET_USER_EMAIL':
      return {
        ...state,
        user_email: action.payload,
      };
    
    case 'SET_ADMIN_EMAIL':
        return {
          ...state,
          admin_email: action.payload,
    };

    case 'SET_DOCTOR_EMAIL':
        return {
          ...state,
          doctor_email: action.payload,
    };
    case 'SET_NOTIFICATION_COUNT':
      return {
          ...state,
          notificationCount: action.payload,
      };

      
  

  case 'SET_USER_NOTIFICATION_COUNT':
    return {
      ...state,
      userNotificationCount: action.payload,
    };

  case 'SET_ADMIN_NOTIFICATION_COUNT':
    return {
      ...state,
      adminNotificationCount: action.payload,
    };

  case 'RESET_USER_NOTIFICATION_COUNT':
    return {
      ...state,
      userNotificationCount: 0,
    };

  case 'RESET_ADMIN_NOTIFICATION_COUNT':
    return {
      ...state,
      adminNotificationCount: 0,
    };
  

    case 'RESET_NOTIFICATION_COUNT_FROM_STORAGE':
      return {
        ...state,
        notificationCount: 0,
      };
    

    
    default:
      return state;
  }
};

export const setUserEmail = (email) => ({
  type: 'SET_USER_EMAIL',
  payload: email,
});


export const setAdminEmail = (email) => ({
  type: 'SET_ADMIN_EMAIL',
  payload: email,
});

export const setDoctorEmail = (email) => ({
  type: 'SET_DOCTOR_EMAIL',
  payload: email,
});

export const setNotificationCount = (count) => ({
  type: 'SET_NOTIFICATION_COUNT',
  payload: count,
});


export const setUserNotificationCount = (count) => ({
  type: 'SET_USER_NOTIFICATION_COUNT',
  payload: count,
});

export const setAdminNotificationCount = (count) => ({
  type: 'SET_ADMIN_NOTIFICATION_COUNT',
  payload: count,
});

export const resetNotificationCountFromStorage = () => ({
  type: 'RESET_NOTIFICATION_COUNT_FROM_STORAGE',
});

export const resetUserNotificationCount = () => ({
  type: 'RESET_USER_NOTIFICATION_COUNT',
});

export const resetAdminNotificationCount = () => ({
  type: 'RESET_ADMIN_NOTIFICATION_COUNT',
});







const persistConfig = {
  key: 'root', 
  storage,
  blacklist: ['notificationCount','userNotificationCount', 'adminNotificationCount'], 

};

const persistedReducer = persistReducer(persistConfig, reducer);

const store = legacy_createStore(persistedReducer);

const persistor = persistStore(store);


// Reset notification counts on startup
store.dispatch(resetUserNotificationCount());
store.dispatch(resetAdminNotificationCount());
store.dispatch({ type: 'RESET_NOTIFICATION_COUNT_FROM_STORAGE' });


export { store, persistor };



////////////////////////////////////////