
import React, { useEffect, useState } from "react";
import Header from "./Header";
import Sidebar from "./Sidebar";
import "../App.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faArrowRight,
  faTimesCircle,
  faExclamationTriangle,
  faCircle,
} from "@fortawesome/free-solid-svg-icons";
import{setAdminNotificationCount}from "../store"

import { useDispatch, useSelector } from "react-redux";
import { BASE_URL } from "../config";
import axios from "axios";
import DataSpinners from "../Spinner";
import { useLocation } from "react-router-dom";

const Notifications = () => {

  
  const [isDarkMode, setIsDarkMode] = useState(true);
  const [table, setTable] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [entitiesPerPage, setEntitiesPerPage] = useState(5);
 // const [notificationCount, setAdminNotificationCount] = useState(0);
  const [spinner, setSpinner] = useState(false);


  const [searchQuery, setSearchQuery] = useState("");
  const [filteredProfiles, setFilteredProfiles] = useState([]);
  const location = useLocation();


  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const searchParam = queryParams.get('search');

    console.log('searchParam',searchParam)
    if (searchParam) {
        setSearchQuery(searchParam);
    }
}, [location.search]);





  const email = "ADMIN";
  useSelector((state) => state.user_email);
  const dispatch = useDispatch();


  useEffect(() => {
    fetchData();
    dispatch(setAdminNotificationCount());

  }, []);



  const fetchData = async () => {
    try {
      setSpinner(true);
      const response = await axios.post(
        `${BASE_URL}/api/admin/fetch_request_access`,
        { email }
      );
      const fetchedData = response.data; 
      response.data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
    
      setTable(fetchedData);

      const storedLength = parseInt(localStorage.getItem("adminNotificationLength")) || 0;

      console.log("storedlenght", storedLength)                 

      if (fetchedData.length > storedLength) {
        setAdminNotificationCount(fetchedData.length - storedLength);
        dispatch(setAdminNotificationCount(fetchedData.length - storedLength));
      }


      // Check for changes in view_status from null to 1 or 0
      const statusChangedCount = fetchedData.reduce((count, entity) => {
        const prevEntity = table.find((e) => e.id === entity.id); // Assuming each entity has a unique id
        if (
          entity.view_status === null &&
          (entity.view_status === "1" || entity.view_status === "0")
        ) {
          return count + 1;
        }
        return count;
      }, 0);

      if (statusChangedCount > 0) {
        dispatch(setAdminNotificationCount(statusChangedCount));
      }

      localStorage.setItem("adminNotificationLength", fetchedData.length);
      setSpinner(false);
    } catch (error) {
      console.error("Error fetching notifications:", error.message);
      setSpinner(false);
    }
  };

  console.log("notification count", setAdminNotificationCount)

  const toggleDarkMode = () => {
    setIsDarkMode(!isDarkMode);
  };




  useEffect(() => {
    const filterProfiles = () => {
        if (searchQuery) {
            const lowercasedQuery = searchQuery.toLowerCase();
            const filtered = table.filter(profile => 
                (profile.kitnumber && profile.kitnumber.toLowerCase().includes(lowercasedQuery)) ||
                (profile.from_email && profile.from_email.toLowerCase().includes(lowercasedQuery)) ||
                (profile.wallet_address && profile.wallet_address.toLowerCase().includes(lowercasedQuery)) ||
                (profile.document_type && profile.document_type.toLowerCase().includes(lowercasedQuery)) ||
                (profile.created_at && profile.created_at.toLowerCase().includes(lowercasedQuery)) 
                
    
            );
            setFilteredProfiles(filtered);
        } else {
            setFilteredProfiles(table);
        }
    };
    filterProfiles();
}, [searchQuery, table]);






  const indexOfLastEntity = currentPage * entitiesPerPage;
  const indexOfFirstEntity = indexOfLastEntity - entitiesPerPage;
  const currentEntities = filteredProfiles.slice(indexOfFirstEntity, indexOfLastEntity);
  const emptyRowCount = entitiesPerPage - currentEntities.length;

  const handlePrevClick = () => {
    setCurrentPage(currentPage - 1);
  };

  const handleNextClick = () => {
    if (currentEntities.length === entitiesPerPage) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handleDropdownChange = (e) => {
    setEntitiesPerPage(parseInt(e.target.value));
    setCurrentPage(1);
  };

  return (
    <div className={`${isDarkMode ? "dark" : "light"}`}>
      <Header toggleDarkMode={toggleDarkMode} isDarkMode={isDarkMode} />
      <div className={`bg-[#05295A] ${isDarkMode ? "bg-[#05295A]" : "bg-white"} flex min-h-screen font-inter pt-5`}>
        <Sidebar isDarkMode={isDarkMode} />
        <div className="lg:w-[80%] w-full">
          <div className="md:h-screen overflow-hidden overflow-y-auto w-[100%]">
            <div className={`rounded-xl mx-5 ${isDarkMode ? "bg-white/20" : "bg-[#3ab7ff28]"} px-10 pb-5`}>
              <p className={`${isDarkMode ? "text-white" : "text-black"} text-end 2xl:text-base text-xs px-3 2xl:py-10 py-2`}>
                Show no of entity
                <select
                  className="ml-2 outline-none rounded-md bg-transparent border-[1px]"
                  onChange={handleDropdownChange}
                  value={entitiesPerPage}
                >
                  <option className="text-black" value="5">5</option>
                  <option className="text-black" value="10">10</option>
                  <option className="text-black" value="50">50</option>
                </select>
              </p>

              <div className="rounded-xl overflow-hidden overflow-x-auto">
                <table className="w-full text-center">
                  <thead>
                    <tr className={`2xl:text-xl sm:text-base text-sm ${isDarkMode ? "text-white" : "text-black"}`}>
                      <th className="py-5 px-5 md:px-0">From</th>
                      <th className="py-5 px-5 md:px-0">Date</th>
                      <th className="py-5 px-5 md:px-0">Kit Number</th>
                      <th className="py-5 px-5 md:px-0">Notification Type</th>
                      <th className="py-5 px-5 md:px-0">Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentEntities.length === 0 ? (
                      <tr>
                        <td colSpan="5" className={`py-5 text-center font-bold ${isDarkMode ? 'text-white' : 'text-black'}`}>
                          No notifications found
                        </td>
                      </tr>
                    ) : (
                      currentEntities.map((data, index) => (
                        <tr key={index} className={`bg-white rounded-xl 2xl:text-lg text-xs md:text-sm ${isDarkMode ? "border-y-8 border-[#37547b]" : "border-y-8 border-[#3ab7ff28]"}`}>
                          <td className="py-5 px-5 md:px-0 rounded-l-xl">{data.from_email}</td>
                          <td className="py-5 px-5 md:px-0">
                            {new Date(data.created_at).toLocaleDateString("en-IN")} || {new Date(data.created_at).toLocaleTimeString("en-IN")}
                          </td>
                          <td className="py-5 px-5 md:px-0">{data.kitnumber}</td>
                          <td className="py-5 px-5 md:px-0">{data.document_type}</td>
                          <td className="py-5 px-5 md:px-0 rounded-r-xl">
                            {data.view_status === null ? (
                              <p className="text-yellow-500 gap-2 font-bold flex justify-center items-center">
                                Pending
                                <span>
                                  <FontAwesomeIcon icon={faCircle} />
                                </span>
                              </p>
                            ) : data.view_status === "1" ? (
                              <p className="text-[#1EEF32] font-bold flex gap-2 justify-center items-center">
                                Approved
                                <span>
                                  <img src="../assets/image 106.svg" className="w-3" alt="Approved" />
                                </span>
                              </p>
                            ) : data.view_status === "0" ? (
                              <p className="text-[#FF3131] gap-2 font-bold flex justify-center items-center">
                                Access Denied
                                <span>
                                  <FontAwesomeIcon icon={faTimesCircle} />
                                </span>
                              </p>
                            ) : (
                              <p className="text-red-500 gap-2 font-bold flex justify-center items-center">
                                Invalid Status
                                <span>
                                  <FontAwesomeIcon icon={faExclamationTriangle} />
                                </span>
                              </p>
                            )}
                          </td>
                        </tr>
                      ))
                    )}

                    {/* {Array.from({ length: emptyRowCount }).map((_, index) => (
                      <tr key={`empty-${index}`} className="h-14">
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                      </tr>
                    ))} */}


                  </tbody>
                </table>
              </div>
            </div>
            <div className="flex justify-between text-xs px-10 pb-5 mt-5">
              <button
                className={`${isDarkMode ? "bg-white/20" : "bg-[#3ab7ff28]"} rounded-md px-5 py-1`}
                onClick={handlePrevClick}
                disabled={currentPage === 1}
              >
                <FontAwesomeIcon icon={faArrowLeft} />
              </button>
              <button
                className={`${isDarkMode ? "bg-white/20" : "bg-[#3ab7ff28]"} rounded-md px-5 py-1`}
                onClick={handleNextClick}
                disabled={currentEntities.length < entitiesPerPage}
              >
                <FontAwesomeIcon icon={faArrowRight} />
              </button>
            </div>
          </div>
        </div>
      </div>
      {spinner ? <DataSpinners /> : ""}
    </div>
  );
};

export default Notifications;