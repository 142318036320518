import { faBell } from '@fortawesome/free-regular-svg-icons'
import { faBars, faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useRef, useState } from 'react'
import AOS from 'aos';
import 'aos/dist/aos.css';

AOS.init();

export default function LandingHeader() {
    const dropdownRef = useRef(null);
    const [isServices, setServices] = useState(false);
    const [isMyProfile, setMyProfile] = useState(false);
    const [isDropdown, setDropdown] = useState(false);

    const handleServices = () => {
        setServices(!isServices)
    }
    const handleMyProfile = () => {
        setMyProfile(!isMyProfile);
    }
    const handleDropdown = () => {
        setDropdown(!isDropdown)
    }
    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setServices(false);
                setMyProfile(false);
                setDropdown(false);
            }
        };

        document.addEventListener('mousedown', handleOutsideClick);

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, []);


    return (
        <div className='font-poppins'>
            <div className='flex justify-between py-5 px-5 md:px-10'>
                <div>
                    <img src='../assets/Logo.png' className='w-[60%] md:w-auto'></img>
                </div>
                <div className='hidden md:block '>
                    <div className='text-sm font-bold flex justify-center gap-5 pt-4'>
                        <a>Home</a>
                        <a>About us</a>
                        <a className='cursor-pointer' >Services 
                            {/* <FontAwesomeIcon icon={faChevronDown} className='pl-1 pb-0.5 text-xs'></FontAwesomeIcon> */}
                            </a>
                        {/* {isServices && (
                            <div ref={dropdownRef} className='dropdown-content z-10 w-[7.5rem] absolute left-[44%] top-20 ' data-aos="fade-zoom-in">
                                <div className='flex flex-col text-center gap-3 bg-white border rounded-lg p-5'>
                                    <a href='' >content 1</a>
                                    <a href='' >content 2</a>
                                    <a href='' >content 3</a>
                                </div>
                            </div>
                        )} */}
                        <a className='cursor-pointer' href='/userDashBoard'>My Profile
                            {/* <FontAwesomeIcon icon={faChevronDown} className='pl-1 pb-0.5 text-xs'></FontAwesomeIcon> */}
                            </a>
                        {/* {isMyProfile && (
                            <div ref={dropdownRef} className='dropdown-content z-10 w-[7.5rem] absolute left-[52%] top-20 ' data-aos="fade-zoom-in">
                                <div className='flex flex-col text-center gap-3 bg-white border rounded-lg p-5'>
                                    <a href='' >part 1</a>
                                    <a href='' >part 2</a>
                                    <a href='' >part 3</a>
                                </div>
                            </div>
                        )} */}
                        <a>Contact</a>
                    </div>
                </div>
                <div className='flex gap-3'>
                    <div><a href= '/userLogin'><button className='py-3 rounded-full border-2 border-black font-bold px-10 text-sm hidden md:block'>Login Now</button></a></div>
                    <div><button className='text-xl block md:hidden' onClick={handleDropdown}><FontAwesomeIcon icon={faBars}></FontAwesomeIcon></button></div>
                    {isDropdown && (
                        <div ref={dropdownRef} className='dropdown-content text-black z-10 w-[10rem] absolute right-5 top-[4rem]' data-aos="fade-up" >
                            <div className='flex flex-col gap-3 border-2 text-center bg-white rounded-lg p-3'>
                                <a href=''>Home</a>
                                <a href=''>About us</a>
                                <a className='cursor-pointer' >Services 
                                  {/* <FontAwesomeIcon icon={faChevronDown} className='pl-1 pb-0.5 text-xs'></FontAwesomeIcon> */}
                                    </a>
                                {/* {isServices && (
                                    <div ref={dropdownRef} className='dropdown-content z-10 w-[7.5rem] absolute right-[80%] top-24 ' data-aos="fade-zoom-in">
                                        <div className='flex flex-col text-center gap-3 bg-white border rounded-lg p-5'>
                                            <a href=''>content 1</a>
                                            <a href=''>content 2</a>
                                            <a href=''>content 3</a>
                                        </div>
                                    </div>
                                )} */}
                                <a className='cursor-pointer'  href='/userDashBoard'>My Profile
                                    {/* <FontAwesomeIcon icon={faChevronDown} className='pl-1 pb-0.5 text-xs'></FontAwesomeIcon> */}
                                    </a>
                                {/* {isMyProfile && (
                                    <div ref={dropdownRef} className='dropdown-content z-10 w-[7.5rem] absolute right-[80%] top-20 ' data-aos="fade-zoom-in">
                                        <div className='flex flex-col text-center gap-3 bg-white border rounded-lg p-5'>
                                            <a href=''>part 1</a>
                                            <a href=''>part 2</a>
                                            <a href=''>part 3</a>
                                        </div>
                                    </div>
                                )} */}
                                <div className='flex justify-center'>
                                    <button className='py-1 rounded-full border-2 border-black font-bold px-3 text-sm'>Login Now</button>
                                </div>
                            </div>
                        </div>
                    )}
                    <div><button className='text-white py-1 md:py-2 px-2 md:px-3 rounded-full bg-black'><FontAwesomeIcon icon={faBell}></FontAwesomeIcon></button></div>
                </div>
            </div>
        </div>
    )
}